export default {
  sidebar: {
    fleet: 'Vloot',
    vehicles: 'Voertuigen',
    vehiclesClasses: 'Voertuigklassen',
    administration: 'Administratie',
    currencies: 'Valuta',
    currenciesAdministration: 'Valutabeheer',
    paymentTypes: 'Betalingsmethoden',
    paymentTypesAdministration: 'Beheer van betalingsmethoden',
    users: 'Gebruikers',
    usersAdministration: 'Gebruikersbeheer',
    clients: 'Klanten',
    clientsAdministration: 'Klantenbeheer',
    organizations: 'Organisaties',
    organizationsAdministration: 'Organisatiebeheer',
    saasAdmin: 'Saas-beheerder',
    administrators: 'Beheerders',
    saasAdminAdministration: 'Beheer van Saas-beheerder',
    statistics: 'Statistieken',
    generalOverview: 'Algemeen rapport',
    payment: 'Betaling',
    rebates: 'Kortingen',
    pricingRegions: "Prijzenregio's",
    pricingRegionsAdministration: "Beheer van prijsregio's",
    help: 'Help',
    drivings: 'Rijbestellingen',
    calendar: 'Kalender',
    tabularView: 'Tabellarisch overzicht',
    expenses: 'Kosten',
    vehicleExpensesAdministration: 'Beheer van voertuigkosten',
    vehicleMaintenance: 'Voertuigonderhoud',
    vehicleMaintenanceAdministration: 'Beheer van voertuigonderhoud',
    organization: 'Organisatie',
    organizationAdminstration: 'Beheer van organisatie',
    packages: 'Pakketten',
    packagesChange: 'Wijzig pakketten',
    logout: 'Uitloggen uit het systeem',
    logoutText: 'Weet u zeker dat u wilt uitloggen uit het systeem?',
    unaccessibleRoutes: 'U kunt deze pagina niet openen. Upgrade het pakket voor volledige toegang.',
    transactions: 'Transacties',
    gpsMap: 'GPS kaart',
    team: 'Team',
    teamAdministration: 'Teambeheer',
    invoices: 'Facturen',
    invoicesAdministration: 'Factuurbeheer',
    drivingOffers: 'Aanbiedingen',
    drivingOffersAdministration: 'Beheer van aanbiedingen',
    dashboard: 'Dashboard',
    dashboardAdministration: 'Dashboardbeheer',
    resourceOverview: 'Overzicht van middelen',
    resourceOverviewAdministration: 'Beheer van middelenoverzicht',
    rides: 'Ritten',
  },
  header: {
    organization: 'Organisatie',
    newDriving: 'Rijbestelling',
    dailyRent: 'Dagverhuur',
    transfer: 'Overdracht',
    notifications: 'Meldingen',
    changeLanguage: 'Taal wijzigen',
  },
  footer: {
    poweredBy: 'Mogelijk gemaakt door',
    subscriptionTo: 'Uw abonnement op',
    trialEndsAt: 'Uw proefperiode voor',
    packageExpiresIn: 'abonnement verloopt over',
    clickToChangePackage: 'Klik om het pakket te wijzigen',
    clickToActivatePackage: 'Klik om het pakket te activeren',
    clickToPay: 'Klik om te betalen',
    packageHasExpired: 'pakket is verlopen',
  },
  buttons: {
    ok: 'Oké',
    yes: 'Ja',
    no: 'Nee',
    save: 'Opslaan',
    send: 'Verzenden',
    sendMessage: 'Bericht verzenden',
    edit: 'Bewerken',
    add: 'Toevoegen',
    confirm: 'Bevestigen',
    cancel: 'Annuleren',
    cancelDriving: 'Rijbestelling annuleren',
    markAsDone: 'Markeer als voltooid',
    markAsNoShow: 'Markeren als niet verschenen',
    deleteDriving: 'Rijden verwijderen',
    newOrganisation: 'Nieuwe organisatie',
    changePackage: 'Pakketten wijzigen',
    downloadStats: 'Statistieken downloaden',
    downloadPDF: 'PDF downloaden',
    today: 'Vandaag',
    choose: 'Kiezen',
    login: 'Inloggen',
    generateFile: 'Contract genereren',
    generateDrivingOrder: 'Rijbestelling afdrukken',
    downloadFile: 'Bestand downloaden',
    activate: 'Activeren',
    deactivate: 'Deactiveren',
    showImage: 'Afbeelding weergeven',
    showDetails: 'Details weergeven',
    actions: 'Acties',
    delete: 'Verwijderen',
    open: 'Openen',
    show: 'Weergeven',
    options: 'Opties',
    prev: 'Vorige',
    next: 'Volgende',
    skip: 'Overslaan',
    finish: 'Voltooien',
    settings: 'Instellingen',
    cancelSubscription: 'Abonnement annuleren',
    backToEditOrganization: 'Terug naar organisatie bewerken',
    payNow: 'Betaal nu',
    back: 'Terug',
    integrationRoutes: 'Integratieroutes',
    transactions: 'Transacties',
    articlesOfLaw: 'Artikelen van wet',
    export: 'Exporteer',
    duplicate: 'Maak een kopie',
    makeReturnTrip: 'Maak een retourreis',
    selectOrganisation: 'Selecteer organisatie',
    advancedSettings: 'Geavanceerde instellingen',
    accept: 'Accepteren',
    reject: 'Weigeren',
    sendViaGnet: 'Versturen via GNET',
    printInvoice: 'Factuur afdrukken',
    enable: 'Inschakelen',
    disable: 'Uitschakelen',
    generatePaymentLink: 'Betaallink genereren',
    addItem: '+ Item toevoegen',
  },
  tooltips: {
    save: 'Opslaan',
    edit: 'Bewerken',
    delete: 'Verwijderen',
    showImage: 'Afbeelding weergeven',
    open: 'Openen',
    show: 'Weergeven',
    showDetails: 'Details weergeven',
    downloadFile: 'Bestand downloaden',
    generateFile: 'Contract genereren',
    generateDrivingOrder: 'Rijbestelling afdrukken',
    activate: 'Activeren',
    deactivate: 'Deactiveren',
    cancel: 'Annuleren',
    cancelDriving: 'Rijbestelling annuleren',
    cannotMarkAsCanceled: 'De bestelling kan niet als geannuleerd worden gemarkeerd omdat deze al is geaccepteerd',
    markAsDone: 'Markeer als voltooid',
    cannotMarkAsDone: 'De bestelling kan niet als voltooid worden gemarkeerd, omdat deze nog niet heeft plaatsgevonden',
    markAsNoShow: 'Markeren als niet verschenen',
    cannotMarkAsNoShow:
      'De bestelling kan niet worden gemarkeerd als niet verschenen, omdat deze nog niet heeft plaatsgevonden',
    deleteDriving: 'Rijden verwijderen',
    cannotDelete:
      'Het spijt ons, maar om veiligheidsredenen kunnen gebruikers niet worden verwijderd terwijl ze momenteel zijn ingelogd.',
    duplicate: 'Maak een kopie',
    makeReturnTrip: 'Maak een retourreis',
    refresh: 'Vernieuwen',
    selectOrganisation: 'Selecteer organisatie',
    sendViaGnet: 'Versturen via GNET',
    receivedViaGnet: 'Ontvangen via GNET',
    sentViaGnet: 'Verzonden via GNET',
    printInvoice: 'Factuur afdrukken',

    optionsDisabledGnet: 'U moet de bestelling weigeren of accepteren om toegang te krijgen tot het optiemenu',
    cannotMakeReturnTrip: 'U kunt geen retourreis maken voor daghuur',
    cannotSendGnetOrderViaGnet: 'U kunt de gnet-bestelling niet via gnet verzenden',
    cannotSendViaGnetWithoutGnetId: 'U kunt de bestelling niet via gnet verzenden zonder gnet-id',
    cannotPrintInvoiceWithoutClient: 'U kunt geen factuur afdrukken zonder een klant te kiezen',
    cannotMarkAsDoneStatusRejected:
      'De bestelling kan niet worden gemarkeerd als voltooid omdat deze al als afgewezen is gemarkeerd',
    cannotMarkAsDoneStatusCanceled:
      'De bestelling kan niet worden gemarkeerd als voltooid omdat deze al als geannuleerd is gemarkeerd',
    cannotMarkAsDoneStatusDone:
      'De bestelling kan niet worden gemarkeerd als voltooid omdat deze al als voltooid is gemarkeerd',
    cannotMarkAsDoneStatusNoShow:
      'De bestelling kan niet worden gemarkeerd als voltooid omdat deze al als niet verschenen is gemarkeerd',
    cannotMarkAsNoShowStatusRejected:
      'De bestelling kan niet worden gemarkeerd als niet verschenen omdat deze al als afgewezen is gemarkeerd',
    cannotMarkAsNoShowStatusCanceled:
      'De bestelling kan niet worden gemarkeerd als niet verschenen omdat deze al als geannuleerd is gemarkeerd',
    cannotMarkAsNoShowStatusDone:
      'De bestelling kan niet worden gemarkeerd als niet verschenen omdat deze al als voltooid is gemarkeerd',
    cannotMarkAsNoShowStatusNoShow:
      'De bestelling kan niet worden gemarkeerd als niet verschenen omdat deze al als niet verschenen is gemarkeerd',
    cannotMarkAsCanceledGnet:
      'De bestelling kan niet als geannuleerd worden gemarkeerd omdat deze via gnet is ontvangen',
    cannotMarkAsCanceledStatusRejected:
      'De bestelling kan niet als geannuleerd worden gemarkeerd omdat deze al als afgewezen is gemarkeerd',
    cannotMarkAsCanceledStatusExpired:
      'De bestelling kan niet als geannuleerd worden gemarkeerd omdat deze al als verlopen is gemarkeerd',
    cannotMarkAsCanceledStatusCanceled:
      'De bestelling kan niet als geannuleerd worden gemarkeerd omdat deze al als geannuleerd is gemarkeerd',
    cannotMarkAsCanceledStatusDraft:
      'De bestelling kan niet als geannuleerd worden gemarkeerd omdat deze al als concept is gemarkeerd',
    cannotMarkAsCanceledStatusCompleted:
      'De bestelling kan niet als geannuleerd worden gemarkeerd omdat deze al als voltooid is gemarkeerd',
    cannotMarkAsCanceledStatusNoShow:
      'De bestelling kan niet als geannuleerd worden gemarkeerd omdat deze al als niet verschenen is gemarkeerd',
    cannotDeleteDrivingSentViaGnet: 'De bestelling kan niet worden verwijderd omdat deze via gnet is verzonden',
    cannotDeleteDrivingReceivedViaGnet:
      'De bestelling kan niet worden verwijderd omdat deze via gnet is ontvangen en geaccepteerd',
    generatePaymentLink: 'Betaallink genereren',
    cannotGeneratePaymentLInk: 'Betaallink kan niet worden gegenereerd omdat je Stripe niet hebt geactiveerd',
  },
  snackbar: {
    close: 'Sluiten',
    savedSuccessfully: 'Succesvol opgeslagen',
    excelDownloadedSuccessfully: 'Succesvol gedownload',
    deletedSuccessfully: 'Succesvol verwijderd',
    sentSuccessfully: 'Succesvol verzonden',
    activeStatusChanged: 'Actieve status succesvol gewijzigd',
    defaultStatusChanged: 'Standaard status succesvol gewijzigd',
    primaryStatusChanged: 'Primaire status succesvol gewijzigd',
    anErrorOccured: 'Er is een fout opgetreden, meld dit aan de ondersteuning',
    loadingCommentsFailed: 'Laden van opmerkingen mislukt',
    drivingCanceled: 'Rijbestelling is succesvol geannuleerd',
    drivingFinished: 'Rijbestelling is succesvol voltooid',
    drivingNoShow: 'Ritbestelling is succesvol gemarkeerd als niet verschenen.',
    drivingDeleted: 'de rijopdracht is succesvol verwijderd',
    drivingAccepted: 'Rijopdracht is succesvol geaccepteerd',
    drivingRejected: 'Rijopdracht is succesvol geweigerd',
    planChanged: 'Plan succesvol gewijzigd',
    planChangeFailed: 'Het is niet mogelijk om het plan te wijzigen',
    saveChangesFirst: 'Sla eerst de wijzigingen op die u eerder heeft gemaakt',
    makeRoutesPublic: 'Routes zijn openbaar toegankelijk',
    makeRoutesNotPublic: 'Routes zijn niet langer openbaar toegankelijk',
    makePublicLinkActive: 'Publieke link is nu beschikbaar',
    makePublicLinkInactive: 'Publieke link is niet langer beschikbaar',
    integrationKeyCopied: 'Integratiesleutel gekopieerd',
    copyToken: 'Kopieer de token handmatig',
    publicLinkCopied: 'Publieke link gekopieerd',
    copyPublicLink: 'Kopieer de publieke link handmatig',
    organisationChanged: 'Organisatie succesvol gewijzigd',
    changeFailed: 'De wijziging is mislukt, probeer het opnieuw',
    loginFailed: 'Gebruikersnaam of wachtwoord is onjuist',
    organisationSuspended: 'Uw organisatie is opgeschort',
    userEmailDoesNotExists: 'Gebruiker met dit e-mailadres bestaat niet',
    canceledSubscrSuccessfully: 'Abonnement succesvol geannuleerd',
    toManyRequests: 'Te veel verzoeken. Probeer het over een minuut opnieuw.',
    visibleStatusChanged: 'Zichtbare status succesvol gewijzigd',
    noInternetConnection: 'Geen internet. Controleer uw internetverbinding.',
    backOnline: 'U bent weer online.',
    driverNotifiedToTurnGpsOn: 'Bestuurder geïnformeerd om GPS in te schakelen',
    savedSuccessfullyWithGnetError: 'GNET-synchronisatie mislukt, ritbestelling succesvol opgeslagen.',
    logoutFromAnotherDevice: 'U bent afgemeld van een ander apparaat.',
    stripeEnabled: 'Stripe succesvol geactiveerd',
    stripeDisabled: 'Stripe succesvol gedeactiveerd',
    finishStripeConnect: 'Voltooi uw Stripe-installatie',
  },
  filters: {
    filter: 'Filter',
    currentYear: 'Huidig jaar',
    lastYear: 'Vorig jaar',
    lastQuarter: 'Laatste kwartaal',
    lastThreeYears: 'Laatste drie jaar',
    filterByDriver: 'Filteren op bestuurder',
    filterByVehicle: 'Filteren op voertuig',
    filterByClient: 'Filteren op klanten',
    day: 'Dag',
    week: 'Week',
    month: 'Maand',
    vehicle: 'Voertuig',
    driving: 'Rijden',
    user: 'Gebruiker',
    fromDate: 'Vanaf datum',
    toDate: 'Tot datum',
    threeMonths: 'Drie maanden',
    halfYear: 'Half jaar',
    year: 'Jaar',
  },
  months: {
    jan: 'januari',
    feb: 'februari',
    mar: 'maart',
    apr: 'april',
    may: 'mei',
    jun: 'juni',
    jul: 'juli',
    aug: 'augustus',
    sep: 'september',
    oct: 'oktober',
    nov: 'november',
    dec: 'december',
    January: 'januari',
    February: 'februari',
    March: 'maart',
    April: 'april',
    May: 'mei',
    June: 'juni',
    July: 'juli',
    August: 'augustus',
    September: 'september',
    October: 'oktober',
    November: 'november',
    December: 'december',
  },
  usersPageHeaders: {
    firstName: 'Voornaam',
    lastName: 'Achternaam',
    username: 'Gebruikersnaam',
    email: 'E-mail',
    phoneNumber: 'Telefoonnummer',
    role: 'Rol',
    active: 'Actief',
    actions: 'Acties',
  },
  usersRoles: {
    masterAdmin: 'Hoofdbeheerder',
    manager: 'Manager',
    driver: 'Chauffeur',
    operator: 'Operator',
    externalAssociate: 'Externe medewerker',
    sales: 'Verkoop',
    salesLead: 'Verkoopleider',
    gnet: 'GNET',
    customer: 'Portaalklant',
  },
  masterUsersPageHeaders: {
    firstName: 'Voornaam',
    lastName: 'Achternaam',
    username: 'Gebruikersnaam',
    email: 'E-mail',
    phoneNumber: 'Telefoonnummer',
    role: 'Rol',
    organisation: 'Organisatie',
    active: 'Actief',
    actions: 'Acties',
  },
  usersPage: {
    newItem: 'Nieuwe gebruiker invoeren',
    editItem: 'Gebruiker bewerken',
    id: 'Id',
    firstName: 'Voornaam',
    lastName: 'Achternaam',
    username: 'Gebruikersnaam',
    password: 'Wachtwoord',
    phoneNumber: 'Telefoonnummer',
    role: 'Rol',
    client: 'Klant',
    email: 'E-mail',
    organisation: 'Organisatie',
    percentage: 'Percentage',
    externalAssociate: 'Externe medewerker',
    image: 'Afbeelding',
    deleteUser: 'Gebruiker verwijderen',
    deleteMessage: 'Weet u zeker dat u de gebruiker wilt verwijderen ',
    emailChanged: 'E-mailadres wijzigen',
    verificationEmailSent:
      'Het verzoek om het e-mailadres te wijzigen is verzonden. Om het proces te voltooien en de beveiliging van het gebruikersaccount te waarborgen, moet het nieuwe e-mailadres worden geverifieerd door te klikken op de verificatielink die naar het nieuwe e-mailadres is gestuurd. Het e-mailadres wordt pas gewijzigd nadat het met succes is geverifieerd.',
  },
  clientsPageHeaders: {
    name: 'Naam',
    phoneNumber: 'Telefoonnummer',
    address: 'Adres',
    email: 'E-mail',
    companyId: 'Bedrijfs-ID/Persoonlijke ID',
    clientType: 'Klanttype',
    percentage: 'Percentage',
    active: 'Actief',
    actions: 'Acties',
  },
  clientsPage: {
    newItem: 'Nieuwe klant invoeren',
    editItem: 'Klant bewerken',
    id: 'Id',
    name: 'Naam',
    address: 'Adres',
    phoneNumber: 'Telefoonnummer',
    email: 'E-mail',
    companyId: 'Bedrijfs-ID/Persoonlijke ID',
    clientType: 'Klanttype',
    individual: 'Individu',
    business: 'Zakelijke entiteit',
    percentage: 'Percentage',
    deleteClient: 'Klant verwijderen',
    deleteMessage: 'Weet u zeker dat u de klant wilt verwijderen ',
    tabs: {
      general: 'Algemeen',
      transfers: 'Overdrachten',
      users: 'Gebruikers',
      invoices: 'Facturen',
      cards: 'Kaarten',
    },
  },
  drivingsHeaders: {
    drivingNumber: 'Rijbestelnummer',
    drivingType: 'Type rijbestelling',
    drivingStatus: 'Status',
    locationFrom: 'Vertrekpunt',
    locationTo: 'Bestemming',
    vehicleClass: 'Voertuigklasse',
    vehicle: 'Voertuig',
    driversName: 'Chauffeur',
    startTime: 'Ophaaltijd',
    actions: 'Acties',
    exportExcel: 'Exporteer excel',
    price: 'Prijs',
    totalPrice: 'Totale prijs',
    confirmed: 'Bevestigd',
    paid: 'Betaald',
    numberOfDrivings: 'Aantal rijopdrachten',
  },
  vehiclesHeaders: {
    plateNumber: 'Kenteken',
    brand: 'Voertuigmerk',
    vehicleClass: 'Voertuigklasse',
    passengersNumber: 'Aantal passagiers',
    fuelConsumption: 'Brandstofverbruik',
    yearOfManufacture: 'Bouwjaar',
    pricePerKm: 'Prijs per km',
    pricePerMi: 'Prijs per mijl',
    pricePerHour: 'Prijs per uur',
    pricePerWaitingHour: 'Prijs per wachturen',
    active: 'Actief',
    actions: 'Acties',
  },
  vehiclesPage: {
    id: 'Id',
    newItem: 'Nieuwe voertuiginvoer',
    editItem: 'Voertuig bewerken',
    vehiclePlates: 'Kenteken',
    vehicleBrand: 'Voertuigmerk',
    vehicleClass: 'Voertuigklasse',
    passengersNumber: 'Aantal passagiers',
    fuelConsumption: 'Brandstofverbruik (100 km)',
    fuelConsumptionMi: 'Brandstofverbruik auto (100 mijl)',
    manufactureYear: 'Bouwjaar',
    price: 'Prijs per km',
    priceMi: 'Prijs per mijl',
    hourPrice: 'Prijs per uur',
    image: 'Afbeelding',
    hourWaitingPrice: 'Prijs per wachturen',
    deleteMessage: 'Weet u zeker dat u dit voertuig wilt verwijderen?',
    tabs: {
      general: 'Algemeen',
      transfers: 'Transfers',
      costs: 'Kosten',
      damages: 'Schades',
    },
    licence: 'Licentienummer',
  },
  vehicleClassesHeaders: {
    vehicleClass: 'Voertuigklasse',
    active: 'Actief',
    actions: 'Acties',
  },
  vehicleClassesPage: {
    newItem: 'Nieuwe voertuigklasse-invoer',
    editItem: 'Voertuigklasse bewerken',
    id: 'Id',
    vehicleClass: 'Voertuigklasse',
    hourWaitingPrice: 'Prijs per wachturen',
    deleteMessage: 'Weet u zeker dat u deze voertuigklasse wilt verwijderen?',
  },
  expensesHeaders: {
    name: 'Naam',
    vehicle: 'Voertuig',
    type: 'Type',
    description: 'Beschrijving',
    price: 'Prijs',
    expenseDate: 'Datum',
    actions: 'Actie',
    driving: 'Rijden',
    exportExcel: 'Exporteer excel',
  },
  vehicleExpensesTypes: {
    minorService: 'Kleine service',
    majorService: 'Grote service',
    fuel: 'Brandstof',
    tires: 'Banden',
    parking: 'Parkeren',
    other: 'Overige',
  },
  expensesPage: {
    newItem: 'Nieuwe invoer van kosten',
    editItem: 'Wijziging van kosten',
    id: 'Id',
    name: 'Naam',
    vehicle: 'Voertuig',
    costType: 'Kostentype',
    price: 'Prijs',
    currency: 'Valuta',
    costTime: 'Kostentijd',
    description: 'Omschrijving',
    deleteMessage: 'Weet u zeker dat u deze kosten wilt verwijderen?',
    costTypes: {
      'Mali servis': 'Kleine service',
      'Veliki servis': 'Grote service',
      Gorivo: 'Brandstof',
      Gume: 'Banden',
      Parking: 'Parkeren',
      Ostalo: 'Overig',
    },
  },
  vehicleMaintenanceHeaders: {
    name: 'Voertuig',
    details: 'Details',
    dateFrom: 'Datum vanaf',
    dateTo: 'Datum tot',
    actions: 'Acties',
  },
  vehicleMaintenancePage: {
    newItem: 'Nieuwe invoer van voertuigstoring',
    editItem: 'Wijziging van voertuigstoring',
    id: 'Id',
    vehicle: 'Voertuig',
    description: 'Omschrijving',
    dateFrom: 'Tijd vanaf',
    dateTo: 'Tijd tot',
    deleteMessage: 'Weet u zeker dat u deze voertuigstoring wilt verwijderen?',
  },
  rebatesHeaders: {
    min: 'Minimum aantal rijbestellingen',
    max: 'Maximum aantal rijbestellingen',
    percentage: 'Korting (%)',
    actions: 'Acties',
  },
  rebatesPage: {
    newItem: 'Nieuwe invoer van korting',
    editItem: 'Wijziging van korting',
    rebateMin: 'Minimum aantal rijbestellingen',
    rebateMax: 'Maximum aantal rijbestellingen',
    rebate: 'Korting (%)',
    deleteRebate: 'Verwijder korting',
    deleteMessage: 'Weet u zeker dat u deze korting wilt verwijderen?',
  },
  customerCardsHeaders: {
    cardholderName: 'Naam kaarthouder',
    cardNumber: 'Kaartnummer',
    expiryDate: 'Vervaldatum',
    cvv: 'CVV',
    actions: 'Acties',
  },
  customerCardsPage: {
    newItem: 'Nieuwe kaartinvoer',
    editItem: 'Kaartwijziging',
    cardholderName: 'Naam kaarthouder',
    cardNumber: 'Kaartnummer',
    expiryDate: 'Vervaldatum',
    cvv: 'CVV',
    deleteCard: 'Kaart verwijderen',
    deleteMessage: 'Weet u zeker dat u deze kaart wilt verwijderen?',
  },
  pricingRegionsHeaders: {
    name: 'Naam',
    actions: 'Acties',
  },
  pricingRegionsPage: {
    newItem: 'Nieuwe invoer van prijsregio',
    editItem: 'Wijziging van prijsregio',
    name: 'Naam',
    chooseCurrency: 'Kies valuta',
    team10Price: 'Team 10 prijs',
    team10PaddlePriceId: 'Team 10 Paddle prijs ID',
    team25Price: 'Team 25 prijs',
    team25PaddlePriceId: 'Team 25 Paddle prijs ID',
    team50Price: 'Team 50 prijs',
    team50PaddlePriceId: 'Team 50 Paddle prijs ID',
    chooseCountries: 'Kies landen',
    description: 'Omschrijving',
    deletePricingRegion: 'Verwijder prijsregio',
    deleteMessage: 'Weet je zeker dat je deze prijsregio wilt verwijderen?',
  },
  notificationsHeaders: {
    time: 'Tijd',
    notification: 'Melding',
  },
  organisationsHeaders: {
    name: 'Organisatie',
    phoneNumber: 'Telefoonnummer',
    email: 'E-mail',
    address: 'Adres',
    companyId: 'Bedrijfs-ID',
    vat: 'BTW',
    active: 'Actief',
    createdAt: 'Geregistreerd',
    actions: 'Acties',
    billingDate: 'Factuurdatum',
  },
  organisationsPage: {
    newItem: 'Nieuwe gebruikersinvoer',
    editItem: 'Bewerk gebruiker',
    id: 'Id',
    name: 'Organisatie',
    address: 'Adres',
    phoneNumber: 'Telefoonnummer',
    email: 'E-mail',
    vat: 'BTW',
    companyId: 'Bedrijfs-ID',
    warrantNumber: 'Beslissingsnummer',
    image: 'Afbeelding',
    signature: 'Handtekening',
    seal: 'Stempel',
    deactivateMessage: 'Weet u zeker dat u ',
    deactivate: 'DEACTIVEREN',
    activate: 'ACTIVEREN',
    organisation: 'organisatie',
    deleteOrganisation: 'Verwijder organisatie',
    deleteMessage: 'Weet u zeker dat u deze organisatie wilt verwijderen?',
    cancelSubscrMessage:
      'Weet u zeker dat u uw abonnement wilt opzeggen? Wees u ervan bewust dat door het bevestigen van deze actie: 1. Het account van uw organisatie en alle bijbehorende gegevens permanent worden verwijderd. Deze actie kan niet ongedaan worden gemaakt. 2. U wordt direct na de annulering automatisch uit het systeem uitgelogd. 3. U zult in de toekomst geen toegang meer hebben om in te loggen of gegevens gerelateerd aan uw organisatie op te halen. Wij raden u ten zeerste aan om alle benodigde informatie te controleren of kritieke gegevens te exporteren voordat u doorgaat met deze onomkeerbare actie. Als u vragen heeft of hulp nodig heeft, aarzel dan niet om contact op te nemen met ons ondersteuningsteam. Wilt u doorgaan met de annulering?',
    allDrivings: 'Totaal aantal rijbestellingen',
    allDrivingsAccepted: 'Totaal geaccepteerde rijbestellingen',
    allDrivingsCanceled: 'Totaal geweigerde rijbestellingen',
    allVehicles: 'Totaal aantal voertuigen',
    allUsers: 'Totaal aantal gebruikers',
    areYouSure: 'Weet u het zeker?',
    makeRoutesAvailable: 'Maak integratieroutes beschikbaar',
    percentage: 'Percentage',
    deleteImageMessage: 'Weet u zeker dat u deze afbeelding wilt verwijderen?',
    deleteImageTitle: 'Elimina Immagine',
    cancelSubscriptionTitle: 'Abonnement annuleren',
    subscriptionType: 'Abonnementstype',
    billingDate: 'Factuurdatum',
    articlesOfLaw: 'Artikelen van wet',
    country: 'Land',
    referent: 'Referent',
    language: 'Taal',
    gnetId: 'GNET-ID',
  },
  transactionsHeaders: {
    invoiceNumber: 'Factuurnummer',
    status: 'Status',
    total: 'Totaal',
    currency: 'Valuta',
    billedAt: 'Gefactureerd op',
    actions: 'Acties',
  },
  transactionsPage: {
    newItem: 'Nieuwe transactie-invoer',
    editItem: 'Bewerk transactie',
    id: 'Id',
    invoiceNumber: 'Factuurnummer',
    status: 'Status',
    sent: 'Verzonden',
    completed: 'Voltooid',
    total: 'Totaal',
    currency: 'Valuta',
    billedAt: 'Gefactureerd op',
    actions: 'Acties',
    deleteTransaction: 'Verwijder transactie',
    deleteMessage: 'Weet u zeker dat u deze transactie wilt verwijderen?',
    currentSubscription: 'Huidig pakket',
    nextBillingDate: 'Volgende factuurdatum',
    numberOfSeatsTaken: 'Aantal bezette teamleden',
    numberOfSeatsAvailable: 'Aantal beschikbare teamleden',
  },
  currenciesHeaders: {
    name: 'Naam',
    code: 'Code',
    symbol: 'Symbool',
    active: 'Actief',
    default: 'Primair',
    actions: 'Acties',
  },
  currenciesPage: {
    newItem: 'Nieuwe valutainvoer',
    editItem: 'Valutawijziging',
    id: 'Id',
    currency: 'Valuta',
    code: 'Code',
    symbol: 'Symbool',
    deleteMessage: 'Weet u zeker dat u deze valuta wilt verwijderen?',
  },
  paymentMethodsHeaders: {
    name: 'Naam',
    active: 'Actief',
    default: 'Primair',
    priceVisible: 'Prijs zichtbaar',
    actions: 'Acties',
  },
  paymentMethodsPage: {
    newItem: 'Nieuwe betaalmethode toevoegen',
    editItem: 'Bewerk betaalmethode',
    id: 'Id',
    paymentMethod: 'Betaalmethode',
    deleteMessage: 'Weet u zeker dat u deze betaalmethode wilt verwijderen?',
  },
  feedbacksHeaders: {
    subject: 'Naam',
    organisation: 'Organisatie',
    user: 'Gebruiker',
    actions: 'Acties',
  },
  passengersHeaders: {
    firstName: 'Voornaam',
    lastName: 'Achternaam',
    phoneNumber: 'Telefoonnummer',
    email: 'E-mail',
    passport: 'Paspoort',
    nationality: 'Nationaliteit',
    actions: 'Acties',
  },
  checkpointsHeaders: {
    checkpointLocation: 'Locatie van tussenpunt',
    arrivalTime: 'Aankomsttijd',
    actions: 'Acties',
  },
  tables: {
    noDataText: 'Geen gegevens',
    search: 'Zoeken',
    drivings: 'Ritopdrachten',
    drivingsByPage: 'Ritopdrachten per pagina',
    vehicles: 'Voertuigen',
    vehiclesByPage: 'Voertuigen per pagina',
    vehiclesClasses: 'Voertuigklassen',
    vehiclesClassesByPage: 'Voertuigklassen per pagina',
    expenses: 'Kosten',
    expensesByPage: 'Kosten per pagina',
    vehiclesMaintenance: 'Voertuigstoringen',
    vehiclesMaintenanceByPage: 'Voertuigstoringen per pagina',
    reportsByPage: 'Rapporten per pagina',
    currencies: 'Valuta',
    currencieseByPage: 'Valuta per pagina',
    payments: 'Betaalmethoden',
    paymentsByPage: 'Betaalmethoden per pagina',
    saasAdministrators: 'Alle beheerders',
    users: 'Alle gebruikers',
    usersByPage: 'Gebruikers per pagina',
    clients: 'Alle klanten',
    clientsByPage: 'Klanten per pagina',
    organisations: 'Alle organisaties',
    organisationsByPage: 'Organisaties per pagina',
    rebates: 'Kortingen',
    rebatesByPage: 'Kortingen per pagina',
    cards: 'Kaarten',
    cardsByPage: 'Kaarten per pagina',
    transactions: 'Transacties',
    transactionsByPage: 'Transacties per pagina',
    pricingRegions: "Prijzenregio's",
    pricingRegionsByPage: "Prijzenregio's per pagina",
    feedback: 'Feedbacks',
    feedbacksByPage: 'Feedbacks per pagina',
    notifications: 'Meldingen',
    notificationsByPage: 'Meldingen per pagina',
    all: 'Alles',
    passengers: 'Passagiers',
    passengersByPage: 'Passagiers per pagina',
    of: 'van',
    checkpoints: 'Tussenpunten',
    checkpointsByPage: 'Tussenpunten per pagina',
    invoices: 'Facturen',
    invoicesByPage: 'Facturen per pagina',
    drivingOffers: 'Rijaanbiedingen',
    drivingOffersByPage: 'Rijaanbiedingen per pagina',
    smsMessages: 'SMS-berichten',
    smsMessagesByPage: 'SMS-berichten per pagina',
    resourcesUsed: 'Gebruikte middelen',
    resourcesUsedByPage: 'Middelen per pagina',
    inNext3Days: 'In de komende 3 dagen',
    notConfirmedOrPaid: 'Niet bevestigd of betaald',
    inNext5Days: 'In de komende 5 dagen',
    latestReviews: 'Laatste beoordelingen',
    driversByPage: 'Chauffeurs per pagina',
    reviewsByPage: 'Beoordelingen per pagina',
  },
  statistics: {
    numberOfAcceptedDrivings: 'Aantal geaccepteerde ritopdrachten',
    numberOfRejectedDrivings: 'Aantal afgewezen ritopdrachten per chauffeur',
    vehicleDrivingsNumber: 'Aantal ritopdrachten per voertuig',
    vehicleProfit: 'Winst per voertuig',
    monthDrivingsNumber: 'Aantal ritopdrachten per maand',
    driverProfit: 'Winst per chauffeur',
  },
  reportsHeaders: {
    vehicle: 'Voertuig',
    expenses: 'Kosten',
    profit: 'Winst',
    earnings: 'Inkomsten',
    daysWorking: 'Werkdagen',
    usageIndex: 'Gebruiksindex',
    client: 'Klant',
    numberOfDrivings: 'Aantal Ritten',
    percentForClient: 'Prijs voor de klant',
    sumOfPrice: 'Hoeveelheid',
  },
  packages: {
    active: 'Actief',
    starterTitle: 'Starter',
    monthlyPackagesMessage: 'Maandelijkse abonnement per gebruiker.',
    starterText: 'Ideaal voor individuen of kleine teams die alleen basisfunctionaliteit nodig hebben.',
    starterListItemOne: 'Mobiele applicaties',
    starterListItemTwo: 'Pushmeldingen',
    starterListItemThree: 'Toegang tot beheer',
    starterListItemFour: 'Website-integratie',
    standardTitle: 'Standaard',
    standardText: 'Perfect voor organisaties met meer dan 5 chauffeurs.',
    standardListItemOne: 'INBEGREPEN STARTER-pakket',
    standardListItemTwo: 'Geen limiet op het aantal chauffeurs',
    standardListItemThree: '99,9% uptime',
    standardListItemFour: 'Rapporten en voertuigbeheer',
    premiumTitle: 'Premium',
    premiumText: 'Perfect voor limousine-serviceorganisaties die werken met nauwkeurige gegevens.',
    premiumListItemOne: 'INBEGREPEN STANDAARD-pakket',
    premiumListItemTwo: 'BI & Kostenprognose',
    premiumListItemThree: 'Gegevens exporteren',
    premiumListItemFour: 'Wekelijkse en maandelijkse rapporten',
    warningOne: '* De getoonde prijzen zijn maandelijkse abonnementskosten per gebruiker',
    warningTwo:
      '* Na overschakeling naar een nieuw pakket is het niet mogelijk om binnen de volgende 6 maanden naar een kleiner pakket over te schakelen',
    warningThree:
      '* Gebruikers van het demoplan hebben inzicht in de functionaliteiten die worden geboden door het premium pakket',
    warningOneAnnualPackage: 'Meer nodig? Heeft u een grotere organisatie? We hebben een ENTERPRISE-pakket voor u.',
    warningTwoAnnualPackage: 'en ons verkoopteam zal binnenkort contact met u opnemen.',
    packageChange: 'Pakket wijzigen',
    changePackage: 'Weet u zeker dat u wilt overschakelen naar',
    package: 'pakket',
    annualPricing: 'Jaarlijkse prijzen',
    annualPackageType10: 'Team 10',
    annualPackageType25: 'Team 25',
    annualPackageType50: 'Team 50',
    'Team 10': 'Team 10',
    'Team 25': 'Team 25',
    'Team 50': 'Team 50',
    annualPackagesMessage: 'Jaarabonnement per organisatie.',
    annualPackageType10MainMessage: 'Maximaal 10 gebruikersaccounts.',
    annualPackageType25MainMessage: 'Maximaal 25 gebruikersaccounts.',
    annualPackageType50MainMessage: 'Maximaal 50 gebruikersaccounts.',
    annualPackageListItemOne: 'Standaardpakket',
    annualPackageListItemTwo: 'Eenmalige betaling',
    annualPackageListItemThree: 'Prijsvoordelen',
    annualPackageType10Discount: '- tot 65% korting',
    annualPackageType25Discount: '- tot 68% korting',
    annualPackageType50Discount: '- tot 65% korting',
    allFeaturesIncluded: 'Alle functies inbegrepen',
    trialPeriodIncluded: 'Proefperiode inbegrepen',
    unlimitedVehicles: 'Onbeperkte voertuigen',
    unlimitedBookings: 'Onbeperkte boekingen',
    onetimePaymentAnnually: 'Eenmalige jaarlijkse betaling',
  },
  successPaymentPage: {
    message: 'Betaling succesvol. Dank je wel.',
  },
  demoExpiring: {
    notification: 'Melding',
    expiredSubscriptionMessage:
      'Wij informeren u dat uw abonnement op dit platform is verlopen. Om onze diensten te blijven gebruiken, vernieuw alstublieft uw abonnement.',
  },
  login: {
    username: 'Gebruikersnaam',
    password: 'Wachtwoord',
    rememberMe: 'Onthoud mij',
    login: 'Inloggen',
    noAccount: 'Heeft u nog geen account? U kunt zich hier registreren',
    forgetPassword: 'Wachtwoord vergeten? U kunt het hier opnieuw instellen',
    registerHere: 'Hier',
    email: 'E-mail',
    enterEmail: 'Voer uw e-mailadres in',
    enterPassword: 'Voer uw nieuw wachtwoord in',
    signInWithGoogle: 'Inloggen met Google',
  },
  registration: {
    register: 'Registreren',
    name: 'Organisatienaam',
    address: 'Adres',
    phoneNumber: 'Telefoonnummer',
    email: 'E-mail',
    companyId: 'Bedrijfs-ID',
    vat: 'BTW',
    language: 'Taal',
    package: 'Pakket',
    accept: 'Ik accepteer',
    terms: 'de gebruiksvoorwaarden',
    successfullyRegistered: 'De gebruiker is succesvol geregistreerd. Je ontvangt een e-mail met een verificatielink.',
    haveAccount: 'Heb je al een account? Je kunt inloggen',
    loginHere: 'Hier',
    trialPeriodInfo:
      'Inclusief is een gratis proefperiode van 14 dagen. Facturatie volgt na afloop van de proefperiode.',
  },
  driving: {
    drivingType: 'Besteltype',
    general: 'Algemene informatie',
    newDriving: 'Plan een transfer',
    newDailyRent: 'Plan een dagelijkse verhuur',
    areYouSure: 'Weet je het zeker?',
    vehicleIsUnavailable: 'Het voertuig is bezet.',
    vehicleIsDamaged: 'Het voertuig is beschadigd.',
    sureAboutThisVehicle: 'Weet je zeker dat je dit voertuig wilt kiezen?',
    driverUnavaliable: 'De chauffeur is bezig.',
    sureAboutThisDriver: 'Weet je zeker dat je deze chauffeur wilt selecteren?',
    checkVehicleSeatAvailability: 'Dit voertuig kan niet zoveel passagiers vervoeren.',
    cancelDriving: 'Weet je zeker dat je de ritopdracht wilt annuleren?',
    finishDriving: 'Weet je zeker dat je de ritopdracht wilt beëindigen?',
    noShowDriving: 'Weet u zeker dat u de ritbestelling als niet verschenen wilt markeren?',
    deleteDriving: 'Weet je zeker dat je de ritopdracht wilt verwijderen?',
    newTransfer: 'Nieuwe overdracht',
    driving: 'Rijopdracht',
    comments: 'Opmerkingen',
    id: 'Id',
    locationFrom: 'Vertrekpunt',
    locationTo: 'Bestemming',
    vehicleClass: 'Voertuigklasse',
    vehicle: 'Voertuig',
    driver: 'Chauffeur',
    pickupTime: 'Ophaaltijd',
    clientConfirmed: 'Klant bevestigd',
    description: 'Beschrijving',
    extraInfo: 'Extra informatie',
    drivingNotAccepted: 'Ze hebben de rijopdracht niet geaccepteerd:',
    currency: 'Valuta',
    paymentMethod: 'Betaalmethoden',
    price: 'Prijs',
    paid: 'Betaald',
    distance: 'Afstand (km)',
    distanceMi: 'Afstand (mijl)',
    expectedTime: 'Verwachte duur',
    waitingTime: 'Wachttijd (h)',
    EnterTheNumberOfHoursWaiting: 'Voer het aantal wachturen in',
    dropOffTime: 'Aankomsttijd',
    comebackTime: 'Terugkeertijd',
    acceptUntil: 'Accepteer tot',
    drivingRequired: 'Is een rijopdracht verplicht',
    comment: 'Opmerking',
    attachments: 'Bijlagen',
    addFiles: '+ Bestanden toevoegen',
    deleteFiles: 'Weet je zeker dat je dit bestand wilt verwijderen?',
    passengers: 'Passagiers',
    addPassenger: '+ Passagier toevoegen',
    deletePassenger: 'Weet je zeker dat je deze passagier wilt verwijderen?',
    chooseClient: 'Kies klant',
    costs: 'Kosten',
    checkpoints: 'Tussenpunten',
    addCheckpoint: 'Voeg tussenpunt toe',
    deleteCheckpoint: 'Weet u zeker dat u dit tussenpunt wilt verwijderen?',
    waitingBoardText: 'Wachttekst op het bord',
    flightNumber: 'Vluchtnummer',
    flightInfo: 'Vluchtinformatie',
    createdBy: 'Rijbestelling gemaakt om <b>{time}</b> door <b>{user}</b>',
    client: 'Klant',
    gnet: 'GNET',
    note: 'Opmerking',
    noteDriver: 'Opmerking voor bestuurder',
    noteDispatcher: 'Opmerking voor dispatcher',
    passenger: 'Passagier',
    fullName: 'Volledige naam',
    phoneNumber: 'Telefoonnummer',
    email: 'E-mail',
    suitcasesNumber: 'Aantal koffers',
    passengersNumber: 'Aantal passagiers',
    babySeatsNumber: 'Aantal kinderzitjes',
    stopsNumber: 'Aantal stops',
    steps: {
      mainInfo: 'Hoofdinfo',
      additionalInfo: 'Aanvullende informatie',
      review: 'Beoordeling',
    },
    commissionAmount: 'Commissie',
  },
  drivingStatus: {
    pending: 'In afwachting',
    accepted: 'Geaccepteerd',
    rejected: 'Afgewezen',
    expired: 'Verlopen',
    canceled: 'Geannuleerd',
    draft: 'Concept',
    done: 'Voltooid',
    noShow: 'Niet verschenen',
  },
  drivingOrderType: {
    dailyRent: 'Dagelijkse verhuur',
    transfer: 'Overdracht',
  },
  flightInfo: {
    airlineName: 'Luchtvaartmaatschappij',
    flightNumber: 'Vluchtnummer',
    departureAirport: 'Vertrekluchthaven',
    departureCountry: 'Vertrekland',
    departureTime: 'Vertrektijd',
    arrivalAirport: 'Aankomstluchthaven',
    arrivalCountry: 'Aankomstland',
    arrivalTime: 'Aankomsttijd',
    departureDelayed: 'Vertrek vertraagd',
    flightStatus: 'Vluchtstatus',
    percentageOfFlight: 'Percentage van de vlucht',
  },
  customValidations: {
    fileType: `Het bestandstype .{extension} wordt niet ondersteund`,
    fileSize: 'Bestanden mogen niet groter zijn dan {size}',
    fieldRequired: 'Veld {field} is verplicht',
    fieldType: 'Veld {field} moet van het type {type} zijn',
    fieldLengthMin: 'Het veld {field} moet {length} of meer zijn',
    fieldLengthMax: 'Het veld {field} moet {length} of minder zijn',
    fieldUnique: 'Veld {field} moet uniek zijn',
    fieldValid: 'Veld {field} moet geldig zijn',
    noActiveSubscription: 'Geen actief abonnement gevonden',
    usersLimitExceeded: 'Gebruikerslimiet bereikt',
    fieldBefore: '{field1} moet voor {field2} zijn',
    flightNotExist: 'De vlucht {field} bestaat niet',
    gnetKeyNotValid: 'De opgegeven Gnet-sleutel is niet geldig.',
    gnetPassengersRequired: 'Rijopdracht kan niet naar GNET worden gestuurd zonder passagiers.',
    priceAndCurrencyRequired: 'Betaallink kan niet worden gegenereerd zonder prijs en valuta',
    fieldValueNotSupported: 'De waarde van het veld {field} wordt niet ondersteund',
    noCountryCode: 'U heeft geen land geselecteerd',
    noStripeForYourCountry: 'Stripe is niet beschikbaar in uw land',
    stripeAlreadyEnabled: 'Stripe is al geactiveerd',
    fieldValueMin: 'Het veld {field} moet {min} of meer zijn',
    fieldValueMax: 'Het veld {field} moet {max} of minder zijn',
  },
  fieldTypes: {
    string: 'tekenreeks',
    integer: 'nummer',
    date: 'datum',
  },
  names: {
    username: 'gebruikersnaam',
    password: 'wachtwoord',
    choose_client: 'kies klant',
    choose_month: 'kies maand',
    currency: 'valuta',
    payment_method: 'betaalmethode',
    vehicle_class: 'voertuigklasse',
    first_name: 'voornaam',
    last_name: 'achternaam',
    passport: 'paspoort',
    nationality: 'nationaliteit',
    plate_number: 'kenteken',
    brand: 'automerk',
    number_of_passengers: 'aantal passagiers',
    fuel_consumption: 'brandstofverbruik',
    year_of_manufacture: 'fabricagejaar',
    price_per_km: 'prijs per km',
    price_per_mi: 'prijs per mijl',
    price_per_hour: 'prijs per uur',
    price_per_waiting_hour: 'prijs per wachtuur',
    vehicle: 'voertuig',
    description: 'omschrijving',
    time_from: 'tijd van',
    time_to: 'tijd tot',
    name: 'naam',
    cost_type: 'kostentype',
    cost_time: 'kostentijd',
    price: 'prijs',
    rebate_min: 'minimum aantal ritten',
    rebate_max: 'maximum aantal ritten',
    rebate: 'korting',
    phone_number: 'telefoonnummer',
    organisation: 'organisatie',
    role: 'rol',
    image: 'afbeelding',
    location_from: 'locatie van',
    location_to: 'locatie naar',
    pickup_time: 'ophaaltijd',
    distance: 'afstand',
    waiting_time: 'wachttijd',
    comment: 'opmerking',
    address: 'adres',
    vat: 'BTW',
    company_id: 'bedrijfsidentificatienummer',
    company_personal_id: 'bedrijfs-id/persoonlijke id',
    licence: 'vergunning',
    warrant_number: 'garantienummer',
    seal: 'zegel',
    signature: 'handtekening',
    email: 'e-mail',
    percentage: 'percentage',
    organizationName: 'nome da organização',
    package: 'paket',
    choose_currency: 'kies valuta',
    team_10_price: 'team 10 prijs',
    team_10_paddle_price_id: 'ID team 10 Paddle prijs',
    team_25_price: 'team 25 prijs',
    team_25_paddle_price_id: 'ID team 25 Paddle prijs',
    team_50_price: 'team 50 prijs',
    team_50_paddle_price_id: 'ID team 50 Paddle prijs',
    countries: 'landen',
    invoice_number: 'factuurnummer',
    status: 'status',
    total: 'totaal',
    billed_at: 'gefactureerd op',
    articles_of_law: 'artikelen van wet',
    country: 'land',
    referent: 'referent',
    language: 'taal',
    location: 'locatie',
    arrival_time: 'aankomsttijd',
    waiting_board_text: 'wachttekst op het bord',
    flight_number: 'vluchtnummer',
    gnet_id: 'GNET-ID',
    receiver_id: 'Ontvanger-ID',
    preferred_vehicle_type: 'voorkeur voertuigtype',
    reservation_type: 'reserveringstype',
    run_type: 'rittype',
    trip_duration_minutes: 'reisduur',
    client: 'klant',
    client_type: 'klanttype',
    card_holder_name: 'naam kaarthouder',
    card_number: 'kaartnummer',
    expiry_date: 'vervaldatum',
    cvv: 'cvv',
    code: 'code',
    symbol: 'symbool',
    quantity: 'hoeveelheid',
    discount: 'korting',
    driving_type: 'type rijden',
    full_name: 'volledige naam',
    suitcases_number: 'aantal koffers',
    passengers_number: 'aantal passagiers',
    baby_seats_number: 'aantal kinderzitjes',
    stops_number: 'aantal stops',
    commission_amount: 'commissie',
  },
  welcomeModal: {
    welcome: 'Welkom bij LimoExpress',
  },
  weekDays: {
    monday: 'maandag',
    tuesday: 'dinsdag',
    wednesday: 'woensdag',
    thursday: 'donderdag',
    friday: 'vrijdag',
    saturday: 'zaterdag',
    sunday: 'zondag',
  },
  select: {
    noDataAvailable: 'Geen gegevens beschikbaar',
  },
  excelExport: {
    to_date_range: 'Het rapport kan niet worden gegenereerd voor toekomstige data. Voer alstublieft geldige data in.',
    overlap_range: 'Eindtijd kan niet groter zijn dan de begintijd.',
  },
  setPassword: {
    success: 'E-mail verzonden',
    checkEmail: 'Controleer uw e-mail voor verdere instructies.',
    already_changed: 'Al gewijzigd',
    send_new_reset_request:
      'U heeft uw wachtwoord al gewijzigd met deze link. Stuur een nieuw verzoek om het wachtwoord te resetten.',
  },
  statisticsPage: {
    reportType: 'Type rapport',
    reportTime: 'Rapportagetijd',
    vehicleUtilization: 'Voertuiggebruik',
    costEffectiveness: 'Kosteneffectiviteit',
    generalReport: 'Algemeen rapport',
    clientsReport: 'Rapport per Klant',
  },
  profile: {
    yourProfile: 'Uw profiel',
    name: 'Naam',
    role: 'Rol',
    username: 'Gebruikersnaam',
    password: 'Wachtwoord',
    phoneNumber: 'Telefoonnummer',
    email: 'E-mail',
    percentage: 'Percentage',
  },
  gpsMaps: {
    noTransfers: 'Er zijn geen lopende of aankomende transfers.',
    map: 'Kaart',
    transfers: 'Overdrachten',
    drivers: 'Chauffeurs',
    ongoing: 'Aan de gang',
    upcoming: 'Komende',
    driving: 'Rijden',
    driver: 'Chauffeur',
    vehicle: 'Voertuig',
    requestDriverLocation: 'Verzoek om locatie van chauffeur',
    lastRecordedLocation: 'Laatst opgenomen locatie',
  },
  driverStatus: {
    available: 'Beschikbaar',
    offline: 'Offline',
    inTransfer: 'In overdracht',
  },
  onboardingTutorialMain: {
    languageStep: 'Hier kunt u uw voorkeurstaal voor de app instellen.',
    notificationsStep: 'Dit zijn uw meldingen.',
    addTransferStep:
      'Klik op deze knop om uw transfers en dagelijkse verhuringen te maken. Dagelijkse verhuringen zijn aangepaste transfers zonder bestemming.',
    sideMenuButtonStep: 'Klik hier om de menubalk weer te geven of te verbergen.',
    sideMenuExpStep:
      'Vanaf hier kunt u navigeren naar de hoofdsecties van de applicatie: Transfers, Vloot, Kosten, Administratie, Statistieken en Transacties.',
    sideMenuButtonCloseStep: 'Klik opnieuw op het pictogram om de menubalk te verbergen.',
    supportStep:
      'Als je hulp nodig hebt bij een probleem, aarzel dan niet om contact op te nemen met de ondersteuning door hier te klikken.',
  },
  onboardingTutorialVehicles: {
    tableStep: 'Uw voertuigen worden hier vermeld in een tabel, nadat u ze heeft toegevoegd.',
    searchStep: 'U kunt voertuigen gemakkelijker vinden door er direct naar te zoeken.',
    addStep:
      "Voeg nieuwe voertuigen toe met de knop 'Toevoegen'. Let op dat u eerst 'Voertuigklassen' moet toevoegen voordat u deze functie gebruikt.",
  },
  onboardingTutorialStatistics: {
    typeStep:
      'In deze sectie vindt u al uw statistische gegevens weergegeven in grafieken. Selecteer hier het type statistiek.',
    intervalStep: 'Voeg een interval toe waarvoor u statistieken wilt bekijken.',
    downloadStep: 'U kunt deze tabellen ook downloaden en afdrukken.',
  },
  onboardingTutorialGps: {
    tabOneStep:
      'In het GPS-module kunt u uw chauffeurs en transfers op dezelfde plaats volgen. Kies deze tab om lopende en toekomstige transfers te bekijken.',
    tabTwoStep:
      'Selecteer voertuigen om ze op de kaart te zien. U kunt vrije voertuigen direct vanaf hier aan transfers toevoegen.',
    mapStep: 'Selecteer of deselecteer voertuigen door op hun pin op de kaart te klikken.',
  },
  advancedSettings: {
    advancedSettings: 'Geavanceerde instellingen',
    distanceUnit: 'Afstandseenheid',
    distanceUnitDescription: 'Selecteer de eenheid voor afstandsmetingen.',
    kilometers: 'Kilometers',
    miles: 'Mijlen',
    dateFormat: 'Datumnotatie',
    dateFormatDescription: 'Selecteer de datumnotatie die u wilt gebruiken.',
    ddmmyyyy: 'DD-MM-YYYY',
    mmddyyyy: 'MM-DD-YYYY',
    mandatoryDrivings: 'Verplichte rijopdrachten',
    mandatoryDrivingsDescription: 'Kies of je alle rijopdrachten standaard verplicht wilt maken.',
    mandatoryDrivingsDefault: 'Verplichte Rijopdrachten (Standaard)',
    paidDrivings: 'Betaalde rijopdrachten',
    paidDrivingsDescription: 'Selecteer om alle rijopdrachten standaard betaald te maken.',
    paidDrivingsDefault: 'Betaalde rijopdrachten (Standaard)',
    notificationsEmail: 'E-mail voor meldingen',
    notificationsEmailDescription:
      'Wijzig het standaard e-mailadres om meldingen te ontvangen (als je niet het e-mailadres van je organisatie wilt gebruiken).',
    email: 'E-mail',
    articlesOfLaw: 'Artikelen van wet',
    integrations: 'Integraties',
    gnetId: 'GNET ID',
    gnetDescription:
      'GNET is een uitgebreid platform ontworpen om real-time tracking, vlootbeheer en dispatch-activiteiten voor de transportindustrie te optimaliseren.',
    makeRoutesAvailable: 'Maak integratieroutes beschikbaar',
    makeRoutesAvailableDescription:
      'Activeer uw API-sleutel om LimoExpress te integreren met uw website, ERP-systeem en meer.',
    makePublicLinkAvailable: 'Maak openbare link beschikbaar',
    makePublicLinkAvailableDescription:
      'Activeer de link om toegang te krijgen tot de openbare boekingspagina, zodat uw klanten toegang hebben tot het geavanceerde boekingsformulier en eenvoudig accounts kunnen aanmaken. U kunt de link gebruiken in nieuwsbrieven, op de website, flyers; het is een unieke link alleen voor uw bedrijf.',
    emailsEnabled: 'E-mails ingeschakeld',
    emailsEnabledDefault: 'E-mails ingeschakeld (Standaard)',
    emailsEnabledDescription: 'Vink aan om e-mailmeldingen te ontvangen.',
    passengerReviewsEnabled: 'Passagiersbeoordelingen ingeschakeld',
    passengerReviewsEnabledDefault: 'Passagiersbeoordelingen ingeschakeld',
    passengerReviewsEnabledDescription:
      'Door deze functie in te schakelen, ontvangen uw passagiers (als er een e-mailadres is ingevoerd) een bevestigingsmail nadat de rit is voltooid (gemarkeerd als Voltooid) en krijgen ze de mogelijkheid om beoordelingen (feedback) te geven over uw diensten.',
    vat: 'BTW',
    vatPercentage: 'Btw-percentage',
    vatPercentageDescription: 'Voer het btw-percentage in.',
    invoicePaymentInstructions: 'Instructies voor factuurbetaling',
    stripe: 'Stripe',
    stripeDescription:
      'Activeer Stripe om uw klanten te kunnen factureren voor uw diensten. LimoExpress neemt geen commissie.',
  },
  drivingExcelCheckboxes: {
    drivingNumber: 'Bestelnummer',
    drivingType: 'Besteltype',
    drivingStatus: 'Status',
    locationFrom: 'Vertrekpunt',
    locationTo: 'Bestemming',
    driver: 'Bestuurder',
    client: 'Klant',
    plateNumber: 'Kentekennummer',
    note: 'Beschrijving',
    fuelConsumption: 'Brandstofverbruik',
    pickupTime: 'Ophaaltijd',
    numberOfPassengers: 'Aantal passagiers',
    passengers: 'Passagiers',
    price: 'Prijs',
    paid: 'Betaald',
    paymentMethod: 'Betalingsmethode',
    waitingTime: 'Wachttijd (h)',
    distance: 'Afstand (km)',
    distanceMi: 'Afstand (mijl)',
    selectAll: 'Alles selecteren',
  },
  gnet: {
    gnetInfo: 'GNET informatie',
    requesterId: 'Aanvrager-ID',
    receiverId: 'Ontvanger-ID',
    preferredVehicleType: 'Voorkeur voertuigtype',
    reservationType: 'Reserveringstype',
    runType: 'Rittype',
    price: 'Prijs',
    currency: 'Valuta',
    noteDriver: 'Opmerking voor bestuurder',
    noteDispatcher: 'Opmerking voor dispatcher',
    acceptDriving: 'Weet je zeker dat je de rijopdracht wilt accepteren?',
    rejectDriving: 'Weet je zeker dat je de rijopdracht wilt weigeren?',
    tripDurationMinutes: 'Reisduur (minuten)',
  },
  invoicesHeaders: {
    invoiceNumber: 'Factuurnummer',
    client: 'Klant',
    totalPrice: 'Totale prijs',
    drivings: 'Ritten',
    paid: 'Betaald',
    currency: 'Valuta',
    createdAt: 'Gemaakt op',
    actions: 'Acties',
  },
  invoicesPage: {
    newItem: 'Nieuwe factuurinvoer',
    chooseClient: 'Kies klant',
    chooseDrivings: 'Kies ritten',
    deleteMessage: 'Weet je zeker dat je deze factuur wilt verwijderen?',
  },
  drivingOffersHeaders: {
    number: 'Rij-aanbiedingsnummer',
    totalPrice: 'Totale prijs',
    description: 'Beschrijving',
    paid: 'Betaald',
    currency: 'Valuta',
    createdAt: 'Gemaakt op',
    actions: 'Acties',
  },
  drivingOffersPage: {
    newItem: 'Nieuwe rij-aanbieding invoer',
    item: 'Item',
    description: 'Beschrijving',
    title: 'Titel',
    vehicleClass: 'Voertuigklasse',
    quantity: 'Hoeveelheid',
    price: 'Prijs',
    discount: 'Korting',
    vat: 'BTW',
    deleteMessage: 'Weet u zeker dat u deze rij-aanbieding wilt verwijderen?',
  },
  modal: {
    reservationSuccessTitle: 'Succes',
    reservationSuccessMessage: 'Reserveringsverzoek succesvol verzonden. Iemand zal binnenkort contact met u opnemen.',
  },
  dashboardPage: {
    drivingCountToday: 'Aantal ritten (vandaag)',
    drivingCountThisMonth: 'Aantal ritten (laatste 30 dagen)',
    dailyRentsAndTransfers: 'Aantal ritten (dagelijkse verhuur/transfers)',
    drivingTotalAmount: 'Totaalbedrag',
    driversCount: 'Actieve chauffeurs (±7 dagen)',
    passengerCount: 'Aantal passagiers (laatste 30 dagen)',
  },
  reviewsHeaders: {
    rating: 'Beoordeling',
    comment: 'Opmerking',
  },
  resourceOverviewHeaders: {
    actionType: 'Actietype',
    user: 'Gebruiker',
    action: 'Actie',
    createdAt: 'Aangemaakt op',
  },
  resourceOverviewPage: {
    smsMessagesLeft: 'Aantal resterende SMS-berichten',
    flightsCheckLeft: 'Aantal resterende vluchtcontroles',
    sms: 'SMS',
    flightCheck: 'Vluchtcontrole',
  },
};
