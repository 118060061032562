export default {
  sidebar: {
    fleet: 'Flota',
    vehicles: 'Vozila',
    vehiclesClasses: 'Klase vozila',
    administration: 'Administracija',
    currencies: 'Valute',
    currenciesAdministration: 'Administracija valuta',
    paymentTypes: 'Načini plaćanja',
    paymentTypesAdministration: 'Administracija načina plaćanja',
    users: 'Korisnici',
    usersAdministration: 'Administracija korisnika',
    clients: 'Klijenti',
    clientsAdministration: 'Administracija klijenata',
    organizations: 'Organizacije',
    organizationsAdministration: 'Administracija organizacija',
    saasAdmin: 'Saas admin',
    administrators: 'Administratori',
    saasAdminAdministration: 'Administracija Saas admina',
    statistics: 'Statistika',
    generalOverview: 'Opći pregled',
    payment: 'Plaćanje',
    rebates: 'Popusti',
    pricingRegions: 'Cijene područja',
    pricingRegionsAdministration: 'Upravljanje cijenama područja',
    help: 'Pomoć',
    drivings: 'Vožnje',
    calendar: 'Kalendar',
    tabularView: 'Tablični prikaz',
    expenses: 'Troškovi',
    vehicleExpensesAdministration: 'Administracija troškova vozila',
    vehicleMaintenance: 'Održavanje vozila',
    vehicleMaintenanceAdministration: 'Administracija održavanja vozila',
    organization: 'Organizacija',
    organizationAdminstration: 'Administracija organizacije',
    packages: 'Paketi',
    packagesChange: 'Promjena paketa',
    logout: 'Odjava iz sustava',
    logoutText: 'Jeste li sigurni da želite se odjaviti iz sustava?',
    unaccessibleRoutes: 'Ne možete pristupiti ovoj stranici. Nadogradite paket za potpuni pristup.',
    transactions: 'Transakcije',
    gpsMap: 'GPS karta',
    team: 'Tim',
    teamAdministration: 'Administracija tima',
    invoices: 'Računi',
    invoicesAdministration: 'Administracija računa',
    drivingOffers: 'Ponude',
    drivingOffersAdministration: 'Administracija ponuda',
    dashboard: 'Dashboard',
    dashboardAdministration: 'Administracija dashboard-a',
    resourceOverview: 'Pregled resursa',
    resourceOverviewAdministration: 'Administracija pregleda resursa',
    rides: 'Vožnje',
  },
  header: {
    organization: 'Organizacija',
    newDriving: 'Vožnja',
    dailyRent: 'Dnevni najam',
    transfer: 'Prijenos',
    notifications: 'Obavijesti',
    changeLanguage: 'Promijeni jezik',
  },
  footer: {
    poweredBy: 'Pokreće',
    subscriptionTo: 'Vaša pretplata na',
    trialEndsAt: 'Probni period za',
    packageExpiresIn: 'paketa ističe za',
    clickToChangePackage: 'Kliknite za promjenu paketa',
    clickToActivatePackage: 'Kliknite za aktivaciju paketa',
    clickToPay: 'Kliknite za plaćanje',
    packageHasExpired: 'paket je istekla',
  },
  buttons: {
    ok: 'U redu',
    yes: 'Da',
    no: 'Ne',
    save: 'Spremi',
    send: 'Pošalji',
    sendMessage: 'Pošalji poruku',
    edit: 'Uredi',
    add: 'Dodaj',
    confirm: 'Potvrdi',
    cancel: 'Otkaži',
    cancelDriving: 'Otkaži vožnju',
    markAsDone: 'Označi kao završeno',
    markAsNoShow: 'Označi kao nepojavljivanje',
    deleteDriving: 'Izbriši nalog',
    newOrganisation: 'Nova organizacija',
    changePackage: 'Promjena paketa',
    downloadStats: 'Preuzimanje statistike',
    downloadPDF: 'Preuzmi PDF',
    today: 'Danas',
    choose: 'Odaberi',
    login: 'Prijava',
    generateFile: 'Generiraj ugovor',
    generateDrivingOrder: 'Ispis naručivanja vožnje',
    downloadFile: 'Preuzmi datoteku',
    activate: 'Aktiviraj',
    deactivate: 'Deaktiviraj',
    showImage: 'Prikaži sliku',
    showDetails: 'Prikaži detalje',
    actions: 'Radnje',
    delete: 'Obriši',
    open: 'Otvori',
    show: 'Prikaži',
    options: 'Opcije',
    prev: 'Prethodno',
    next: 'Sljedeće',
    skip: 'Preskoči',
    finish: 'Završi',
    settings: 'Postavke',
    cancelSubscription: 'Otkaži pretplatu',
    backToEditOrganization: 'Natrag na uređivanje organizacije',
    payNow: 'Platite sada',
    back: 'Idi natrag',
    integrationRoutes: 'Rute integracije',
    transactions: 'Transakcije',
    articlesOfLaw: 'Članci zakona',
    export: 'Izvoz',
    duplicate: 'Napravi kopiju',
    makeReturnTrip: 'Napravite povratno putovanje',
    selectOrganisation: 'Odaberite organizaciju',
    advancedSettings: 'Paramètres avancés',
    accept: 'Prihvati',
    reject: 'Odbij',
    sendViaGnet: 'Pošalji putem GNET-a',
    printInvoice: 'Ispis fakture',
    enable: 'Omogući',
    disable: 'Onemogući',
    generatePaymentLink: 'Generiraj link za plaćanje',
    addItem: '+ Dodaj stavku',
  },
  tooltips: {
    save: 'Spremi',
    edit: 'Uredi',
    delete: 'Obriši',
    showImage: 'Prikaži sliku',
    open: 'Otvori',
    show: 'Prikaži',
    showDetails: 'Prikaži detalje ',
    downloadFile: 'Preuzmi datoteku',
    generateFile: 'Generiraj ugovor',
    generateDrivingOrder: 'Ispis naručivanja vožnje',
    activate: 'Aktiviraj',
    deactivate: 'Deaktiviraj',
    cancel: 'Otkaži',
    cancelDriving: 'Otkaži nalog',
    cannotMarkAsCanceled: 'Nalog ne može biti označen kao otkazan jer je već prihvaćen',
    markAsDone: 'Označi kao završeno',
    cannotMarkAsDone: 'Nalog ne može biti označen kao završen, jer se još nije dogodio',
    markAsNoShow: 'Označi kao nepojavljivanje',
    cannotMarkAsNoShow: 'Narudžbina se ne može označiti kao nepojavljivanje jer se još nije dogodila',
    deleteDriving: 'Izbriši nalog',
    cannotDelete: 'Žao nam je, ali iz sigurnosnih razloga korisnici se ne mogu brisati dok su trenutno prijavljeni.',
    duplicate: 'Napravi kopiju',
    makeReturnTrip: 'Napravite povratno putovanje',
    refresh: 'Osvježi',
    selectOrganisation: 'Odaberite organizaciju',
    sendViaGnet: 'Pošalji putem GNET-a',
    receivedViaGnet: 'Primljeno putem GNET-a',
    sentViaGnet: 'Poslano putem GNET-a',
    printInvoice: 'Ispis fakture',
    optionsDisabledGnet: 'Morate odbiti ili prihvatiti nalog da biste pristupili izborniku opcija',
    cannotMakeReturnTrip: 'Ne možete napraviti povratno putovanje za dnevni najam',
    cannotSendGnetOrderViaGnet: 'Ne možete poslati Gnet nalog putem Gnet-a',
    cannotSendViaGnetWithoutGnetId: 'Ne možete poslati nalog putem Gnet-a bez Gnet ID-a',
    cannotPrintInvoiceWithoutClient: 'Ne možete ispisati fakturu bez odabira klijenta',
    cannotMarkAsDoneStatusRejected: 'Nalog ne može biti označen kao završen jer je već označen kao odbijen',
    cannotMarkAsDoneStatusCanceled: 'Nalog ne može biti označen kao završen jer je već označen kao otkazan',
    cannotMarkAsDoneStatusDone: 'Nalog ne može biti označen kao završen jer je već označen kao završen',
    cannotMarkAsDoneStatusNoShow: 'Nalog ne može biti označen kao završen jer je već označen kao nedolazak',
    cannotMarkAsNoShowStatusRejected: 'Nalog ne može biti označen kao nedolazak jer je već označen kao odbijen',
    cannotMarkAsNoShowStatusCanceled: 'Nalog ne može biti označen kao nedolazak jer je već označen kao otkazan',
    cannotMarkAsNoShowStatusDone: 'Nalog ne može biti označen kao nedolazak jer je već označen kao završen',
    cannotMarkAsNoShowStatusNoShow: 'Nalog ne može biti označen kao nedolazak jer je već označen kao nedolazak',
    cannotMarkAsCanceledGnet: 'Nalog ne može biti označen kao otkazan jer je primljen putem Gnet-a',
    cannotMarkAsCanceledStatusRejected: 'Nalog ne može biti označen kao otkazan jer je već označen kao odbijen',
    cannotMarkAsCanceledStatusExpired: 'Nalog ne može biti označen kao otkazan jer je već označen kao istekao',
    cannotMarkAsCanceledStatusCanceled: 'Nalog ne može biti označen kao otkazan jer je već označen kao otkazan',
    cannotMarkAsCanceledStatusDraft: 'Nalog ne može biti označen kao otkazan jer je već označen kao nacrt',
    cannotMarkAsCanceledStatusCompleted: 'Nalog ne može biti označen kao otkazan jer je već označen kao završen',
    cannotMarkAsCanceledStatusNoShow: 'Nalog ne može biti označen kao otkazan jer je već označen kao nedolazak',
    cannotDeleteDrivingSentViaGnet: 'Nalog ne može biti obrisan jer je poslan putem Gnet-a',
    cannotDeleteDrivingReceivedViaGnet: 'Nalog ne može biti obrisan jer je primljen putem Gnet-a i prihvaćen',
    generatePaymentLink: 'Generiraj link za plaćanje',
    cannotGeneratePaymentLInk: 'Link za plaćanje ne može biti generiran jer niste aktivirali Stripe',
  },
  snackbar: {
    close: 'Zatvori',
    savedSuccessfully: 'Uspješno spremljeno',
    excelDownloadedSuccessfully: 'Uspješno preuzeto',
    deletedSuccessfully: 'Uspješno izbrisano',
    sentSuccessfully: 'Uspješno poslano',
    activeStatusChanged: 'Aktivni status uspješno promijenjen',
    defaultStatusChanged: 'Status zadane vrijednosti uspješno promijenjen',
    primaryStatusChanged: 'Primarni status uspješno promijenjen',
    anErrorOccured: 'Došlo je do pogreške, javite se podršci',
    loadingCommentsFailed: 'Nije uspjelo učitavanje komentara',
    drivingCanceled: 'Naručivanje vožnje uspješno otkazano',
    drivingFinished: 'Naručivanje vožnje uspješno završeno',
    drivingNoShow: 'Nalog za vožnju je uspješno označen kao nepojavljivanje.',
    drivingDeleted: 'vozačka dozvola je uspješno izbrisana',
    drivingAccepted: 'Vožnja je uspješno prihvaćena',
    drivingRejected: 'Vožnja je uspješno odbijena',
    planChanged: 'Plan uspješno promijenjen',
    planChangeFailed: 'Nije moguće promijeniti plan',
    saveChangesFirst: 'Prvo spremiti promjene koje ste napravili ranije',
    makeRoutesPublic: 'Rute su javno dostupne',
    makeRoutesNotPublic: 'Rute više nisu javno dostupne',
    makePublicLinkActive: 'Javna poveznica je sada dostupna',
    makePublicLinkInactive: 'Javna poveznica više nije dostupna',
    integrationKeyCopied: 'Ključ integracije kopiran',
    copyToken: 'Ručno kopiranje tokena',
    publicLinkCopied: 'Javna poveznica je kopirana',
    copyPublicLink: 'Ručno kopirajte javnu poveznicu',
    organisationChanged: 'Organizacija uspješno promijenjena',
    changeFailed: 'Promjena nije uspjela, molimo pokušajte ponovno',
    loginFailed: 'Korisničko ime ili lozinka nisu ispravni',
    organisationSuspended: 'Vaša organizacija je suspendirana',
    userEmailDoesNotExists: 'Korisnik s ovom email adresom ne postoji',
    canceledSubscrSuccessfully: 'Uspješno ste otkazali pretplatu',
    toManyRequests: 'Previše zahtjeva. Pokušajte ponovo za minutu.',
    visibleStatusChanged: 'Vidljivi status uspješno promijenjen',
    noInternetConnection: 'Nema interneta. Molimo provjerite vašu internetsku vezu.',
    backOnline: 'Ponovo ste online.',
    driverNotifiedToTurnGpsOn: 'Vozač obaviješten da uključi GPS',
    savedSuccessfullyWithGnetError: 'GNET sinhronizacija nije uspjela, nalog za vožnju je uspješno spremljen.',
    logoutFromAnotherDevice: 'Odjavljeni ste s drugog uređaja.',
    stripeEnabled: 'Stripe uspješno aktiviran',
    stripeDisabled: 'Stripe uspješno deaktiviran',
    finishStripeConnect: 'Molimo završite postavljanje Stripe-a',
  },
  filters: {
    filter: 'Filtriraj',
    currentYear: 'Trenutna godina',
    lastYear: 'Prošla godina',
    lastQuarter: 'Posljednji kvartal',
    lastThreeYears: 'Posljednje tri godine',
    filterByDriver: 'Filtriraj po vozaču',
    filterByVehicle: 'Filtriraj po vozilu',
    filterByClient: 'Filtriraj po klijentu',
    day: 'Dan',
    week: 'Tjedan',
    month: 'Mjesec',
    vehicle: 'Vozilo',
    driving: 'Vožnja',
    user: 'Korisnik',
    fromDate: 'Od datuma',
    toDate: 'Do datuma',
    threeMonths: 'Tri mjeseca',
    halfYear: 'Pola godine',
    year: 'Godina',
  },
  months: {
    jan: 'siječanj',
    feb: 'veljača',
    mar: 'ožujak',
    apr: 'travanj',
    may: 'svibanj',
    jun: 'lipanj',
    jul: 'srpanj',
    aug: 'kolovoz',
    sep: 'rujan',
    oct: 'listopad',
    nov: 'studeni',
    dec: 'prosinac',
    January: 'siječanj',
    February: 'veljača',
    March: 'ožujak',
    April: 'travanj',
    May: 'svibanj',
    June: 'lipanj',
    July: 'srpanj',
    August: 'kolovoz',
    September: 'rujan',
    October: 'listopad',
    November: 'studeni',
    December: 'prosinac',
  },
  usersPageHeaders: {
    firstName: 'Ime',
    lastName: 'Prezime',
    username: 'Korisničko ime',
    email: 'Email',
    phoneNumber: 'Broj telefona',
    role: 'Uloga',
    active: 'Aktivan',
    actions: 'Radnje',
  },
  usersRoles: {
    masterAdmin: 'Glavni administrator',
    manager: 'Upravitelj',
    driver: 'Vozač',
    operator: 'Operator',
    externalAssociate: 'Vanjski suradnik',
    sales: 'Prodaja',
    salesLead: 'Vođa prodaje',
    gnet: 'GNET',
    customer: 'Korisnik portala',
  },
  masterUsersPageHeaders: {
    firstName: 'Ime',
    lastName: 'Prezime',
    username: 'Korisničko ime',
    email: 'Email',
    phoneNumber: 'Broj telefona',
    role: 'Uloga',
    organisation: 'Organizacija',
    active: 'Aktivan',
    actions: 'Radnje',
  },
  usersPage: {
    newItem: 'Novi unos korisnika',
    editItem: 'Uredi korisnika',
    id: 'ID',
    firstName: 'Ime',
    lastName: 'Prezime',
    username: 'Korisničko ime',
    password: 'Lozinka',
    phoneNumber: 'Broj telefona',
    role: 'Uloga',
    client: 'Klijent',
    email: 'Email',
    organisation: 'Organizacija',
    percentage: 'Postotak',
    externalAssociate: 'Vanjski suradnik',
    image: 'Slika',
    deleteUser: 'Izbriši korisnika',
    deleteMessage: 'Jeste li sigurni da želite izbrisati korisnika ',
    emailChanged: 'Promjena email adrese',
    verificationEmailSent:
      'Zahtjev za promjenom email adrese je poslan. Da biste dovršili postupak i osigurali sigurnost korisničkog naloga, novu email adresu treba verificirati klikom na verifikacijsku poveznicu poslanu na novu email adresu. Email adresa neće biti promijenjena dok se uspješno ne verificira.',
  },
  clientsPageHeaders: {
    name: 'Ime',
    phoneNumber: 'Broj telefona',
    address: 'Adresa',
    email: 'Email',
    companyId: 'OIB/Osobna iskaznica',
    clientType: 'Vrsta klijenta',
    percentage: 'Postotak',
    active: 'Aktivan',
    actions: 'Radnje',
  },
  clientsPage: {
    newItem: 'Novi unos klijenta',
    editItem: 'Uredi klijenta',
    id: 'ID',
    name: 'Ime',
    address: 'Adresa',
    phoneNumber: 'Broj telefona',
    email: 'Email',
    companyId: 'OIB/Osobna iskaznica',
    clientType: 'Vrsta klijenta',
    individual: 'Fizička osoba',
    business: 'Pravna osoba',
    percentage: 'Postotak',
    deleteClient: 'Izbriši klijenta',
    deleteMessage: 'Jeste li sigurni da želite izbrisati klijenta ',
    tabs: {
      general: 'Općenito',
      transfers: 'Transferi',
      users: 'Korisnici',
      invoices: 'Računi',
      cards: 'Kartice',
    },
  },
  drivingsHeaders: {
    drivingNumber: 'Broj nalog',
    drivingType: 'Tip naloga',
    drivingStatus: 'Status',
    locationFrom: 'Polazište',
    locationTo: 'Odredište',
    vehicleClass: 'Klasa vozila',
    vehicle: 'Vozilo',
    driversName: 'Vozač',
    startTime: 'Vrijeme polaska',
    actions: 'Radnje',
    exportExcel: 'Izvoz u Excel',
    price: 'Cijena',
    totalPrice: 'Ukupna cijena',
    confirmed: 'Potvrđeno',
    paid: 'Plaćeno',
    numberOfDrivings: 'Broj vožnji',
  },
  vehiclesHeaders: {
    plateNumber: 'Registracija vozila',
    brand: 'Marka vozila',
    vehicleClass: 'Klasa vozila',
    passengersNumber: 'Broj putnika',
    fuelConsumption: 'Potrošnja goriva na 100 km',
    yearOfManufacture: 'Godina proizvodnje',
    pricePerKm: 'Cijena po km',
    pricePerMi: 'Cijena po milji',
    pricePerHour: 'Cijena po satu',
    pricePerWaitingHour: 'Cijena čekanja po satu',
    active: 'Aktivan',
    actions: 'Radnje',
  },
  vehiclesPage: {
    id: 'ID',
    newItem: 'Novi unos vozila',
    editItem: 'Uredi vozilo',
    vehiclePlates: 'Registracijske oznake',
    vehicleBrand: 'Marka vozila',
    vehicleClass: 'Klasa vozila',
    passengersNumber: 'Broj putnika',
    fuelConsumption: 'Potrošnja goriva (100km)',
    fuelConsumptionMi: 'Potrošnja goriva automobila (100 milja)',
    manufactureYear: 'Godina proizvodnje',
    price: 'Cijena po km',
    priceMi: 'Cijena po milji',
    hourPrice: 'Cijena po satu',
    image: 'Slika',
    hourWaitingPrice: 'Cijena čekanja po satu',
    deleteMessage: 'Jeste li sigurni da želite izbrisati vozilo?',
    tabs: {
      general: 'Općenito',
      transfers: 'Prijenosi',
      costs: 'Troškovi',
      damages: 'Oštećenja',
    },
    licence: 'Broj licence',
  },
  vehicleClassesHeaders: {
    vehicleClass: 'Klasa vozila',
    active: 'Aktivan',
    actions: 'Radnje',
  },
  vehicleClassesPage: {
    newItem: 'Nova klasa vozila',
    editItem: 'Uredi klasu vozila',
    id: 'ID',
    vehicleClass: 'Klasa vozila',
    hourWaitingPrice: 'Cijena čekanja po satu',
    deleteMessage: 'Jeste li sigurni da želite izbrisati klasu vozila?',
  },
  expensesHeaders: {
    name: 'Naziv',
    vehicle: 'Vozilo',
    type: 'Vrsta',
    description: 'Opis',
    price: 'Cijena',
    expenseDate: 'Datum',
    actions: 'Radnje',
    driving: 'Nalog',
    exportExcel: 'Izvoz u Excel',
  },
  vehicleExpensesTypes: {
    minorService: 'Manji servis',
    majorService: 'Veći servis',
    fuel: 'Gorivo',
    tires: 'Gume',
    parking: 'Parking',
    other: 'Ostalo',
  },
  expensesPage: {
    newItem: 'Novi unos troškova ',
    editItem: 'Promjena troškova',
    id: 'ID',
    name: 'Naziv',
    vehicle: 'Vozilo',
    costType: 'Vrsta troška',
    price: 'Cijena',
    currency: 'Valuta',
    costTime: 'Vrijeme troška',
    description: 'Opis',
    deleteMessage: 'Jeste li sigurni da želite izbrisati ovaj trošak?',
    costTypes: {
      'Mali servis': 'Manji servis',
      'Veliki servis': 'Veći servis',
      Gorivo: 'Gorivo',
      Gume: 'Gume',
      Parking: 'Parking',
      Ostalo: 'Ostalo',
    },
  },
  vehicleMaintenanceHeaders: {
    name: 'Vozilo',
    details: 'Detalji',
    dateFrom: 'Datum početka',
    dateTo: 'Datum završetka',
    actions: 'Radnje',
  },
  vehicleMaintenancePage: {
    newItem: 'Novi unos kvara na vozilu ',
    editItem: 'Promjena kvara na vozilu',
    id: 'ID',
    vehicle: 'Vozilo',
    description: 'Opis',
    dateFrom: 'Vrijeme početka',
    dateTo: 'Vrijeme završetka',
    deleteMessage: 'Jeste li sigurni da želite izbrisati ovaj kvar na vozilu?',
  },
  rebatesHeaders: {
    min: 'Minimalan broj narudžbi vožnje',
    max: 'Maksimalan broj narudžbi vožnje',
    percentage: 'Popust (%)',
    actions: 'Radnje',
  },
  rebatesPage: {
    newItem: 'Novi unos popusta',
    editItem: 'Promjena popusta',
    rebateMin: 'Minimalan broj narudžbi vožnje',
    rebateMax: 'Maksimalan broj narudžbi vožnje',
    rebate: 'Popust (%)',
    deleteRebate: 'Izbriši rabat',
    deleteMessage: 'Jeste li sigurni da želite izbrisati ovaj popust?',
  },
  customerCardsHeaders: {
    cardholderName: 'Ime vlasnika kartice',
    cardNumber: 'Broj kartice',
    expiryDate: 'Datum isteka',
    cvv: 'CVV',
    actions: 'Akcije',
  },
  customerCardsPage: {
    newItem: 'Novi unos kartice',
    editItem: 'Izmjena kartice',
    cardholderName: 'Ime vlasnika kartice',
    cardNumber: 'Broj kartice',
    expiryDate: 'Datum isteka',
    cvv: 'CVV',
    deleteCard: 'Obriši karticu',
    deleteMessage: 'Jeste li sigurni da želite obrisati ovu karticu?',
  },
  pricingRegionsHeaders: {
    name: 'Ime',
    actions: 'Radnje',
  },
  pricingRegionsPage: {
    newItem: 'Nova cjenovna regije',
    editItem: 'Uređivanje cjenovne regije',
    name: 'Ime',
    chooseCurrency: 'Odaberi valutu',
    team10Price: 'Cijena tima 10',
    team10PaddlePriceId: 'ID cijene Paddle tima 10',
    team25Price: 'Cijena tima 25',
    team25PaddlePriceId: 'ID cijene Paddle tima 25',
    team50Price: 'Cijena tima 50',
    team50PaddlePriceId: 'ID cijene Paddle tima 50',
    chooseCountries: 'Odaberi zemlje',
    description: 'Opis',
    deletePricingRegion: 'Izbriši cjenovnu regiju',
    deleteMessage: 'Jeste li sigurni da želite izbrisati ovu cjenovnu regiju?',
  },
  notificationsHeaders: {
    time: 'Vrijeme',
    notification: 'Obavijest',
  },
  organisationsHeaders: {
    name: 'Organizacija',
    phoneNumber: 'Broj telefona',
    email: 'Email',
    address: 'Adresa',
    companyId: 'OIB',
    vat: 'PDV',
    active: 'Aktivan',
    createdAt: 'Registriran',
    actions: 'Radnje',
    billingDate: 'Datum naplate',
  },
  organisationsPage: {
    newItem: 'Novi unos organizacije',
    editItem: 'Uredi organizaciju',
    id: 'ID',
    name: 'Organizacija',
    address: 'Adresa',
    phoneNumber: 'Broj telefona',
    email: 'Email',
    vat: 'PDV',
    companyId: 'OIB',
    warrantNumber: 'Broj rješenja',
    image: 'Slika',
    signature: 'Potpis',
    seal: 'Pečat',
    deactivateMessage: 'Jeste li sigurni da želite ',
    deactivate: 'DEAKTIVIRATI',
    activate: 'AKTIVIRATI',
    organisation: 'organizaciju',
    deleteOrganisation: 'Izbriši organizaciju',
    cancelSubscrMessage:
      'Jeste li sigurni da želite otkazati pretplatu? Imajte na umu da potvrdom ove radnje: 1. Račun vaše organizacije i svi povezani podaci bit će trajno izbrisani. Ova radnja se ne može poništiti. 2. Bit ćete automatski odjavljeni iz sustava odmah nakon otkazivanja. 3. Više nećete imati pristup za prijavu ili dohvat bilo kojih podataka povezanih s vašom organizacijom u budućnosti. Preporučujemo da pregledate sve potrebne informacije ili izvezete ključne podatke prije nastavka s ovom nepovratnom radnjom. Ako imate pitanja ili trebate pomoć, ne oklijevajte kontaktirati naš tim za podršku. Želite li nastaviti s otkazivanjem?',
    deleteMessage: 'Jeste li sigurni da želite izbrisati ovu organizaciju?',
    allDrivings: 'Ukupan broj narudžbi vožnje',
    allDrivingsAccepted: 'Ukupno prihvaćenih narudžbi vožnje',
    allDrivingsCanceled: 'Ukupno odbijenih narudžbi vožnje',
    allVehicles: 'Ukupan broj vozila',
    allUsers: 'Ukupan broj korisnika',
    areYouSure: 'Jeste li sigurni?',
    makeRoutesAvailable: 'Omogući javni pristup rutama',
    percentage: 'Postotak',
    deleteImageMessage: 'Jeste li sigurni da želite izbrisati ovu sliku?',
    deleteImageTitle: 'Brisanje slike',
    cancelSubscriptionTitle: 'Otkazivanje pretplate',
    subscriptionType: 'Vrsta pretplate',
    billingDate: 'Datum naplate',
    articlesOfLaw: 'Članci zakona',
    country: 'Zemlja',
    referent: 'Referent',
    language: 'Jezik',
    gnetId: 'GNET ID',
  },
  transactionsHeaders: {
    invoiceNumber: 'Broj računa',
    status: 'Status',
    total: 'Ukupno',
    currency: 'Valuta',
    billedAt: 'Datum izdavanja računa',
    actions: 'Radnje',
  },
  transactionsPage: {
    newItem: 'Nova transakcija',
    editItem: 'Uredi transakciju',
    id: 'ID',
    invoiceNumber: 'Broj računa',
    status: 'Status',
    sent: 'Poslano',
    completed: 'Dovršeno',
    total: 'Ukupno',
    currency: 'Valuta',
    billedAt: 'Datum izdavanja računa',
    actions: 'Radnje',
    deleteTransaction: 'Izbriši transakciju',
    deleteMessage: 'Jeste li sigurni da želite izbrisati ovu transakciju?',
    currentSubscription: 'Trenutni paket',
    nextBillingDate: 'Sljedeći datum naplate',
    numberOfSeatsTaken: 'Broj dodatih članova tima',
    numberOfSeatsAvailable: 'Broj dostupnih članova tima',
  },
  currenciesHeaders: {
    name: 'Ime',
    code: 'Kod',
    symbol: 'Simbol',
    active: 'Aktivan',
    default: 'Osnovna',
    actions: 'Radnje',
  },
  currenciesPage: {
    newItem: 'Novi unos valute ',
    editItem: 'Promjena valute',
    id: 'ID',
    currency: 'Valuta',
    code: 'Kod',
    symbol: 'Simbol',
    deleteMessage: 'Jeste li sigurni da želite izbrisati ovu valutu?',
  },
  paymentMethodsHeaders: {
    name: 'Ime',
    active: 'Aktivan',
    default: 'Osnovna',
    priceVisible: 'Cijena vidljiva',
    actions: 'Radnje',
  },
  paymentMethodsPage: {
    newItem: 'Novi unos načina plaćanja',
    editItem: 'Promjena načina plaćanja',
    id: 'ID',
    paymentMethod: 'Način plaćanja',
    deleteMessage: 'Jeste li sigurni da želite izbrisati ovaj način plaćanja?',
  },
  feedbacksHeaders: {
    subject: 'Naslov',
    organisation: 'Organizacija',
    user: 'Korisnik',
    actions: 'Radnje',
  },
  passengersHeaders: {
    firstName: 'Ime',
    lastName: 'Prezime',
    phoneNumber: 'Broj telefona',
    email: 'Email',
    passport: 'Putovnica',
    nationality: 'Nacionalnost',
    actions: 'Radnje',
  },
  checkpointsHeaders: {
    checkpointLocation: 'Lokacija putne točke',
    arrivalTime: 'Vrijeme dolaska',
    actions: 'Radnje',
  },
  tables: {
    noDataText: 'Nema podataka',
    search: 'Pretraga',
    drivings: 'Narudžbe vožnje',
    drivingsByPage: 'Narudžbe vožnje po stranici',
    vehicles: 'Vozila',
    vehiclesByPage: 'Vozila po stranici',
    vehiclesClasses: 'Klase vozila',
    vehiclesClassesByPage: 'Klase vozila po stranici',
    expenses: 'Troškovi',
    expensesByPage: 'Troškova po stranici',
    vehiclesMaintenance: 'Kvarovi vozila',
    vehiclesMaintenanceByPage: 'Kvarovi vozila po stranici',
    reportsByPage: 'Izvješća po stranici',
    currencies: 'Valute',
    currencieseByPage: 'Valute po stranici',
    payments: 'Načini plaćanja',
    paymentsByPage: 'Načini plaćanja po stranici',
    saasAdministrators: 'Svi administratori',
    users: 'Svi korisnici',
    usersByPage: 'Korisnici po stranici',
    clients: 'Svi klijenti',
    clientsByPage: 'Klijenti po stranici',
    organisations: 'Sve organizacije',
    organisationsByPage: 'Organizacije po stranici',
    rebates: 'Popusti',
    rebatesByPage: 'Popusti po stranici',
    cards: 'Kartice',
    cardsByPage: 'Kartice po stranici',
    transactions: 'Transakcije',
    transactionsByPage: 'Transakcije po stranici',
    pricingRegions: 'Cijene područja',
    pricingRegionsByPage: 'Cijene područja po stranici',
    feedback: 'Povratne informacije',
    feedbacksByPage: 'Povratne informacije po stranici',
    notifications: 'Obavijesti',
    notificationsByPage: 'Obavijesti po stranici',
    all: 'Sve',
    passengers: 'Putnici',
    passengersByPage: 'Putnici po stranici',
    of: 'od',
    checkpoints: 'Putne točke',
    checkpointsByPage: 'Putne točke po stranici',
    invoices: 'Računi',
    invoicesByPage: 'Računi po stranici',
    drivingOffers: 'Ponude za vožnju',
    drivingOffersByPage: 'Ponude za vožnju po stranici',
    smsMessages: 'SMS poruke',
    smsMessagesByPage: 'SMS poruke po stranici',
    resourcesUsed: 'Iskorišteni resursi',
    resourcesUsedByPage: 'Resursi po stranici',
    inNext3Days: 'U sljedeća 3 dana',
    notConfirmedOrPaid: 'Nije potvrđeno ili plaćeno',
    inNext5Days: 'U sljedećih 5 dana',
    latestReviews: 'Najnovije recenzije',
    driversByPage: 'Vozači po stranici',
    reviewsByPage: 'Recenzije po stranici',
  },
  statistics: {
    numberOfAcceptedDrivings: 'Broj prihvaćenih vožnji',
    numberOfRejectedDrivings: 'Broj odbijenih vožnji po vozaču',
    vehicleDrivingsNumber: 'Broj vožnji po vozilu',
    vehicleProfit: 'Zarada po vozilu',
    monthDrivingsNumber: 'Broj vožnji po mjesecima',
    driverProfit: 'Zarada po vozaču',
  },
  reportsHeaders: {
    vehicle: 'Vozilo',
    expenses: 'Troškovi',
    profit: 'Profit',
    earnings: 'Zarada',
    daysWorking: 'Radni dani',
    usageIndex: 'Indeks korištenja',
    client: 'Klijent',
    numberOfDrivings: 'Broj Vožnji',
    percentForClient: 'Cijena za Klijenta',
    sumOfPrice: 'Iznos',
  },
  packages: {
    active: 'Aktivan',
    starterTitle: 'Početnik',
    monthlyPackagesMessage: 'Mjesečna pretplata po korisniku.',
    starterText: 'Idealno za pojedince ili male timove koji trebaju samo osnovne funkcije.',
    starterListItemOne: 'Mobilne aplikacije',
    starterListItemTwo: 'Obavijesti putem pusha',
    starterListItemThree: 'Pristup administraciji',
    starterListItemFour: 'Integracija na web stranicu',
    standardTitle: 'Standard',
    standardText: 'Savršeno za organizacije s više od 5 vozača.',
    standardListItemOne: 'PAKET POČETNIK uključen',
    standardListItemTwo: 'Nema ograničenja broja vozača',
    standardListItemThree: 'Dostupnost 99,9%',
    standardListItemFour: 'Izvješća i upravljanje vozilima',
    premiumTitle: 'Premium',
    premiumText: 'Idealno za organizacije limuzinskih usluga koje djeluju na temelju preciznih podataka.',
    premiumListItemOne: 'PAKET STANDARD uključen',
    premiumListItemTwo: 'BI i Prognoza troškova',
    premiumListItemThree: 'Izvoz podataka',
    premiumListItemFour: 'Tjedna i mjesečna izvješća',
    warningOne: '* Prikazane cijene su mjesečna pretplata po korisniku',
    warningTwo: '* Nakon prelaska na novi paket, nije moguće prebaciti se na manji paket tijekom sljedećih 6 mjeseci',
    warningThree: '* Korisnici demo plana imaju uvid u funkcionalnosti koje pruža premium paket',
    warningOneAnnualPackage: 'Trebate više? Imate veću organizaciju? Imamo za vas PAKET PODUZEĆA.',
    warningTwoAnnualPackage: 'i naš tim za prodaju će vas uskoro kontaktirati.',
    packageChange: 'Promjena paketa',
    changePackage: 'Jeste li sigurni da želite prebaciti se na',
    package: 'paketa',
    annualPricing: 'Godišnje cijene',
    annualPackageType10: 'Tim 10',
    annualPackageType25: 'Tim 25',
    annualPackageType50: 'Tim 50',
    'Team 10': 'Tim 10',
    'Team 25': 'Tim 25',
    'Team 50': 'Tim 50',
    annualPackagesMessage: 'Godišnja pretplata po organizaciji.',
    annualPackageType10MainMessage: 'Do 10 korisničkih naloga.',
    annualPackageType25MainMessage: 'Do 25 korisničkih naloga.',
    annualPackageType50MainMessage: 'Do 50 korisničkih naloga.',
    annualPackageListItemOne: 'Paket Standard',
    annualPackageListItemTwo: 'Jednokratno plaćanje',
    annualPackageListItemThree: 'Povoljne cijene',
    annualPackageType10Discount: '- do 65% popusta',
    annualPackageType25Discount: '- do 68% popusta',
    annualPackageType50Discount: '- do 65% popusta',
    allFeaturesIncluded: 'Sve značajke uključene',
    trialPeriodIncluded: 'Probni period uključen',
    unlimitedVehicles: 'Neograničena vozila',
    unlimitedBookings: 'Neograničene rezervacije',
    onetimePaymentAnnually: 'Jednokratno godišnje plaćanje',
  },
  successPaymentPage: {
    message: 'Plaćanje uspješno. Hvala vam.',
  },
  demoExpiring: {
    notification: 'Obavijest',
    expiredSubscriptionMessage:
      'Obavještavamo vas da je vaša pretplata na ovoj platformi istekla. Kako biste nastavili koristiti naše usluge, molimo vas da obnovite svoju pretplatu.',
  },
  login: {
    username: 'Korisničko ime',
    password: 'Lozinka',
    rememberMe: 'Zapamti me',
    login: 'Prijava',
    noAccount: 'Još nemate nalog? Možete se registrirati',
    forgetPassword: 'Zaboravili ste lozinku? Možete je resetirati',
    registerHere: 'ovdje',
    email: 'Email',
    enterEmail: 'Molimo unesite svoj email',
    enterPassword: 'Molimo unesite svoju novu lozinku',
    signInWithGoogle: 'Prijavite se putem Googlea',
  },
  registration: {
    register: 'Registracija',
    name: 'Naziv organizacije',
    address: 'Adresa',
    phoneNumber: 'Broj telefona',
    email: 'E-mail',
    companyId: 'OIB tvrtke',
    vat: 'PDV',
    language: 'Jezik',
    package: 'Paket',
    accept: 'Prihvaćam',
    terms: 'uvjete korištenja',
    successfullyRegistered: 'Korisnik je uspješno registriran. Primit ćete e-mail s poveznicom za verifikaciju.',
    haveAccount: 'Već imate korisnički nalog? Možete se prijaviti',
    loginHere: 'Ovdje',
    trialPeriodInfo:
      'Uključen je besplatni probni period od 14 dana. Naplata će uslijediti nakon završetka probnog perioda.',
  },
  driving: {
    drivingType: 'Tip naloga',
    general: 'Opće informacije',
    newDriving: 'Zakaži transfer',
    newDailyRent: 'Zakaži dnevni najam',
    areYouSure: 'Jeste li sigurni?',
    vehicleIsUnavailable: 'Vozilo je zauzeto.',
    vehicleIsDamaged: 'Vozilo je oštećeno.',
    sureAboutThisVehicle: 'Jeste li sigurni da želite odabrati ovo vozilo?',
    driverUnavaliable: 'Vozač je zauzet.',
    sureAboutThisDriver: 'Jeste li sigurni da želite odabrati ovog vozača?',
    checkVehicleSeatAvailability: 'Ovo vozilo ne može primiti toliko putnika.',
    cancelDriving: 'Jeste li sigurni da želite otkazati nalog za vožnju?',
    finishDriving: 'Jeste li sigurni da želite završiti nalog za vožnju?',
    noShowDriving: 'Jeste li sigurni da želite označiti narudžbu vožnje kao nepojavljivanje?',
    deleteDriving: 'Jeste li sigurni da želite izbrisati nalog za vožnju?',
    newTransfer: 'Novi transfer',
    driving: 'Narudžba vožnje',
    comments: 'Komentari',
    id: 'ID',
    locationFrom: 'Polazište',
    locationTo: 'Odredište',
    vehicleClass: 'Klasa vozila',
    vehicle: 'Vozilo',
    driver: 'Vozač',
    pickupTime: 'Vrijeme preuzimanja',
    clientConfirmed: 'Klijent potvrdio',
    description: 'Opis',
    extraInfo: 'Dodatne informacije',
    drivingNotAccepted: 'Nisu prihvatili nalog za vožnju:',
    currency: 'Valuta',
    paymentMethod: 'Način plaćanja',
    price: 'Cijena',
    paid: 'Plaćeno',
    distance: 'Udaljenost (km)',
    distanceMi: 'Udaljenost (milja)',
    expectedTime: 'Očekivano trajanje',
    waitingTime: 'Vrijeme čekanja (h)',
    EnterTheNumberOfHoursWaiting: 'Unesite broj sati čekanja',
    dropOffTime: 'Vrijeme dolaska',
    comebackTime: 'Vrijeme povratka',
    acceptUntil: 'Prihvatite do',
    drivingRequired: 'Je li narudžba vožnje obavezna',
    comment: 'Komentar',
    attachments: 'Privici',
    addFiles: '+ Dodajte datoteke',
    deleteFiles: 'Jeste li sigurni da želite izbrisati ovu datoteku?',
    passengers: 'Putnici',
    addPassenger: '+ Dodaj putnika',
    deletePassenger: 'Jeste li sigurni da želite izbrisati ovog putnika?',
    chooseClient: 'Izaberite klijenta',
    costs: 'Troškovi',
    checkpoints: 'Putne točke',
    addCheckpoint: 'Dodaj putnu točku',
    deleteCheckpoint: 'Jeste li sigurni da želite izbrisati ovu putnu točku?',
    waitingBoardText: 'Tekst na čekanju na ploči',
    flightNumber: 'Broj leta',
    flightInfo: 'Informacije o letu',
    createdBy: 'Nalog za vožnju kreiran <b>{time}</b> od <b>{user}</b>',
    client: 'Klijent',
    gnet: 'GNET',
    note: 'Napomena',
    noteDriver: 'Napomena za vozača',
    noteDispatcher: 'Napomena za dispečera',
    passenger: 'Putnik',
    fullName: 'Ime i prezime',
    phoneNumber: 'Broj telefona',
    email: 'Email',
    suitcasesNumber: 'Broj kofera',
    passengersNumber: 'Broj putnika',
    babySeatsNumber: 'Broj dječjih sjedala',
    stopsNumber: 'Broj zaustavljanja',
    steps: {
      mainInfo: 'Osnovne informacije',
      additionalInfo: 'Dodatne informacije',
      review: 'Pregled',
    },
    commissionAmount: 'Provizija',
  },
  drivingStatus: {
    pending: 'Na čekanju',
    accepted: 'Prihvaćeno',
    rejected: 'Odbijeno',
    expired: 'Isteklo',
    canceled: 'Otkazano',
    draft: 'Nacrt',
    done: 'Dovršeno',
    noShow: 'Nepojavljivanje',
  },
  drivingOrderType: {
    dailyRent: 'Dnevni najam',
    transfer: 'Transfer',
  },
  flightInfo: {
    airlineName: 'Naziv zrakoplovne kompanije',
    flightNumber: 'Broj leta',
    departureAirport: 'Zračna luka polaska',
    departureCountry: 'Zemlja polaska',
    departureTime: 'Vrijeme polaska',
    arrivalAirport: 'Zračna luka dolaska',
    arrivalCountry: 'Zemlja dolaska',
    arrivalTime: 'Vrijeme dolaska',
    departureDelayed: 'Kašnjenje polaska',
    flightStatus: 'Status leta',
    percentageOfFlight: 'Postotak leta',
  },
  customValidations: {
    fileType: 'Vrsta datoteke .{extension} nije podržana',
    fileSize: 'Datoteke ne smiju biti veće od {size}',
    fieldRequired: 'Polje {field} je obavezno',
    fieldType: 'Polje {field} mora biti tipa {type}',
    fieldLengthMin: 'Polje {field} mora biti dulje od {length}',
    fieldLengthMax: 'Polje {field} mora biti kraće od {length}',
    fieldUnique: 'Polje {field} mora biti jedinstveno',
    fieldValid: 'Polje {field} mora biti valjano',
    noActiveSubscription: 'Nema aktivne pretplate',
    usersLimitExceeded: 'Dostignuto ograničenje korisnika',
    fieldBefore: '{field1} mora biti prije {field2}',
    flightNotExist: 'Let {field} ne postoji',
    gnetKeyNotValid: 'Gnet ključ koji ste unijeli nije važeći.',
    gnetPassengersRequired: 'Nalog za vožnju ne može biti poslan GNET-u bez putnika.',
    priceAndCurrencyRequired: 'Link za plaćanje ne može biti generiran bez cijene i valute',
    fieldValueNotSupported: 'Vrijednost polja {field} nije podržana',
    noCountryCode: 'Niste odabrali državu',
    noStripeForYourCountry: 'Stripe nije dostupan u vašoj zemlji',
    stripeAlreadyEnabled: 'Stripe je već aktiviran',
    fieldValueMin: 'Polje {field} mora biti {min} ili više',
    fieldValueMax: 'Polje {field} mora biti {max} ili manje',
  },
  fieldTypes: {
    string: 'tekst',
    integer: 'broj',
    date: 'datum',
  },
  names: {
    username: 'korisničko ime',
    password: 'lozinka',
    choose_client: 'izaberite klijenta',
    choose_month: 'izaberite mjesec',
    currency: 'valuta',
    payment_method: 'način plaćanja',
    vehicle_class: 'klasa vozila',
    first_name: 'ime',
    last_name: 'prezime',
    passport: 'putovnica',
    nationality: 'nacionalnost',
    plate_number: 'registracija',
    brand: 'marka automobila',
    number_of_passengers: 'broj putnika',
    fuel_consumption: 'potrošnja goriva',
    year_of_manufacture: 'godina proizvodnje',
    price_per_km: 'cijena po kilometru',
    price_per_mi: 'cijena po milji',
    price_per_hour: 'cijena po satu',
    price_per_waiting_hour: 'cijena po čekanju po satu',
    vehicle: 'vozilo',
    description: 'opis',
    time_from: 'vrijeme od',
    time_to: 'vrijeme do',
    name: 'ime',
    cost_type: 'tip troška',
    cost_time: 'vrijeme troška',
    price: 'cijena',
    rebate_min: 'minimalan broj narudžbi',
    rebate_max: 'maksimalan broj narudžbi',
    rebate: 'popust',
    phone_number: 'broj telefona',
    organisation: 'organizacija',
    role: 'uloga',
    image: 'slika',
    location_from: 'polazište',
    location_to: 'odredište',
    pickup_time: 'vrijeme preuzimanja',
    distance: 'udaljenost',
    waiting_time: 'vrijeme čekanja',
    comment: 'komentar',
    address: 'adresa',
    vat: 'PDV',
    company_id: 'ID tvrtke',
    company_personal_id: 'id tvrtke/osobna iskaznica',
    licence: 'licence',
    warrant_number: 'broj dozvole',
    seal: 'pečat',
    signature: 'potpis',
    email: 'email',
    percentage: 'postotak',
    organizationName: 'ime organizacije',
    package: 'paket',
    choose_currency: 'odaberi valutu',
    team_10_price: 'cijena za tim 10',
    team_10_paddle_price_id: 'ID cijene za tim 10 Paddle',
    team_25_price: 'cijena za tim 25',
    team_25_paddle_price_id: 'ID cijene za tim 25 Paddle',
    team_50_price: 'cijena za tim 50',
    team_50_paddle_price_id: 'ID cijene za tim 50 Paddle',
    countries: 'zemlje',
    invoice_number: 'broj računa',
    status: 'status',
    total: 'ukupno',
    billed_at: 'datum izdavanja računa',
    articles_of_law: 'članci zakona',
    country: 'zemlja',
    referent: 'referent',
    language: 'jezik',
    location: 'lokacija',
    arrival_time: 'vrijeme dolaska',
    waiting_board_text: 'tekst na čekanju na ploči',
    flight_number: 'broj leta',
    gnet_id: 'GNET ID',
    sendViaGnet: 'Pošalji putem GNET-a',
    receiver_id: 'ID primatelja',
    preferred_vehicle_type: 'preferirani tip vozila',
    reservation_type: 'tip rezervacije',
    trip_duration_minutes: 'trajanje putovanja',
    client: 'klijent',
    client_type: 'vrsta klijenta',
    card_holder_name: 'ime vlasnika kartice',
    card_number: 'broj kartice',
    expiry_date: 'datum isteka',
    cvv: 'cvv',
    code: 'kod',
    symbol: 'simbol',
    quantity: 'količina',
    discount: 'popust',
    driving_type: 'tip vožnje',
    full_name: 'puno ime',
    suitcases_number: 'broj kofera',
    passengers_number: 'broj putnika',
    baby_seats_number: 'broj dječjih sjedalica',
    stops_number: 'broj zaustavljanja',
    commission_amount: 'provizija',
  },
  welcomeModal: {
    welcome: 'Dobrodošli u LimoExpress',
  },
  weekDays: {
    monday: 'Ponedjeljak',
    tuesday: 'Utorak',
    wednesday: 'Srijeda',
    thursday: 'Četvrtak',
    friday: 'Petak',
    saturday: 'Subota',
    sunday: 'Nedjelja',
  },
  select: {
    noDataAvailable: 'Nema dostupnih podataka',
  },
  excelExport: {
    to_date_range: 'Izvješće ne može biti generirano za buduće datume. Molimo unesite valjane datume',
    overlap_range: 'Vrijeme završetka ne može biti veće od vremena početka.',
  },
  setPassword: {
    success: 'Email poslan',
    checkEmail: 'Provjerite svoj email za daljnje upute.',
    already_changed: 'Već promijenjeno',
    send_new_reset_request: 'Već ste promijenili lozinku s ovim linkom. Pošaljite novi zahtjev za resetiranje lozinke.',
  },
  statisticsPage: {
    reportType: 'Tip izvješća',
    reportTime: 'Vrijeme izvješća',
    vehicleUtilization: 'Iskorištenje vozila',
    costEffectiveness: 'Isplativost troškova',
    generalReport: 'Opće izvješće',
    clientsReport: 'Izvještaj po Klijentima',
  },
  profile: {
    yourProfile: 'Vaš profil',
    name: 'Ime',
    role: 'Uloga',
    username: 'Korisničko ime',
    password: 'Lozinka',
    phoneNumber: 'Broj telefona',
    email: 'Email',
    percentage: 'Postotak',
  },
  gpsMaps: {
    noTransfers: 'Nema tekućih ili nadolazećih transfera.',
    map: 'Karta',
    transfers: 'Prijenosi',
    drivers: 'Vozači',
    ongoing: 'U tijeku',
    upcoming: 'Nadolazeći',
    driving: 'Vožnja',
    driver: 'Vozač',
    vehicle: 'Vozilo',
    requestDriverLocation: 'Zatraži lokaciju vozača',
    lastRecordedLocation: 'Zadnja zabilježena lokacija',
  },
  driverStatus: {
    available: 'Dostupan',
    offline: 'Van mreže',
    inTransfer: 'U prijenosu',
  },
  onboardingTutorialMain: {
    languageStep: 'Ovdje možete postaviti željeni jezik za aplikaciju.',
    notificationsStep: 'Ovo su vaša obavijest.',
    addTransferStep:
      'Pritisnite ovu tipku kako biste stvorili svoje transfere i dnevne najmove. Dnevni najmovi su prilagođeni transferi bez odredišta.',
    sideMenuButtonStep: 'Kliknite ovdje da biste prikazali ili sakrili izbornik.',
    sideMenuExpStep:
      'Odavde možete navigirati do glavnih sekcija aplikacije: Transfere, Flota, Troškovi, Administracija, Statistika i Transakcije.',
    sideMenuButtonCloseStep: 'Kliknite ponovno da biste sakrili izbornik.',
    supportStep:
      'Ako vam je potrebna pomoć s bilo kojim problemom, ne ustručavajte se kontaktirati podršku klikom ovdje.',
  },
  onboardingTutorialVehicles: {
    tableStep: 'Vaša vozila bit će navedena ovdje u tablici, nakon što ih dodate.',
    searchStep: 'Vozila možete lakše pronaći izravnom pretragom.',
    addStep:
      "Dodajte nova vozila pomoću gumba 'Dodaj'. Imajte na umu da morate dodati 'Klase vozila' prije korištenja ove značajke.",
  },
  onboardingTutorialStatistics: {
    typeStep:
      'U ovoj sekciji možete pronaći sve vaše statističke podatke prikazane u grafikonima. Odaberite vrstu statistike ovdje.',
    intervalStep: 'Dodajte interval za koji želite vidjeti statistiku.',
    downloadStep: 'Također možete preuzeti i ispisati ove tablice.',
  },
  onboardingTutorialGps: {
    tabOneStep:
      'U GPS modulu možete pratiti svoje vozače i transfere na istom mjestu. Odaberite ovu karticu da biste vidjeli trenutne i buduće transfere.',
    tabTwoStep: 'Odaberite vozila da biste ih vidjeli na karti. Slobodna vozila možete direktno dodati u transfere.',
    mapStep: 'Odaberite ili odznačite vozila klikom na njihovu iglu na karti.',
  },
  advancedSettings: {
    advancedSettings: 'Napredne postavke',
    distanceUnit: 'Jedinica za udaljenost',
    distanceUnitDescription: 'Odaberite jedinicu za mjerenje udaljenosti.',
    kilometers: 'Kilometri',
    miles: 'Milje',
    dateFormat: 'Format datuma',
    dateFormatDescription: 'Odaberite format datuma koji želite koristiti.',
    ddmmyyyy: 'DD-MM-YYYY',
    mmddyyyy: 'MM-DD-YYYY',
    mandatoryDrivings: 'Obavezne vožnje',
    mandatoryDrivingsDescription: 'Odaberite ako želite da sve vožnje budu obavezne prema zadanim postavkama.',
    mandatoryDrivingsDefault: 'Obavezne Vožnje (Zadano)',
    paidDrivings: 'Plaćene vožnje',
    paidDrivingsDescription: 'Označite kako bi sve vožnje bile plaćene prema zadanim postavkama.',
    paidDrivingsDefault: 'Plaćene vožnje (Zadano)',
    notificationsEmail: 'Email za obavijesti',
    notificationsEmailDescription:
      'Promijenite zadanu email adresu za primanje obavijesti (ako ne želite koristiti email adresu svoje organizacije).',
    email: 'Email',
    articlesOfLaw: 'Članci zakona',
    integrations: 'Integracije',
    gnetId: 'GNET ID',
    gnetDescription:
      'GNET je sveobuhvatna platforma osmišljena za optimizaciju praćenja u stvarnom vremenu, upravljanja flotom i dispečerskih operacija za transportnu industriju.',
    makeRoutesAvailable: 'Omogući javni pristup rutama',
    makeRoutesAvailableDescription:
      'Aktivirajte svoj API ključ da integrirate LimoExpress s vašom web stranicom, ERP sustavom i još mnogo toga.',
    makePublicLinkAvailable: 'Omogućite javnu poveznicu',
    makePublicLinkAvailableDescription:
      'Aktivirajte poveznicu za pristup javnoj stranici za rezervacije kako bi vaši klijenti mogli pristupiti naprednom obrascu za rezervacije i lako stvarati račune. Poveznicu možete koristiti u newsletterima, na web stranici, flajerima; to je jedinstvena poveznica samo za vašu tvrtku.',
    emailsEnabled: 'Omogućeni emailovi',
    emailsEnabledDefault: 'Omogućeni emailovi',
    emailsEnabledDescription: 'Označite da biste primali obavijesti putem email-a.',
    passengerReviewsEnabled: 'Omogućene ocjene putnika',
    passengerReviewsEnabledDefault: 'Omogućene ocjene putnika',
    passengerReviewsEnabledDescription:
      'Aktivacijom ove funkcionalnosti vaši putnici (ako je unesen email) dobit će email potvrdu nakon završene vožnje (označene kao Završeno) i imat će priliku dati ocjene (feedback) za vaše usluge.',
    vat: 'PDV',
    vatPercentage: 'Postotak PDV-a',
    vatPercentageDescription: 'Unesite postotak PDV-a.',
    invoicePaymentInstructions: 'Upute za plaćanje fakture',
    stripe: 'Stripe',
    stripeDescription:
      'Aktiviraj Stripe kako bi mogao naplaćivati svoje usluge klijentima. LimoExpress ne uzima proviziju.',
  },
  drivingExcelCheckboxes: {
    drivingNumber: 'Broj naloga',
    drivingType: 'Tip naloga',
    drivingStatus: 'Status',
    locationFrom: 'Polazište',
    locationTo: 'Odredište',
    driver: 'Vozač',
    client: 'Klijent',
    plateNumber: 'Registarska oznaka',
    note: 'Opis',
    fuelConsumption: 'Potrošnja goriva',
    pickupTime: 'Vrijeme polaska',
    numberOfPassengers: 'Broj putnika',
    passengers: 'Putnici',
    price: 'Cijena',
    paid: 'Plaćeno',
    paymentMethod: 'Način plaćanja',
    waitingTime: 'Vrijeme čekanja (h)',
    distance: 'Razdaljina (km)',
    distanceMi: 'Udaljenost (milja)',
    selectAll: 'Odaberi sve',
  },
  gnet: {
    gnetInfo: 'GNET informacije',
    requesterId: 'ID podnosioca zahtjeva',
    receiverId: 'ID primatelja',
    preferredVehicleType: 'Preporučeni tip vozila',
    reservationType: 'Tip rezervacije',
    runType: 'Tip vožnje',
    price: 'Cijena',
    currency: 'Valuta',
    noteDriver: 'Napomena za vozača',
    noteDispatcher: 'Napomena za dispečera',
    acceptDriving: 'Jeste li sigurni da želite prihvatiti vožnju?',
    rejectDriving: 'Jeste li sigurni da želite odbiti vožnju?',
    tripDurationMinutes: 'Trajanje putovanja (minuti)',
  },
  invoicesHeaders: {
    invoiceNumber: 'Broj računa',
    client: 'Klijent',
    totalPrice: 'Ukupna cijena',
    drivings: 'Vožnje',
    paid: 'Plaćeno',
    currency: 'Valuta',
    createdAt: 'Kreirano',
    actions: 'Akcije',
  },
  invoicesPage: {
    newItem: 'Novi unos računa',
    chooseClient: 'Odaberite klijenta',
    chooseDrivings: 'Odaberite vožnje',
    deleteMessage: 'Jeste li sigurni da želite izbrisati ovaj račun?',
  },
  drivingOffersHeaders: {
    number: 'Broj ponude vožnje',
    totalPrice: 'Ukupna cijena',
    description: 'Opis',
    paid: 'Plaćeno',
    currency: 'Valuta',
    createdAt: 'Kreirano',
    actions: 'Akcije',
  },
  drivingOffersPage: {
    newItem: 'Novi unos ponude vožnje',
    item: 'Stavka',
    description: 'Opis',
    title: 'Naslov',
    vehicleClass: 'Klasa vozila',
    quantity: 'Količina',
    price: 'Cijena',
    discount: 'Popust',
    vat: 'PDV',
    deleteMessage: 'Jeste li sigurni da želite obrisati ovu ponudu vožnje?',
  },
  modal: {
    reservationSuccessTitle: 'Uspjeh',
    reservationSuccessMessage: 'Zahtjev za rezervaciju je uspješno poslan. Netko će vas uskoro kontaktirati.',
  },
  dashboardPage: {
    drivingCountToday: 'Broj vožnji (danas)',
    drivingCountThisMonth: 'Broj vožnji (zadnjih 30 dana)',
    dailyRentsAndTransfers: 'Broj vožnji (dnevni najam/transferi)',
    drivingTotalAmount: 'Ukupan iznos',
    driversCount: 'Aktivni vozači (±7 dana)',
    passengerCount: 'Broj putnika (zadnjih 30 dana)',
  },
  reviewsHeaders: {
    rating: 'Ocjena',
    comment: 'Komentar',
  },
  resourceOverviewHeaders: {
    actionType: 'Vrsta akcije',
    user: 'Korisnik',
    action: 'Akcija',
    createdAt: 'Kreirano',
  },
  resourceOverviewPage: {
    smsMessagesLeft: 'Broj preostalih SMS poruka',
    flightsCheckLeft: 'Broj preostalih provjera letova',
    sms: 'SMS',
    flightCheck: 'Provjera leta',
  },
};
