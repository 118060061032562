export default {
  sidebar: {
    fleet: 'Flota',
    vehicles: 'Vozila',
    vehiclesClasses: 'Razredi vozil',
    administration: 'Uprava',
    currencies: 'Valute',
    currenciesAdministration: 'Uprava valut',
    paymentTypes: 'Načini plačila',
    paymentTypesAdministration: 'Uprava načinov plačila',
    users: 'Uporabniki',
    usersAdministration: 'Uprava uporabnikov',
    clients: 'Stranke',
    clientsAdministration: 'Uprava strank',
    organizations: 'Organizacije',
    organizationsAdministration: 'Uprava organizacij',
    saasAdmin: 'Saas admin',
    administrators: 'Administratorji',
    saasAdminAdministration: 'Uprava Saas admin',
    statistics: 'Statistika',
    generalOverview: 'Splošni pregled',
    payment: 'Plačilo',
    rebates: 'Popusti',
    pricingRegions: 'Cenovne regije',
    pricingRegionsAdministration: 'Upravljanje cenovnim regijama',
    help: 'Pomoč',
    drivings: 'Vožnje',
    calendar: 'Koledar',
    tabularView: 'Tabelarni pogled',
    expenses: 'Stroški',
    vehicleExpensesAdministration: 'Uprava stroškov vozil',
    vehicleMaintenance: 'Okvare vozila',
    vehicleMaintenanceAdministration: 'Uprava okvar vozila',
    organization: 'Organizacija',
    organizationAdminstration: 'Uprava organizacije',
    packages: 'Paketi',
    packagesChange: 'Sprememba paketov',
    logout: 'Odjava',
    logoutText: 'Ste prepričani, da želite oditi iz sistema?',
    unaccessibleRoutes: 'Do te strani nimate dostopa. Nadgradite paket za poln dostop.',
    transactions: 'Transakcije',
    gpsMap: 'GPS zemljevid',
    team: 'Ekipa',
    teamAdministration: 'Upravljanje ekipe',
    invoices: 'Računi',
    invoicesAdministration: 'Upravljanje računov',
    drivingOffers: 'Ponudbe',
    drivingOffersAdministration: 'Upravljanje ponudb',
    dashboard: 'Domača stran',
    dashboardAdministration: 'Upravljanje domače strani',
    resourceOverview: 'Pregled virov',
    resourceOverviewAdministration: 'Upravljanje pregledom virov',
    rides: 'Voznje',
  },
  header: {
    organization: 'Organizacija',
    newDriving: 'Vožnja',
    dailyRent: 'Dnevna najemnina',
    transfer: 'Prenos',
    notifications: 'Obvestila',
    changeLanguage: 'Spremeni jezik',
  },
  footer: {
    poweredBy: 'Omogoča',
    subscriptionTo: 'Vaša naročnina na',
    trialEndsAt: 'Vaše preskusno obdobje za',
    packageExpiresIn: 'paketa poteče čez',
    clickToChangePackage: 'Kliknite za spremembo paketa',
    clickToActivatePackage: 'Kliknite za aktivacijo paketa',
    clickToPay: 'Kliknite za plačilo',
    packageHasExpired: 'paketa je potekel',
  },
  buttons: {
    ok: 'V redu',
    yes: 'Da',
    no: 'Ne',
    save: 'Shrani',
    send: 'Pošlji',
    sendMessage: 'Pošlji sporočilo',
    edit: 'Uredi',
    add: 'Dodaj',
    confirm: 'Potrdi',
    cancel: 'Prekliči',
    cancelDriving: 'Prekliči vožnjo',
    markAsDone: 'Označi kot opravljeno',
    markAsNoShow: 'Označi kot neudeležbo',
    deleteDriving: 'izbriši naloga',
    newOrganisation: 'Nova organizacija',
    changePackage: 'Sprememba paketa',
    downloadStats: 'Prenesi statistiko',
    downloadPDF: 'Prenesi PDF',
    today: 'Danes',
    choose: 'Izberi',
    login: 'Prijava',
    generateFile: 'Generiraj pogodbo',
    generateDrivingOrder: 'Natisni vožnjo',
    downloadFile: 'Prenesi datoteko',
    activate: 'Aktiviraj',
    deactivate: 'Onemogoči',
    showImage: 'Pokaži sliko',
    showDetails: 'Pokaži podrobnosti',
    actions: 'Dejanja',
    delete: 'Izbriši',
    open: 'Odpri',
    show: 'Pokaži',
    options: 'Možnosti',
    prev: 'Prejšnji',
    next: 'Naslednji',
    skip: 'Preskoči',
    finish: 'Končaj',
    settings: 'Nastavitve',
    cancelSubscription: 'Prekliči naročnino',
    backToEditOrganization: 'Nazaj na urejanje organizacije',
    payNow: 'Plačaj zdaj',
    back: 'Nazaj',
    integrationRoutes: 'Integracijske poti',
    transactions: 'Transakcije',
    articlesOfLaw: 'Členi zakona',
    export: 'Izvozi',
    duplicate: 'Naredite kopijo',
    makeReturnTrip: 'Naredite povratno potovanje',
    selectOrganisation: 'Izberite organizacijo',
    advancedSettings: 'Napredne nastavitve',
    accept: 'Sprejmi',
    reject: 'Zavrni',
    sendViaGnet: 'Pošlji prek GNET-a',
    printInvoice: 'Natisni račun',
    enable: 'Omogoči',
    disable: 'Onemogoči',
    generatePaymentLink: 'Ustvari povezavo za plačilo',
    addItem: '+ Dodaj postavko',
  },
  tooltips: {
    save: 'Shrani',
    edit: 'Uredi',
    delete: 'Izbriši',
    showImage: 'Prikaži sliko',
    open: 'Odpri',
    show: 'Pokaži',
    showDetails: 'Pokaži podrobnosti',
    downloadFile: 'Prenesi datoteko',
    generateFile: 'Generiraj pogodbo',
    generateDrivingOrder: 'Natisni vožnjo',
    activate: 'Aktiviraj',
    deactivate: 'Onemogoči',
    cancel: 'Prekliči',
    cancelDriving: 'Prekliči vožnjo',
    cannotMarkAsCanceled: 'Naročila ni mogoče označiti kot preklicano, ker je že bilo sprejeto',
    markAsDone: 'Označi kot opravljeno',
    cannotMarkAsDone: 'Naročila ni mogoče označiti kot opravljeno, ker še ni bilo izvedeno',
    markAsNoShow: 'Označi kot neudeležbo',
    cannotMarkAsNoShow: 'Naročila ni mogoče označiti kot neudeležbo, ker se še ni zgodilo',
    deleteDriving: 'izbriši naloga',
    cannotDelete:
      'Oprostite, ampak iz varnostnih razlogov uporabniki ne morejo biti izbrisani, dokler so trenutno prijavljeni.',
    duplicate: 'Naredite kopijo',
    makeReturnTrip: 'Naredite povratno potovanje',
    refresh: 'Osveži',
    selectOrganisation: 'Izberite organizacijo',
    sendViaGnet: 'Pošlji prek GNET-a',
    receivedViaGnet: 'Prejeto preko GNET-a',
    sentViaGnet: 'Poslano preko GNET-a',
    printInvoice: 'Natisni račun',
    optionsDisabledGnet: 'Zavrniti ali sprejeti morate naročilo, da dostopate do menija možnosti',
    cannotMakeReturnTrip: 'Ne morete narediti povratne vožnje za dnevni najem',
    cannotSendGnetOrderViaGnet: 'Naročila ne morete poslati prek gnet',
    cannotSendViaGnetWithoutGnetId: 'Naročila ne morete poslati prek gnet brez gnet ID-ja',
    cannotPrintInvoiceWithoutClient: 'Računa ne morete natisniti brez izbire stranke',
    cannotMarkAsDoneStatusRejected: 'Naročilo ne more biti označeno kot dokončano, ker je že bilo zavrnjeno',
    cannotMarkAsDoneStatusCanceled: 'Naročilo ne more biti označeno kot dokončano, ker je že bilo preklicano',
    cannotMarkAsDoneStatusDone: 'Naročilo ne more biti označeno kot dokončano, ker je že bilo označeno kot dokončano',
    cannotMarkAsDoneStatusNoShow: 'Naročilo ne more biti označeno kot dokončano, ker je že bilo označeno kot neprihod',
    cannotMarkAsNoShowStatusRejected: 'Naročilo ne more biti označeno kot neprihod, ker je že bilo zavrnjeno',
    cannotMarkAsNoShowStatusCanceled: 'Naročilo ne more biti označeno kot neprihod, ker je že bilo preklicano',
    cannotMarkAsNoShowStatusDone: 'Naročilo ne more biti označeno kot neprihod, ker je že bilo dokončano',
    cannotMarkAsNoShowStatusNoShow: 'Naročilo ne more biti označeno kot neprihod, ker je že bilo označeno kot neprihod',
    cannotMarkAsCanceledGnet: 'Naročilo ne more biti preklicano, ker je bilo prejeto prek gnet',
    cannotMarkAsCanceledStatusRejected: 'Naročilo ne more biti preklicano, ker je že bilo zavrnjeno',
    cannotMarkAsCanceledStatusExpired: 'Naročilo ne more biti preklicano, ker je že poteklo',
    cannotMarkAsCanceledStatusCanceled: 'Naročilo ne more biti preklicano, ker je že bilo preklicano',
    cannotMarkAsCanceledStatusDraft: 'Naročilo ne more biti preklicano, ker je že bilo označeno kot osnutek',
    cannotMarkAsCanceledStatusCompleted: 'Naročilo ne more biti preklicano, ker je že bilo dokončano',
    cannotMarkAsCanceledStatusNoShow: 'Naročilo ne more biti preklicano, ker je že bilo označeno kot neprihod',
    cannotDeleteDrivingSentViaGnet: 'Naročila ne morete izbrisati, ker je bilo poslano prek gnet',
    cannotDeleteDrivingReceivedViaGnet: 'Naročila ne morete izbrisati, ker je bilo prejeto prek gnet in sprejeto',
    generatePaymentLink: 'Ustvari povezavo za plačilo',
    cannotGeneratePaymentLInk: 'Povezave za plačilo ni mogoče ustvariti, ker niste aktivirali Stripe',
  },
  snackbar: {
    close: 'Zapri',
    savedSuccessfully: 'Uspešno shranjeno',
    excelDownloadedSuccessfully: 'Uspešno preneseno',
    deletedSuccessfully: 'Uspešno izbrisano',
    sentSuccessfully: 'Uspešno poslano',
    activeStatusChanged: 'Uspešno spremenjen aktivni status',
    defaultStatusChanged: 'Uspešno spremenjen privzeti status',
    primaryStatusChanged: 'Uspešno spremenjen primarni status',
    anErrorOccured: 'Prišlo je do napake, sporočite podpori',
    loadingCommentsFailed: 'Nalaganje komentarjev ni uspelo',
    drivingCanceled: 'Vožnja uspešno preklicana',
    drivingFinished: 'Vožnja uspešno končana',
    drivingNoShow: 'Naročilo za vožnjo je uspešno označeno kot neudeležba.',
    drivingDeleted: 'vozniški ukaz je uspešno izbrisan',
    drivingAccepted: 'Naročilo za vožnjo je uspešno sprejeto',
    drivingRejected: 'Naročilo za vožnjo je uspešno zavrnjeno',
    planChanged: 'Načrt uspešno spremenjen',
    planChangeFailed: 'Sprememba načrta ni mogoča',
    saveChangesFirst: 'Najprej shranite spremembe, ki ste jih naredili prej',
    makeRoutesPublic: 'Poti so javno dostopne',
    makeRoutesNotPublic: 'Poti niso več javno dostopne',
    makePublicLinkActive: 'Javna povezava je zdaj na voljo',
    makePublicLinkInactive: 'Javna povezava ni več na voljo',
    integrationKeyCopied: 'Ključ integracije kopiran',
    copyToken: 'Ročno kopirajte žeton',
    publicLinkCopied: 'Javna povezava je kopirana',
    copyPublicLink: 'Ročno kopirajte javno povezavo',
    organisationChanged: 'Podjetje uspešno spremenjeno',
    changeFailed: 'Sprememba ni uspela, poskusite znova',
    loginFailed: 'Uporabniško ime ali geslo ni pravilno',
    organisationSuspended: 'Vaša organizacija je suspendirana',
    userEmailDoesNotExists: 'Uporabnik s to elektronsko pošto ne obstaja',
    canceledSubscrSuccessfully: 'Naročnina uspešno preklicana',
    toManyRequests: 'Preveč zahtev. Poskusite znova čez minuto.',
    visibleStatusChanged: 'Vidni status uspešno spremenjen',
    noInternetConnection: 'Ni interneta. Prosimo, preverite vašo internetno povezavo.',
    backOnline: 'Ponovno ste na spletu.',
    driverNotifiedToTurnGpsOn: 'Voznik obveščen, naj vklopi GPS',
    savedSuccessfullyWithGnetError: 'Sinhronizacija z GNET ni uspela, naročilo za vožnjo uspešno shranjeno.',
    logoutFromAnotherDevice: 'Odjavljeni ste bili iz druge naprave.',
    stripeEnabled: 'Stripe uspešno aktiviran',
    stripeDisabled: 'Stripe uspešno deaktiviran',
    finishStripeConnect: 'Prosim, dokončajte nastavitev Stripe',
  },
  filters: {
    filter: 'Filter',
    currentYear: 'Trenutno leto',
    lastYear: 'Lansko leto',
    lastQuarter: 'Zadnje četrtletje',
    lastThreeYears: 'Zadnja tri leta',
    filterByDriver: 'Filtriraj po vozniku',
    filterByVehicle: 'Filtriraj po vozilu',
    filterByClient: 'Filtriraj po strankah',
    day: 'Dan',
    week: 'Teden',
    month: 'Mesec',
    vehicle: 'Vozilo',
    driving: 'Vožnja',
    user: 'Uporabnik',
    fromDate: 'Od datuma',
    toDate: 'Do datuma',
    threeMonths: 'Tri meseci',
    halfYear: 'Pol leta',
    year: 'Leto',
  },
  months: {
    jan: 'Januar',
    feb: 'Februar',
    mar: 'Marec',
    apr: 'April',
    may: 'Maj',
    jun: 'Junij',
    jul: 'Julij',
    aug: 'Avgust',
    sep: 'September',
    oct: 'Oktober',
    nov: 'November',
    dec: 'December',
    January: 'Januar',
    February: 'Februar',
    March: 'Marec',
    April: 'April',
    May: 'Maj',
    June: 'Junij',
    July: 'Julij',
    August: 'Avgust',
    September: 'September',
    October: 'Oktober',
    November: 'November',
    December: 'December',
  },
  usersPageHeaders: {
    firstName: 'Ime',
    lastName: 'Priimek',
    username: 'Uporabniško ime',
    email: 'E-pošta',
    phoneNumber: 'Telefonska številka',
    role: 'Vloga',
    active: 'Aktiven',
    actions: 'Dejanja',
  },
  usersRoles: {
    masterAdmin: 'Glavni administrator',
    manager: 'Vodja',
    driver: 'Voznik',
    operator: 'Operater',
    externalAssociate: 'Zunanji sodelavec',
    sales: 'Prodaja',
    salesLead: 'Vodja prodaje',
    gnet: 'GNET',
    customer: 'Uporabnik portala',
  },
  masterUsersPageHeaders: {
    firstName: 'Ime',
    lastName: 'Priimek',
    username: 'Uporabniško ime',
    email: 'E-pošta',
    phoneNumber: 'Telefonska številka',
    role: 'Vloga',
    organisation: 'Organizacija',
    active: 'Aktiven',
    actions: 'Dejanja',
  },
  usersPage: {
    newItem: 'Nov vnos uporabnika',
    editItem: 'Uredi uporabnika',
    id: 'Id',
    firstName: 'Ime',
    lastName: 'Priimek',
    username: 'Uporabniško ime',
    password: 'Geslo',
    phoneNumber: 'Telefonska številka',
    role: 'Vloga',
    client: 'Stranka',
    email: 'E-pošta',
    organisation: 'Organizacija',
    percentage: 'Odstotek',
    externalAssociate: 'Zunanji sodelavec',
    image: 'Slika',
    deleteUser: 'Izbriši uporabnika',
    deleteMessage: 'Ste prepričani, da želite izbrisati uporabnika ',
    emailChanged: 'Sprememba e-pošte',
    verificationEmailSent:
      'Zahteva za spremembo e-poštnega naslova je bila poslana. Postopek je treba zaključiti in zagotoviti varnost uporabniškega računa s klikom na povezavo za preverjanje, poslano na nov e-poštni naslov. E-poštni naslov se ne bo spremenil, dokler ni uspešno preverjen.',
  },
  clientsPageHeaders: {
    name: 'Ime',
    phoneNumber: 'Telefonska številka',
    address: 'Naslov',
    email: 'E-pošta',
    companyId: 'ID podjetja/Osebna ID',
    clientType: 'Tip stranke',
    percentage: 'Odstotek',
    active: 'Aktiven',
    actions: 'Dejanja',
  },
  clientsPage: {
    newItem: 'Nov vnos stranke',
    editItem: 'Uredi stranko',
    id: 'Id',
    name: 'Ime',
    address: 'Naslov',
    phoneNumber: 'Telefonska številka',
    email: 'E-pošta',
    companyId: 'ID podjetja/Osebna ID',
    clientType: 'Tip stranke',
    individual: 'Fizična oseba',
    business: 'Pravna oseba',
    percentage: 'Odstotek',
    deleteClient: 'Izbriši stranko',
    deleteMessage: 'Ste prepričani, da želite izbrisati stranko ',
    tabs: {
      general: 'Splošno',
      transfers: 'Prenosi',
      users: 'Uporabniki',
      invoices: 'Računi',
      cards: 'Kartice',
    },
  },
  drivingsHeaders: {
    drivingNumber: 'Številka vožnje',
    drivingType: 'Vrsta naročila vožnje',
    drivingStatus: 'Status',
    locationFrom: 'Začetna točka',
    locationTo: 'Cilj',
    vehicleClass: 'Razred vozila',
    vehicle: 'Vozilo',
    driversName: 'Ime voznika',
    startTime: 'Čas prevzema',
    actions: 'Dejanja',
    exportExcel: 'Izvozi Excel',
    price: 'Cena',
    totalPrice: 'Skupna cena',
    confirmed: 'Potrjeno',
    paid: 'Plačano',
    numberOfDrivings: 'Število naročil za vožnjo',
  },
  vehiclesHeaders: {
    plateNumber: 'Registrska številka',
    brand: 'Blagovna znamka vozila',
    vehicleClass: 'Razred vozila',
    passengersNumber: 'Število potnikov',
    fuelConsumption: 'Poraba goriva na 100 km',
    yearOfManufacture: 'Leto izdelave',
    pricePerKm: 'Cena na km',
    pricePerMi: 'Cena na miljo',
    pricePerHour: 'Cena na uro',
    pricePerWaitingHour: 'Cena za čakanje na uro',
    active: 'Aktiven',
    actions: 'Dejanja',
  },
  vehiclesPage: {
    id: 'Id',
    newItem: 'Nov vnos vozila',
    editItem: 'Sprememba vozila',
    vehiclePlates: 'Registrske oznake',
    vehicleBrand: 'Blagovna znamka vozila',
    vehicleClass: 'Razred vozila',
    passengersNumber: 'Število potnikov',
    fuelConsumption: 'Poraba goriva (100 km)',
    fuelConsumptionMi: 'Poraba goriva avtomobila (100 milj)',
    manufactureYear: 'Leto izdelave',
    price: 'Cena na km',
    priceMi: 'Cena na miljo',
    hourPrice: 'Cena na uro',
    image: 'Slika',
    hourWaitingPrice: 'Cena za čakanje na uro',
    deleteMessage: 'Ste prepričani, da želite izbrisati to vozilo?',
    tabs: {
      general: 'Splošno',
      transfers: 'Vožnje',
      costs: 'Stroški',
      damages: 'Škode',
    },
    licence: 'Številka dovoljenja',
  },
  vehicleClassesHeaders: {
    vehicleClass: 'Razred vozila',
    active: 'Aktiven',
    actions: 'Dejanja',
  },
  vehicleClassesPage: {
    newItem: 'Nov vnos razreda vozila',
    editItem: 'Sprememba razreda vozila',
    id: 'Id',
    vehicleClass: 'Razred vozila',
    hourWaitingPrice: 'Cena čakanja na uro',
    deleteMessage: 'Ste prepričani, da želite izbrisati ta razred vozila?',
  },
  expensesHeaders: {
    name: 'Ime',
    vehicle: 'Vozilo',
    type: 'Vrsta',
    description: 'Opis',
    price: 'Cena',
    expenseDate: 'Datum',
    actions: 'Dejanje',
    driving: 'Vožnja',
    exportExcel: 'Izvozi Excel',
  },
  vehicleExpensesTypes: {
    minorService: 'Manjše vzdrževanje',
    majorService: 'Večje vzdrževanje',
    fuel: 'Gorivo',
    tires: 'Gume',
    parking: 'Parkiranje',
    other: 'Drugo',
  },
  expensesPage: {
    newItem: 'Nov vnos stroška',
    editItem: 'Sprememba stroška',
    id: 'Id',
    name: 'Ime',
    vehicle: 'Vozilo',
    costType: 'Vrsta stroška',
    price: 'Cena',
    currency: 'Valuta',
    costTime: 'Čas stroška',
    description: 'Opis',
    deleteMessage: 'Ste prepričani, da želite izbrisati ta strošek?',
    costTypes: {
      'Mali servis': 'Manjše vzdrževanje',
      'Veliki servis': 'Večje vzdrževanje',
      Gorivo: 'Gorivo',
      Gume: 'Gume',
      Parkiranje: 'Parkiranje',
      Ostalo: 'Drugo',
    },
  },
  vehicleMaintenanceHeaders: {
    name: 'Vozilo',
    details: 'Podrobnosti',
    dateFrom: 'Datum od',
    dateTo: 'Datum do',
    actions: 'Dejanja',
  },
  vehicleMaintenancePage: {
    newItem: 'Nov vnos okvare vozila',
    editItem: 'Sprememba okvare vozila',
    id: 'Id',
    vehicle: 'Vozilo',
    description: 'Opis',
    dateFrom: 'Čas od',
    dateTo: 'Čas do',
    deleteMessage: 'Ste prepričani, da želite izbrisati to okvaro vozila?',
  },
  rebatesHeaders: {
    min: 'Minimalno število voženj',
    max: 'Maksimalno število voženj',
    percentage: 'Popust (%)',
    actions: 'Dejanja',
  },
  rebatesPage: {
    newItem: 'Nov vnos popusta',
    editItem: 'Sprememba popusta',
    rebateMin: 'Minimalno število voženj',
    rebateMax: 'Maksimalno število voženj',
    rebate: 'Popust (%)',
    deleteRebate: 'Izbriši popust',
    deleteMessage: 'Ste prepričani, da želite izbrisati ta popust?',
  },
  customerCardsHeaders: {
    cardholderName: 'Ime imetnika kartice',
    cardNumber: 'Številka kartice',
    expiryDate: 'Datum poteka',
    cvv: 'CVV',
    actions: 'Dejanja',
  },
  customerCardsPage: {
    newItem: 'Nov vnos kartice',
    editItem: 'Sprememba kartice',
    cardholderName: 'Ime imetnika kartice',
    cardNumber: 'Številka kartice',
    expiryDate: 'Datum poteka',
    cvv: 'CVV',
    deleteCard: 'Izbriši kartico',
    deleteMessage: 'Ali ste prepričani, da želite izbrisati to kartico?',
  },
  pricingRegionsHeaders: {
    name: 'Ime',
    actions: 'Dejanja',
  },
  pricingRegionsPage: {
    newItem: 'Nov vnos cene regije',
    editItem: 'Sprememba cene regije',
    name: 'Ime',
    chooseCurrency: 'Izberi valuto',
    team10Price: 'Cena za ekipo 10',
    team10PaddlePriceId: 'ID cene za ekipo 10 Paddle',
    team25Price: 'Cena za ekipo 25',
    team25PaddlePriceId: 'ID cene za ekipo 25 Paddle',
    team50Price: 'Cena za ekipo 50',
    team50PaddlePriceId: 'ID cene za ekipo 50 Paddle',
    chooseCountries: 'Izberi države',
    description: 'Opis',
    deletePricingRegion: 'Izbriši ceno regije',
    deleteMessage: 'Ste prepričani, da želite izbrisati to ceno regije?',
  },
  notificationsHeaders: {
    time: 'Čas',
    notification: 'Obvestilo',
  },
  organisationsHeaders: {
    name: 'Organizacija',
    phoneNumber: 'Telefonska številka',
    email: 'E-pošta',
    address: 'Naslov',
    companyId: 'ID podjetja',
    vat: 'DDV',
    active: 'Aktiven',
    createdAt: 'Registriran',
    actions: 'Dejanja',
    billingDate: 'Datum obračuna',
  },
  organisationsPage: {
    newItem: 'Nov vnos organizacije',
    editItem: 'Uredi organizacijo',
    id: 'Id',
    name: 'Organizacija',
    address: 'Naslov',
    phoneNumber: 'Telefonska številka',
    email: 'E-pošta',
    vat: 'DDV',
    companyId: 'ID podjetja',
    warrantNumber: 'Številka odločbe',
    image: 'Slika',
    signature: 'Podpis',
    seal: 'Žig',
    deactivateMessage: 'Ste prepričani, da želite ',
    deactivate: 'ONEMOGOČI',
    activate: 'AKTIVIRAJ',
    organisation: 'organizacija',
    deleteOrganisation: 'Izbriši organizacijo',
    deleteMessage: 'Ste prepričani, da želite izbrisati to organizacijo?',
    cancelSubscrMessage:
      'Ste prepričani, da želite preklicati naročnino? Bodite pozorni, da z potrditvijo te akcije: 1. Račun vaše organizacije in vsi povezani podatki bodo trajno izbrisani. Tega dejanja ni mogoče razveljaviti. 2. Po prekinitvi boste takoj samodejno odjavljeni iz sistema. 3. V prihodnje ne boste več imeli dostopa za prijavo ali pridobivanje podatkov, povezanih z vašo organizacijo. Toplo priporočamo, da pred nadaljevanjem s to nepopravljivo akcijo pregledate vse potrebne informacije ali izvozite kritične podatke. Če imate kakršna koli vprašanja ali potrebujete pomoč, se obrnite na našo podporo. Želite nadaljevati s preklicem?',
    allDrivings: 'Skupno število naročil za vožnjo',
    allDrivingsAccepted: 'Skupno število sprejetih naročil za vožnjo',
    allDrivingsCanceled: 'Skupno število zavrnjenih naročil za vožnjo',
    allVehicles: 'Skupno število vozil',
    allUsers: 'Skupno število uporabnikov',
    areYouSure: 'Ste prepričani?',
    makeRoutesAvailable: 'Omogoči integracijske poti',
    percentage: 'Odstotek',
    deleteImageMessage: 'Ste prepričani, da želite izbrisati to sliko?',
    deleteImageTitle: 'Izbriši sliko',
    cancelSubscriptionTitle: 'Prekliči naročnino',
    subscriptionType: 'Vrsta naročnine',
    billingDate: 'Datum obračuna',
    transactions: 'Transakcije',
    articlesOfLaw: 'Členi zakona',
    country: 'Država',
    referent: 'Referent',
    language: 'Jezik',
    gnetId: 'GNET ID',
  },
  transactionsHeaders: {
    invoiceNumber: 'Številka računa',
    status: 'Status',
    total: 'Skupaj',
    currency: 'Valuta',
    billedAt: 'Zaračunano ob',
    actions: 'Dejanja',
  },
  transactionsPage: {
    newItem: 'Nova vnosa transakcije',
    editItem: 'Uredi transakcijo',
    id: 'ID',
    invoiceNumber: 'Številka računa',
    status: 'Status',
    sent: 'Poslano',
    completed: 'Dokončano',
    total: 'Skupaj',
    currency: 'Valuta',
    billedAt: 'Zaračunano ob',
    actions: 'Dejanja',
    deleteTransaction: 'Izbriši transakcijo',
    deleteMessage: 'Ste prepričani, da želite izbrisati to transakcijo?',
    currentSubscription: 'Trenutni paket',
    nextBillingDate: 'Naslednji datum obračuna',
    numberOfSeatsTaken: 'Število zasedenih članov ekipe',
    numberOfSeatsAvailable: 'Število razpoložljivih članov ekipe',
  },
  currenciesHeaders: {
    name: 'Ime',
    code: 'Koda',
    symbol: 'Simbol',
    active: 'Aktiven',
    default: 'Osnoven',
    actions: 'Dejanja',
  },
  currenciesPage: {
    newItem: 'Nov vnos valute',
    editItem: 'Sprememba valute',
    id: 'Id',
    currency: 'Valuta',
    code: 'Koda',
    symbol: 'Simbol',
    deleteMessage: 'Ste prepričani, da želite izbrisati to valuto?',
  },
  paymentMethodsHeaders: {
    name: 'Ime',
    active: 'Aktiven',
    default: 'Osnoven',
    priceVisible: 'Vidna cena',
    actions: 'Dejanja',
  },
  paymentMethodsPage: {
    newItem: 'Nov vnos načina plačila',
    editItem: 'Sprememba načina plačila',
    id: 'Id',
    paymentMethod: 'Način plačila',
    deleteMessage: 'Ste prepričani, da želite izbrisati ta način plačila?',
  },
  feedbacksHeaders: {
    subject: 'Ime',
    organisation: 'Organizacija',
    user: 'Uporabnik',
    actions: 'Dejanja',
  },
  passengersHeaders: {
    firstName: 'Ime',
    lastName: 'Priimek',
    phoneNumber: 'Telefonska številka',
    email: 'E-pošta',
    passport: 'Potni list',
    nationality: 'Narodnost',
    actions: 'Dejanja',
  },
  checkpointsHeaders: {
    checkpointLocation: 'Lokacija točke poti',
    arrivalTime: 'Čas prihoda',
    actions: 'Dejanja',
  },
  tables: {
    noDataText: 'Ni podatkov',
    search: 'Iskanje',
    drivings: 'Vožnje',
    drivingsByPage: 'Vožnje po strani',
    vehicles: 'Vozila',
    vehiclesByPage: 'Vozila po strani',
    vehiclesClasses: 'Razredi vozil',
    vehiclesClassesByPage: 'Razredi vozil po strani',
    expenses: 'Stroški',
    expensesByPage: 'Stroški po strani',
    vehiclesMaintenance: 'Okvare vozil',
    vehiclesMaintenanceByPage: 'Okvare vozil po strani',
    reportsByPage: 'Poročila po strani',
    currencies: 'Valute',
    currencieseByPage: 'Valute po strani',
    payments: 'Načini plačila',
    paymentsByPage: 'Načini plačila po strani',
    saasAdministrators: 'Vsi administratorji',
    users: 'Vsi uporabniki',
    usersByPage: 'Uporabniki po strani',
    clients: 'Vse stranke',
    clientsByPage: 'Stranke po strani',
    organisations: 'Vse organizacije',
    organisationsByPage: 'Organizacije po strani',
    rebates: 'Popusti',
    rebatesByPage: 'Popusti po strani',
    cards: 'Kartice',
    cardsByPage: 'Kartice na stran',
    transactions: 'Transakcije',
    transactionsByPage: 'Transakcije na strani',
    pricingRegions: 'Cenovne regije',
    pricingRegionsByPage: 'Cenovne regije po stranici',
    feedback: 'Povratne informacije',
    feedbacksByPage: 'Povratne informacije po strani',
    notifications: 'Obvestila',
    notificationsByPage: 'Obvestila po strani',
    all: 'Vse',
    passengers: 'Potniki',
    passengersByPage: 'Potniki po strani',
    of: 'od',
    checkpoints: 'Točke poti',
    checkpointsByPage: 'Točke poti na stran',
    invoices: 'Računi',
    invoicesByPage: 'Računi po strani',
    drivingOffers: 'Ponudbe za vožnjo',
    drivingOffersByPage: 'Ponudbe za vožnjo na strani',
    smsMessages: 'SMS sporočila',
    smsMessagesByPage: 'SMS sporočila na stran',
    resourcesUsed: 'Uporabljeni viri',
    resourcesUsedByPage: 'Viri po strani',
    inNext3Days: 'V naslednjih 3 dneh',
    notConfirmedOrPaid: 'Ni potrjeno ali plačano',
    inNext5Days: 'V naslednjih 5 dneh',
    latestReviews: 'Zadnje ocene',
    driversByPage: 'Vozniki po strani',
    reviewsByPage: 'Ocene po strani',
  },
  statistics: {
    numberOfAcceptedDrivings: 'Število sprejetih naročil za vožnjo',
    numberOfRejectedDrivings: 'Število zavrnjenih naročil za vožnjo po vozniku',
    vehicleDrivingsNumber: 'Število naročil za vožnjo na vozilo',
    vehicleProfit: 'Zaslužek na vozilo',
    monthDrivingsNumber: 'Število naročil za vožnjo na mesec',
    driverProfit: 'Zaslužek na voznik',
  },
  reportsHeaders: {
    vehicle: 'Vozilo',
    expenses: 'Stroški',
    profit: 'Dobiček',
    earnings: 'Zaslužek',
    daysWorking: 'Delovni dnevi',
    usageIndex: 'Kazalnik uporabe',
    client: 'Stranka',
    numberOfDrivings: 'Število naročil za vožnjo',
    percentForClient: 'Cena za stranko',
    sumOfPrice: 'Znesek',
  },
  packages: {
    active: 'Aktivno',
    starterTitle: 'Začetni',
    monthlyPackagesMessage: 'Mesečna naročnina na uporabnika.',
    starterText: 'Idealno za posameznike ali majhne ekipe, ki potrebujejo le osnovne funkcionalnosti.',
    starterListItemOne: 'Mobilne aplikacije',
    starterListItemTwo: 'Potisna obvestila',
    starterListItemThree: 'Dostop do administracije',
    starterListItemFour: 'Vključena integracija na spletno stran',
    standardTitle: 'Standardni',
    standardText: 'Odlično za organizacije z več kot 5 vozniki.',
    standardListItemOne: 'Vključen STARTER paket',
    standardListItemTwo: 'Ni omejitve števila voznikov',
    standardListItemThree: '99,9% razpoložljivost',
    standardListItemFour: 'Poročila in Upravljanje vozil',
    premiumTitle: 'Premium',
    premiumText: 'Idealno za organizacije limuzinskega servisa, ki delujejo na natančnih podatkih.',
    premiumListItemOne: 'Vključen STANDARD paket',
    premiumListItemTwo: 'BI in napovedovanje stroškov',
    premiumListItemThree: 'Izvoz podatkov',
    premiumListItemFour: 'Tedenska in mesečna poročila',
    warningOne: '* Prikazane cene so mesečna naročnina na uporabnika',
    warningTwo: '* Po preklopu na nov paket ni mogoče preklopiti na manjši paket v naslednjih 6 mesecih',
    warningThree: '* Uporabniki demo načrta imajo vpogled v funkcionalnosti, ki jih zagotavlja premium paket',
    warningOneAnnualPackage: 'Potrebujete več? Imate večjo organizacijo? Imamo paket ENTERPRISE za vas.',
    warningTwoAnnualPackage: 'in naša prodajna ekipa vas bo kmalu kontaktirala.',
    packageChange: 'Sprememba paketa',
    changePackage: 'Ste prepričani, da želite preklopiti na',
    package: 'paketa',
    annualPricing: 'Letna tarifa',
    annualPackageType10: 'Ekipa 10',
    annualPackageType25: 'Ekipa 25',
    annualPackageType50: 'Ekipa 50',
    'Team 10': 'Ekipa 10',
    'Team 25': 'Ekipa 25',
    'Team 50': 'Ekipa 50',
    annualPackagesMessage: 'Letna naročnina na organizacijo.',
    annualPackageType10MainMessage: 'Do 10 uporabniških računov.',
    annualPackageType25MainMessage: 'Do 25 uporabniških računov.',
    annualPackageType50MainMessage: 'Do 50 uporabniških računov.',
    annualPackageListItemOne: 'Paket Standard',
    annualPackageListItemTwo: 'Enkratno plačilo',
    annualPackageListItemThree: 'Ugodnosti cene',
    annualPackageType10Discount: '- do 65% popusta',
    annualPackageType25Discount: '- do 68% popusta',
    annualPackageType50Discount: '- do 65% popusta',
    allFeaturesIncluded: 'Vse funkcije so vključene',
    trialPeriodIncluded: 'Vključeno je preskusno obdobje',
    unlimitedVehicles: 'Neomejeno število vozil',
    unlimitedBookings: 'Neomejene rezervacije',
    onetimePaymentAnnually: 'Enkratno letno plačilo',
  },
  successPaymentPage: {
    message: 'Uspešno ste plačali. Hvala vam.',
  },
  demoExpiring: {
    notification: 'Obvestilo',
    expiredSubscriptionMessage:
      'Obveščamo vas, da je vaša naročnina na to platformo potekla. Za nadaljevanje uporabe naših storitev obnovite svojo naročnino.',
  },
  login: {
    username: 'Uporabniško ime',
    password: 'Geslo',
    rememberMe: 'Zapomni si me',
    login: 'Prijava',
    noAccount: 'Še nimate računa? Registrirate se lahko',
    forgetPassword: 'Ste pozabili geslo? Ponastavite ga lahko',
    registerHere: 'Tukaj',
    email: 'E-pošta',
    enterEmail: 'Prosimo, vnesite svoj e-poštni naslov',
    enterPassword: 'Prosimo, vnesite novo geslo',
    signInWithGoogle: 'Prijava prek Google računa',
    passwordChangeError: 'Prišlo je do napake pri nastavljanju gesla. Poskusite znova ali stopite v stik s podporo.',
  },
  registration: {
    register: 'Registriraj se',
    name: 'Ime organizacije',
    address: 'Naslov',
    phoneNumber: 'Telefonska številka',
    email: 'E-pošta',
    companyId: 'ID podjetja',
    vat: 'DDV',
    language: 'Jezik',
    package: 'Paket',
    accept: 'Sprejemam',
    terms: 'pogoje uporabe',
    successfullyRegistered: 'Uporabnik je bil uspešno registriran. Prejeli boste e-pošto z povezavo za potrditev.',
    haveAccount: 'Že imate račun? Lahko se prijavite',
    loginHere: 'Tukaj',
    trialPeriodInfo: 'Vključeno je brezplačno preskusno obdobje 14 dni. Račun bo sledil po koncu preskusnega obdobja.',
  },
  driving: {
    drivingType: 'Tip naročila',
    general: 'Splošne informacije',
    newDriving: 'Načrtuj prenos',
    newDailyRent: 'Načrtuj dnevni najem',
    areYouSure: 'Ste prepričani?',
    vehicleIsUnavailable: 'Vozilo je zasedeno.',
    vehicleIsDamaged: 'Vozilo je poškodovano.',
    sureAboutThisVehicle: 'Ste prepričani, da želite izbrati to vozilo?',
    driverUnavaliable: 'Voznik je zaseden.',
    sureAboutThisDriver: 'Ste prepričani, da želite izbrati tega voznika?',
    checkVehicleSeatAvailability: 'To vozilo ne more sprejeti toliko potnikov.',
    cancelDriving: 'Ste prepričani, da želite preklicati naročilo za prevoz?',
    finishDriving: 'Ste prepričani, da želite zaključiti naročilo za prevoz?',
    noShowDriving: 'Ali ste prepričani, da želite označiti naročilo vožnje kot neudeležbo?',
    deleteDriving: 'Ste prepričani, da želite izbrisati naročilo za prevoz?',
    newTransfer: 'Nov prenos',
    driving: 'Naročilo za prevoz',
    comments: 'Komentarji',
    id: 'ID',
    locationFrom: 'Začetna točka',
    locationTo: 'Cilj',
    vehicleClass: 'Razred vozila',
    vehicle: 'Vozilo',
    driver: 'Voznik',
    pickupTime: 'Čas prevzema',
    clientConfirmed: 'Stranka potrdila',
    description: 'Opis',
    extraInfo: 'Dodatne informacije',
    drivingNotAccepted: 'Naročila za prevoz niso sprejeli:',
    currency: 'Valuta',
    paymentMethod: 'Način plačila',
    price: 'Cena',
    paid: 'Plačano',
    distance: 'Razdalja (km)',
    distanceMi: 'Razdalja (milj)',
    expectedTime: 'Pričakovana trajanje',
    waitingTime: 'Čas čakanja (h)',
    EnterTheNumberOfHoursWaiting: 'Vnesite število ur čakanja',
    dropOffTime: 'Čas prihoda',
    comebackTime: 'Čas povratka',
    acceptUntil: 'Sprejmi do',
    drivingRequired: 'Je naročilo za prevoz obvezno',
    comment: 'Komentar',
    attachments: 'Priloge',
    addFiles: '+ Dodaj datoteke',
    deleteFiles: 'Ste prepričani, da želite izbrisati to datoteko?',
    passengers: 'Potniki',
    addPassenger: '+ Dodaj potnika',
    deletePassenger: 'Ste prepričani, da želite izbrisati tega potnika?',
    chooseClient: 'Izberite stranko',
    costs: 'Stroški',
    checkpoints: 'Točke poti',
    addCheckpoint: 'Dodaj točko poti',
    deleteCheckpoint: 'Ste prepričani, da želite izbrisati točko poti?',
    waitingBoardText: 'Besedilo na čakanju na tabli',
    flightNumber: 'Številka leta',
    flightInfo: 'Informacije o letu',
    createdBy: 'Naročilo za vožnjo ustvarjeno ob <b>{time}</b> od <b>{user}</b>',
    client: 'Stranka',
    gnet: 'GNET',
    noteDriver: 'Opomba za voznika',
    noteDispatcher: 'Opomba za dispečerja',
    passenger: 'Potnik',
    fullName: 'Polno ime',
    phoneNumber: 'Telefonska številka',
    email: 'E-pošta',
    suitcasesNumber: 'Število kovčkov',
    passengersNumber: 'Število potnikov',
    babySeatsNumber: 'Število otroških sedežev',
    stopsNumber: 'Število postankov',
    steps: {
      mainInfo: 'Glavne informacije',
      additionalInfo: 'Dodatne informacije',
      review: 'Pregled',
    },
    commissionAmount: 'Provizija',
  },
  drivingStatus: {
    pending: 'V čakanju',
    accepted: 'Sprejeto',
    rejected: 'Zavrnjeno',
    expired: 'Poteklo',
    canceled: 'Preklicano',
    draft: 'Osnutek',
    done: 'Dokončano',
    noShow: 'Neudeležba',
  },
  drivingOrderType: {
    dailyRent: 'Dnevni najem',
    transfer: 'Prenos',
  },
  flightInfo: {
    airlineName: 'Ime letalske družbe',
    flightNumber: 'Številka leta',
    departureAirport: 'Letališče odhoda',
    departureCountry: 'Država odhoda',
    departureTime: 'Čas odhoda',
    arrivalAirport: 'Letališče prihoda',
    arrivalCountry: 'Država prihoda',
    arrivalTime: 'Čas prihoda',
    departureDelayed: 'Zamuda odhoda',
    flightStatus: 'Status leta',
    percentageOfFlight: 'Odstotek leta',
  },
  customValidations: {
    fileType: 'Vrsta datoteke .{extension} ni podprta',
    fileSize: 'Datoteke ne smejo biti večje od {size}',
    fieldRequired: 'Polje {field} je obvezno',
    fieldType: 'Polje {field} mora biti tipa {type}',
    fieldLengthMin: 'Polje {field} mora imeti vsaj {length}',
    fieldLengthMax: 'Polje {field} ne sme presegati {length}',
    fieldUnique: 'Polje {field} mora biti edinstveno',
    fieldValid: 'Polje {field} mora biti veljavno',
    noActiveSubscription: 'Ni najdene aktivne naročnine',
    usersLimitExceeded: 'Omejitev uporabnikov dosežena',
    fieldBefore: '{field1} mora biti pred {field2}',
    flightNotExist: 'Let {field} ne obstaja',
    gnetKeyNotValid: 'Navedeni ključ Gnet ni veljaven.',
    gnetPassengersRequired: 'Naročilo za vožnjo ne more biti poslano na GNET brez potnikov.',
    priceAndCurrencyRequired: 'Povezave za plačilo ni mogoče ustvariti brez cene in valute',
    fieldValueNotSupported: 'Vrednost polja {field} ni podprta',
    noCountryCode: 'Niste izbrali države',
    noStripeForYourCountry: 'Stripe ni na voljo v vaši državi',
    stripeAlreadyEnabled: 'Stripe je že aktiviran',
    fieldValueMin: 'Polje {field} mora biti {min} ali več',
    fieldValueMax: 'Polje {field} mora biti {max} ali manj',
  },
  fieldTypes: {
    string: 'besedilo',
    integer: 'število',
    date: 'datum',
  },
  names: {
    username: 'uporabniško ime',
    password: 'geslo',
    choose_client: 'izberi stranko',
    choose_month: 'izberi mesec',
    currency: 'valuta',
    payment_method: 'način plačila',
    vehicle_class: 'razred vozila',
    first_name: 'ime',
    last_name: 'priimek',
    passport: 'potni list',
    nationality: 'državljanstvo',
    plate_number: 'registrska številka',
    brand: 'znamka avtomobila',
    number_of_passengers: 'število potnikov',
    fuel_consumption: 'poraba goriva',
    year_of_manufacture: 'letnik izdelave',
    price_per_km: 'cena na km',
    price_per_mi: 'cena na miljo',
    price_per_hour: 'cena na uro',
    price_per_waiting_hour: 'cena na čakalno uro',
    vehicle: 'vozilo',
    description: 'opis',
    time_from: 'čas od',
    time_to: 'čas do',
    name: 'ime',
    cost_type: 'vrsta stroška',
    cost_time: 'čas stroška',
    price: 'cena',
    rebate_min: 'najmanjše število naročil',
    rebate_max: 'največje število naročil',
    rebate: 'popust',
    phone_number: 'telefonska številka',
    organisation: 'organizacija',
    role: 'vloga',
    image: 'slika',
    location_from: 'lokacija od',
    location_to: 'lokacija do',
    pickup_time: 'čas prevzema',
    distance: 'razdalja',
    waiting_time: 'čas čakanja',
    comment: 'komentar',
    address: 'naslov',
    vat: 'DDV',
    company_id: 'ID podjetja',
    company_personal_id: 'id podjetja/osebna id',
    licence: 'licenca',
    warrant_number: 'številka pooblastila',
    seal: 'pečat',
    signature: 'podpis',
    email: 'e-pošta',
    percentage: 'odstotek',
    organizationName: 'ime organizacije',
    package: 'paket',
    choose_currency: 'izberi valuto',
    team_10_price: 'cena za ekipo 10',
    team_10_paddle_price_id: 'ID cene za ekipo 10 Paddle',
    team_25_price: 'cena za ekipo 25',
    team_25_paddle_price_id: 'ID cene za ekipo 25 Paddle',
    team_50_price: 'cena za ekipo 50',
    team_50_paddle_price_id: 'ID cene za ekipo 50 Paddle',
    countries: 'zemlje',
    invoice_number: 'številka računa',
    status: 'status',
    total: 'skupaj',
    billed_at: 'zaračunano ob',
    articles_of_law: 'členi zakona',
    country: 'država',
    referent: 'referent',
    language: 'jezik',
    location: 'lokacija',
    arrival_time: 'čas prihoda',
    waiting_board_text: 'besedilo na čakanju na tabli',
    flight_number: 'številka leta',
    gnet_id: 'GNET ID',
    receiver_id: 'ID prejemnika',
    preferred_vehicle_type: 'želeni tip vozila',
    reservation_type: 'vrsta rezervacije',
    run_type: 'vrsta vožnje',
    trip_duration_minutes: 'trajanje potovanja',
    client: 'stranka',
    client_type: 'tip stranke',
    card_holder_name: 'ime imetnika kartice',
    card_number: 'številka kartice',
    expiry_date: 'datum poteka',
    cvv: 'cvv',
    code: 'koda',
    symbol: 'simbol',
    quantity: 'količina',
    discount: 'popust',
    driving_type: 'tip vožnje',
    full_name: 'polno ime',
    suitcases_number: 'število kovčkov',
    passengers_number: 'število potnikov',
    baby_seats_number: 'število otroških sedežev',
    stops_number: 'število postankov',
    commission_amount: 'provizija',
  },
  welcomeModal: {
    welcome: 'Dobrodošli v LimoExpress',
  },
  weekDays: {
    monday: 'Ponedeljek',
    tuesday: 'Torek',
    wednesday: 'Sreda',
    thursday: 'Četrtek',
    friday: 'Petek',
    saturday: 'Sobota',
    sunday: 'Nedelja',
  },
  select: {
    noDataAvailable: 'Podatki niso na voljo',
  },
  excelExport: {
    to_date_range: 'Poročila ni mogoče generirati za prihodnje datume. Vnesite veljavne datume.',
    overlap_range: 'Končni čas ne sme biti večji od začetnega časa.',
  },
  setPassword: {
    success: 'E-pošta poslana',
    checkEmail: 'Preverite svojo e-pošto za nadaljnja navodila.',
    already_changed: 'Že spremenjeno',
    send_new_reset_request: 'Geslo ste že spremenili s tem povezovanjem. Pošljite novo zahtevo za ponastavitev gesla.',
  },
  statisticsPage: {
    reportType: 'Vrsta poročila',
    reportTime: 'Čas poročila',
    vehicleUtilization: 'Izkoriščenost vozila',
    costEffectiveness: 'Učinkovitost stroškov',
    generalReport: 'Splošno poročilo',
    clientsReport: 'Poročilo po strankah',
  },
  profile: {
    yourProfile: 'Vaš profil',
    name: 'Ime',
    role: 'Vloga',
    username: 'Uporabniško ime',
    password: 'Geslo',
    phoneNumber: 'Telefonska številka',
    email: 'E-pošta',
    percentage: 'Odstotek',
  },
  gpsMaps: {
    noTransfers: 'Ni tekočih ali prihajajočih prenosov.',
    map: 'Zemljevid',
    transfers: 'Vožnje',
    drivers: 'Vozači',
    ongoing: 'V teku',
    upcoming: 'Prihajajoči',
    driving: 'Vožnja',
    driver: 'Voznik',
    vehicle: 'Vozilo',
    requestDriverLocation: 'Zahteva za lokacijo voznika',
    lastRecordedLocation: 'Zadnja zabeležena lokacija',
  },
  driverStatus: {
    available: 'Na voljo',
    offline: 'Odsoten',
    inTransfer: 'V prenosu',
  },
  onboardingTutorialMain: {
    languageStep: 'Tu lahko nastavite želeni jezik za aplikacijo.',
    notificationsStep: 'To so vaša obvestila.',
    addTransferStep:
      'Kliknite to gumb za ustvarjanje vaših prenosov in dnevnih najemov. Dnevni najemi so prilagojeni prenosi brez določene destinacije.',
    sideMenuButtonStep: 'Kliknite tukaj, da prikažete ali skrijete menijsko vrstico.',
    sideMenuExpStep:
      'Od tu lahko navigirate do glavnih sekcij aplikacije: Prenosi, Flota, Stroški, Uprava, Statistika in Transakcije.',
    sideMenuButtonCloseStep: 'Kliknite ikono znova, da skrijete menijsko vrstico.',
    supportStep:
      'Če potrebujete pomoč pri kakršnem koli problemu, ne oklevajte, da bi stopili v stik s podporo s klikom tukaj.',
  },
  onboardingTutorialVehicles: {
    tableStep: 'Vaša vozila bodo tukaj navedena v tabeli, potem ko jih dodate.',
    searchStep: 'Vozila lahko lažje najdete s direktnim iskanjem.',
    addStep:
      "Dodajte nova vozila s pritiskom na gumb 'Dodaj'. Opazite, da morate pred uporabo te funkcije dodati 'Razrede vozil'.",
  },
  onboardingTutorialStatistics: {
    typeStep:
      'V tem razdelku lahko najdete vse svoje statistične podatke, prikazane v grafikonih. Izberite vrsto statistike tukaj.',
    intervalStep: 'Dodajte interval, za katerega želite videti statistiko.',
    downloadStep: 'Te tabele lahko tudi prenesete in natisnete.',
  },
  onboardingTutorialGps: {
    tabOneStep:
      'V GPS modulu lahko spremljate svoje voznike in prenose na istem mestu. Izberite to zavihek, da vidite trenutne in prihodnje prenose.',
    tabTwoStep: 'Izberite vozila, da jih vidite na zemljevidu. Prosta vozila lahko direktno dodate prenosom od tukaj.',
    mapStep: 'Izberite ali odstranite vozila tako, da kliknete na njihov zatič na zemljevidu.',
  },
  advancedSettings: {
    advancedSettings: 'Napredne nastavitve',
    distanceUnit: 'Enota za razdaljo',
    distanceUnitDescription: 'Izberite enoto za merjenje razdalje.',
    kilometers: 'Kilometri',
    miles: 'Milje',
    dateFormat: 'Oblika datuma',
    dateFormatDescription: 'Izberite želeni format datuma.',
    ddmmyyyy: 'DD-MM-YYYY',
    mmddyyyy: 'MM-DD-YYYY',
    mandatoryDrivings: 'Obvezne vožnje',
    mandatoryDrivingsDescription: 'Izberite, če želite vse vožnje narediti obvezne privzeto.',
    mandatoryDrivingsDefault: 'Obvezne Vožnje (Privzeto)',
    paidDrivings: 'Plačane vožnje',
    paidDrivingsDescription: 'Označite, če želite, da so vse vožnje plačane privzeto.',
    paidDrivingsDefault: 'Plačane vožnje (Privzeto)',
    notificationsEmail: 'E-naslov za obvestila',
    notificationsEmailDescription:
      'Spremenite privzeti e-naslov za prejemanje obvestil (če ne želite uporabiti e-naslova vaše organizacije).',
    email: 'E-naslov',
    articlesOfLaw: 'Členi zakona',
    integrations: 'Integracije',
    gnetId: 'GNET ID',
    gnetDescription:
      'GNET je celovita platforma, zasnovana za optimizacijo sledenja v realnem času, upravljanja voznega parka in dispečerskih operacij v transportni industriji.',
    makeRoutesAvailable: 'Omogoči integracijske poti',
    makeRoutesAvailableDescription:
      'Aktivirajte svoj API ključ, da integrirate LimoExpress z vašo spletno stranjo, ERP sistemom in več.',
    makePublicLinkAvailable: 'Omogočite javno povezavo',
    makePublicLinkAvailableDescription:
      'Aktivirajte povezavo za dostop do javne strani za rezervacije, da bodo lahko vaši stranke dostopale do naprednega obrazca za rezervacije in zlahka ustvarjale račune. Povezavo lahko uporabite v novicah, na spletni strani, letakih; to je edinstvena povezava samo za vaše podjetje.',
    emailsEnabled: 'Omogočena e-pošta',
    emailsEnabledDefault: 'Omogočena e-pošta (Privzeto)',
    emailsEnabledDescription: 'Potrdite, če želite prejemati obvestila po e-pošti.',
    passengerReviewsEnabled: 'Ocene potnikov omogočene',
    passengerReviewsEnabledDefault: 'Ocene potnikov omogočene',
    passengerReviewsEnabledDescription:
      'Z aktiviranjem te funkcije bodo vaši potniki (če je vnesen e-mail) prejeli potrditveno e-pošto po končani vožnji (označeni kot Končano) in bodo imeli možnost podati ocene (povratne informacije) za vaše storitve.',
    vat: 'DDV',
    vatPercentage: 'Odstotek DDV',
    vatPercentageDescription: 'Vnesite odstotek DDV.',
    invoicePaymentInstructions: 'Navodila za plačilo računa',
    stripe: 'Stripe',
    stripeDescription:
      'Aktivirajte Stripe, da boste lahko zaračunavali storitve svojim strankam. LimoExpress ne zaračunava provizije.',
  },
  drivingExcelCheckboxes: {
    drivingNumber: 'Številka naročila',
    drivingType: 'Tip naročila',
    drivingStatus: 'Status',
    locationFrom: 'Izhodišče',
    locationTo: 'Cilj',
    driver: 'Voznik',
    client: 'Stranka',
    plateNumber: 'Registrska številka',
    note: 'Opis',
    fuelConsumption: 'Poraba goriva',
    pickupTime: 'Čas prevzema',
    numberOfPassengers: 'Število potnikov',
    passengers: 'Potniki',
    price: 'Cena',
    paid: 'Plačano',
    paymentMethod: 'Način plačila',
    waitingTime: 'Čakalni čas (h)',
    distance: 'Razdalja (km)',
    distanceMi: 'Razdalja (milj)',
    selectAll: 'Izberi vse',
  },
  gnet: {
    gnetInfo: 'Informacije GNET',
    requesterId: 'ID prosilca',
    receiverId: 'ID prejemnika',
    preferredVehicleType: 'Priporočena vrsta vozila',
    reservationType: 'Vrsta rezervacije',
    runType: 'Vrsta vožnje',
    price: 'Cena',
    currency: 'Valuta',
    noteDriver: 'Opomba za voznika',
    noteDispatcher: 'Opomba za dispečerja',
    acceptDriving: 'Ali ste prepričani, da želite sprejeti naročilo za vožnjo?',
    rejectDriving: 'Ali ste prepričani, da želite zavrniti naročilo za vožnjo?',
    tripDurationMinutes: 'Trajanje potovanja (minute)',
  },
  invoicesHeaders: {
    invoiceNumber: 'Številka računa',
    client: 'Stranka',
    totalPrice: 'Skupna cena',
    drivings: 'Vožnje',
    paid: 'Plačano',
    currency: 'Valuta',
    createdAt: 'Ustvarjeno dne',
    actions: 'Dejanja',
  },
  invoicesPage: {
    newItem: 'Nov vnos računa',
    chooseClient: 'Izberite stranko',
    chooseDrivings: 'Izberite vožnje',
    deleteMessage: 'Ste prepričani, da želite izbrisati ta račun?',
  },
  drivingOffersHeaders: {
    number: 'Številka ponudbe za vožnjo',
    totalPrice: 'Skupna cena',
    description: 'Opis',
    paid: 'Plačano',
    currency: 'Valuta',
    createdAt: 'Ustvarjeno dne',
    actions: 'Dejanja',
  },
  drivingOffersPage: {
    newItem: 'Nov vnos ponudbe za vožnjo',
    item: 'Postavka',
    description: 'Opis',
    title: 'Naslov',
    vehicleClass: 'Razred vozila',
    quantity: 'Količina',
    price: 'Cena',
    discount: 'Popust',
    vat: 'DDV',
    deleteMessage: 'Ali ste prepričani, da želite izbrisati to ponudbo za vožnjo?',
  },
  modal: {
    reservationSuccessTitle: 'Uspeh',
    reservationSuccessMessage: 'Zahteva za rezervacijo je bila uspešno poslana. Nekdo vas bo kmalu kontaktiral.',
  },
  dashboardPage: {
    drivingCountToday: 'Število voženj (danes)',
    drivingCountThisMonth: 'Število voženj (zadnjih 30 dni)',
    dailyRentsAndTransfers: 'Število voženj (dnevni najemi/transferji)',
    drivingTotalAmount: 'Skupni znesek',
    driversCount: 'Aktivni vozniki (±7 dni)',
    passengerCount: 'Število potnikov (zadnjih 30 dni)',
  },
  reviewsHeaders: {
    rating: 'Ocena',
    comment: 'Komentar',
  },
  resourceOverviewHeaders: {
    actionType: 'Vrsta akcije',
    user: 'Uporabnik',
    action: 'Akcija',
    createdAt: 'Ustvarjeno',
  },
  resourceOverviewPage: {
    smsMessagesLeft: 'Število preostalih SMS sporočil',
    flightsCheckLeft: 'Število preostalih preverjanj letov',
    sms: 'SMS',
    flightCheck: 'Preverjanje leta',
  },
};
