export default {
  sidebar: {
    fleet: 'Парк автомобилей',
    vehicles: 'Транспортные средства',
    vehiclesClasses: 'Классы автомобилей',
    administration: 'Администрирование',
    currencies: 'Валюты',
    currenciesAdministration: 'Администрирование валют',
    paymentTypes: 'Способы оплаты',
    paymentTypesAdministration: 'Администрирование способов оплаты',
    users: 'Пользователи',
    usersAdministration: 'Администрирование пользователей',
    clients: 'Клиенты',
    clientsAdministration: 'Администрирование клиентов',
    organizations: 'Организации',
    organizationsAdministration: 'Администрирование организаций',
    saasAdmin: 'Админ Saas',
    administrators: 'Администраторы',
    saasAdminAdministration: 'Администрирование админа Saas',
    statistics: 'Статистика',
    generalOverview: 'Общий отчет',
    payment: 'Оплата',
    rebates: 'Скидки',
    pricingRegions: 'Ценовые регионы',
    pricingRegionsAdministration: 'Администрирование ценовых регионов',
    help: 'Помощь',
    drivings: 'Заказы на поездки',
    calendar: 'Календарь',
    tabularView: 'Табличный вид',
    expenses: 'Расходы',
    vehicleExpensesAdministration: 'Администрирование затрат на транспортные средства',
    vehicleMaintenance: 'Техническое обслуживание автомобилей',
    vehicleMaintenanceAdministration: 'Администрирование технического обслуживания автомобилей',
    organization: 'Организация',
    organizationAdminstration: 'Администрирование организации',
    packages: 'Пакеты',
    packagesChange: 'Изменение пакетов',
    logout: 'Выход из системы',
    logoutText: 'Вы уверены, что хотите выйти из системы?',
    unaccessibleRoutes: 'Вы не можете получить доступ к этой странице. Обновите пакет для полного доступа.',
    transactions: 'Транзакции',
    gpsMap: 'GPS карта',
    team: 'Команда',
    teamAdministration: 'Управление командой',
    invoices: 'Счета',
    invoicesAdministration: 'Управление счетами',
    drivingOffers: 'Предложения',
    drivingOffersAdministration: 'Управление предложениями',
    dashboard: 'Панель управления',
    dashboardAdministration: 'Администрирование панели управления',
    resourceOverview: 'Обзор ресурсов',
    resourceOverviewAdministration: 'Управление обзором ресурсов',
    rides: 'Поездки',
  },
  header: {
    organization: 'Организация',
    newDriving: 'Заказ на поездку',
    dailyRent: 'По дням',
    transfer: 'Трансфер',
    notifications: 'Уведомления',
    changeLanguage: 'Сменить язык',
  },
  footer: {
    poweredBy: 'Предоставлено',
    subscriptionTo: 'Ваша подписка на',
    trialEndsAt: 'Ваш испытательный период для',
    packageExpiresIn: 'подписка истекает через',
    clickToChangePackage: 'Нажмите, чтобы изменить пакет',
    clickToActivatePackage: 'Нажмите, чтобы активировать пакет',
    clickToPay: 'Кликните для оплаты',
    packageHasExpired: 'пакет истек',
  },
  buttons: {
    ok: 'ОК',
    yes: 'Да',
    no: 'Нет',
    save: 'Сохранить',
    send: 'Отправить',
    sendMessage: 'Отправить сообщение',
    edit: 'Изменить',
    add: 'Добавить',
    confirm: 'Подтвердить',
    cancel: 'Отмена',
    cancelDriving: 'Отменить заказ на поездку',
    markAsDone: 'Отметить как выполненное',
    markAsNoShow: 'Отметить как неявку',
    deleteDriving: 'Удалить вождение',
    newOrganisation: 'Новая организация',
    changePackage: 'Изменить пакет',
    downloadStats: 'Загрузить статистику',
    downloadPDF: 'Загрузить PDF',
    today: 'Сегодня',
    choose: 'Выбрать',
    login: 'Войти',
    generateFile: 'Создать контракт',
    generateDrivingOrder: 'Печать заказа на поездку',
    downloadFile: 'Загрузить файл',
    activate: 'Активировать',
    deactivate: 'Деактивировать',
    showImage: 'Показать изображение',
    showDetails: 'Показать детали',
    actions: 'Действия',
    delete: 'Удалить',
    open: 'Открыть',
    show: 'Показать',
    options: 'Опции',
    prev: 'Предыдущий',
    next: 'Следующий',
    skip: 'Пропустить',
    finish: 'Завершить',
    settings: 'Настройки',
    cancelSubscription: 'Отменить подписку',
    backToEditOrganization: 'Вернуться к редактированию организации',
    payNow: 'Заплатить сейчас',
    back: 'Назад',
    integrationRoutes: 'Маршруты интеграции',
    transactions: 'Транзакции',
    articlesOfLaw: 'Статьи закона',
    export: 'Экспорт',
    duplicate: 'Сделайте копию',
    makeReturnTrip: 'Сделать обратное путешествие',
    selectOrganisation: 'Выберите организацию',
    advancedSettings: 'Расширенные настройки',
    accept: 'Принять',
    reject: 'Отклонить',
    sendViaGnet: 'Отправить через GNET',
    printInvoice: 'Распечатать счёт',
    enable: 'Включить',
    disable: 'Отключить',
    generatePaymentLink: 'Сгенерировать ссылку для оплаты',
    addItem: '+ Добавить элемент',
  },
  tooltips: {
    save: 'Сохранить',
    edit: 'Изменить',
    delete: 'Удалить',
    showImage: 'Показать изображение',
    open: 'Открыть',
    show: 'Показать',
    showDetails: 'Показать детали ',
    downloadFile: 'Загрузить файл',
    generateFile: 'Создать контракт',
    generateDrivingOrder: 'Печать заказа на поездку',
    activate: 'Активировать',
    deactivate: 'Деактивировать',
    cancel: 'Отмена',
    cancelDriving: 'Отменить заказ на поездку',
    cannotMarkAsCanceled: 'Заказ не может быть отмечен как отмененный, потому что он уже принят',
    markAsDone: 'Отметить как выполненное',
    cannotMarkAsDone: 'Заказ не может быть отмечен как выполненный, потому что он еще не произошел',
    markAsNoShow: 'Отметить как неявку',
    cannotMarkAsNoShow: 'Заказ не может быть отмечен как неявка, потому что он еще не состоялся',
    deleteDriving: 'Удалить вождение',
    cannotDelete:
      'Извините, но по соображениям безопасности пользователи не могут быть удалены, пока они находятся в системе.',
    duplicate: 'Сделайте копию',
    makeReturnTrip: 'Сделать обратное путешествие',
    refresh: 'Обновить',
    selectOrganisation: 'Выберите организацию',
    sendViaGnet: 'Отправить через GNET',
    receivedViaGnet: 'Получено через GNET',
    sentViaGnet: 'Отправлено через GNET',
    printInvoice: 'Распечатать счёт',
    optionsDisabledGnet: 'Вы должны отклонить или принять заказ, чтобы получить доступ к меню опций',
    cannotMakeReturnTrip: 'Вы не можете сделать обратную поездку для ежедневной аренды',
    cannotSendGnetOrderViaGnet: 'Вы не можете отправить заказ через gnet',
    cannotSendViaGnetWithoutGnetId: 'Вы не можете отправить заказ через gnet без ID gnet',
    cannotPrintInvoiceWithoutClient: 'Вы не можете распечатать счет без выбора клиента',
    cannotMarkAsDoneStatusRejected: 'Заказ не может быть отмечен как выполненный, потому что он уже был отклонен',
    cannotMarkAsDoneStatusCanceled: 'Заказ не может быть отмечен как выполненный, потому что он уже был отменен',
    cannotMarkAsDoneStatusDone:
      'Заказ не может быть отмечен как выполненный, потому что он уже был отмечен как выполненный',
    cannotMarkAsDoneStatusNoShow:
      'Заказ не может быть отмечен как выполненный, потому что он уже был отмечен как неявка',
    cannotMarkAsNoShowStatusRejected: 'Заказ не может быть отмечен как неявка, потому что он уже был отклонен',
    cannotMarkAsNoShowStatusCanceled: 'Заказ не может быть отмечен как неявка, потому что он уже был отменен',
    cannotMarkAsNoShowStatusDone: 'Заказ не может быть отмечен как неявка, потому что он уже был выполнен',
    cannotMarkAsNoShowStatusNoShow: 'Заказ не может быть отмечен как неявка, потому что он уже был отмечен как неявка',
    cannotMarkAsCanceledGnet: 'Заказ не может быть отменен, потому что он был получен через gnet',
    cannotMarkAsCanceledStatusRejected: 'Заказ не может быть отменен, потому что он уже был отклонен',
    cannotMarkAsCanceledStatusExpired: 'Заказ не может быть отменен, потому что он уже истек',
    cannotMarkAsCanceledStatusCanceled: 'Заказ не может быть отменен, потому что он уже был отменен',
    cannotMarkAsCanceledStatusDraft: 'Заказ не может быть отменен, потому что он уже находится в черновике',
    cannotMarkAsCanceledStatusCompleted: 'Заказ не может быть отменен, потому что он уже был выполнен',
    cannotMarkAsCanceledStatusNoShow: 'Заказ не может быть отменен, потому что он уже был отмечен как неявка',
    cannotDeleteDrivingSentViaGnet: 'Заказ не может быть удален, потому что он был отправлен через gnet',
    cannotDeleteDrivingReceivedViaGnet: 'Заказ не может быть удален, потому что он был получен через gnet и принят',
    generatePaymentLink: 'Сгенерировать ссылку для оплаты',
    cannotGeneratePaymentLInk: 'Ссылка для оплаты не может быть создана, так как вы не активировали Stripe',
  },
  snackbar: {
    close: 'Закрыть',
    savedSuccessfully: 'Успешно сохранено',
    excelDownloadedSuccessfully: 'Успешно загружено',
    deletedSuccessfully: 'Успешно удалено',
    sentSuccessfully: 'Успешно отправлено',
    activeStatusChanged: 'Статус активности успешно изменен',
    defaultStatusChanged: 'Статус по умолчанию успешно изменен',
    primaryStatusChanged: 'Основной статус успешно изменен',
    anErrorOccured: 'Произошла ошибка, сообщите в службу поддержки',
    loadingCommentsFailed: 'Не удалось загрузить комментарии',
    drivingCanceled: 'Заказ на поездку успешно отменен',
    drivingFinished: 'Заказ на поездку успешно выполнен',
    drivingNoShow: 'Заказ на вождение успешно отмечен как неявка.',
    drivingDeleted: 'приказ о вождении успешно удален',
    drivingAccepted: 'Заказ на вождение успешно принят',
    drivingRejected: 'Заказ на вождение успешно отклонен',
    planChanged: 'План успешно изменен',
    planChangeFailed: 'Изменение плана невозможно',
    saveChangesFirst: 'Сначала сохраните внесенные изменения',
    makeRoutesPublic: 'Маршруты стали общедоступными',
    makeRoutesNotPublic: 'Маршруты больше не общедоступны',
    makePublicLinkActive: 'Публичная ссылка теперь доступна',
    makePublicLinkInactive: 'Публичная ссылка больше недоступна',
    integrationKeyCopied: 'Интеграционный ключ скопирован',
    copyToken: 'Вручную скопируйте токен',
    publicLinkCopied: 'Публичная ссылка скопирована',
    copyPublicLink: 'Скопируйте публичную ссылку вручную',
    organisationChanged: 'Организация успешно изменена',
    changeFailed: 'Изменение не удалось, повторите попытку',
    loginFailed: 'Неправильное имя пользователя или пароль',
    organisationSuspended: 'Ваша организация приостановлена',
    userEmailDoesNotExists: 'Пользователя с этим адресом электронной почты не существует',
    canceledSubscrSuccessfully: 'Подписка успешно отменена',
    toManyRequests: 'Слишком много запросов. Попробуйте снова через минуту.',
    visibleStatusChanged: 'Видимый статус успешно изменен',
    noInternetConnection: 'Нет интернета. Пожалуйста, проверьте ваше интернет-соединение.',
    backOnline: 'Вы снова онлайн.',
    driverNotifiedToTurnGpsOn: 'Водитель уведомлен включить GPS',
    savedSuccessfullyWithGnetError: 'Синхронизация с GNET не удалась, заказ на вождение успешно сохранен.',
    logoutFromAnotherDevice: 'Вы вышли из системы на другом устройстве.',
    stripeEnabled: 'Stripe успешно активирован',
    stripeDisabled: 'Stripe успешно деактивирован',
    finishStripeConnect: 'Пожалуйста, завершите настройку Stripe',
  },
  filters: {
    filter: 'Фильтр',
    currentYear: 'Текущий год',
    lastYear: 'Прошлый год',
    lastQuarter: 'Прошлый квартал',
    lastThreeYears: 'Последние три года',
    filterByDriver: 'Фильтр по водителю',
    filterByVehicle: 'Фильтр по транспортному средству',
    filterByClient: 'Фильтр по клиентам',
    day: 'День',
    week: 'Неделя',
    month: 'Месяц',
    vehicle: 'Транспортное средство',
    driving: 'Вождение',
    user: 'Пользователь',
    fromDate: 'С даты',
    toDate: 'По дату',
    threeMonths: 'Три месяца',
    halfYear: 'Полгода',
    year: 'Год',
  },
  months: {
    jan: 'Январь',
    feb: 'Февраль',
    mar: 'Март',
    apr: 'Апрель',
    may: 'Май',
    jun: 'Июнь',
    jul: 'Июль',
    aug: 'Август',
    sep: 'Сентябрь',
    oct: 'Октябрь',
    nov: 'Ноябрь',
    dec: 'Декабрь',
    January: 'Январь',
    February: 'Февраль',
    March: 'Март',
    April: 'Апрель',
    May: 'Май',
    June: 'Июнь',
    July: 'Июль',
    August: 'Август',
    September: 'Сентябрь',
    October: 'Октябрь',
    November: 'Ноябрь',
    December: 'Декабрь',
  },
  usersPageHeaders: {
    firstName: 'Имя',
    lastName: 'Фамилия',
    username: 'Имя пользователя',
    email: 'Электронная почта',
    phoneNumber: 'Номер телефона',
    role: 'Роль',
    active: 'Активный',
    actions: 'Действия',
  },
  usersRoles: {
    masterAdmin: 'Главный администратор',
    manager: 'Менеджер',
    driver: 'Водитель',
    operator: 'Оператор',
    externalAssociate: 'Внешний сотрудник',
    sales: 'Продажи',
    salesLead: 'Руководитель отдела продаж',
    gnet: 'GNET',
    customer: 'Пользователь портала',
  },
  masterUsersPageHeaders: {
    firstName: 'Имя',
    lastName: 'Фамилия',
    username: 'Имя пользователя',
    email: 'Электронная почта',
    phoneNumber: 'Номер телефона',
    role: 'Роль',
    organisation: 'Организация',
    active: 'Активный',
    actions: 'Действия',
  },
  usersPage: {
    newItem: 'Новая запись пользователя',
    editItem: 'Редактировать пользователя',
    id: 'ID',
    firstName: 'Имя',
    lastName: 'Фамилия',
    username: 'Имя пользователя',
    password: 'Пароль',
    phoneNumber: 'Номер телефона',
    role: 'Роль',
    client: 'Клиент',
    email: 'Электронная почта',
    organisation: 'Организация',
    percentage: 'Процент',
    externalAssociate: 'Внешний сотрудник',
    image: 'Изображение',
    deleteUser: 'Удалить пользователя',
    deleteMessage: 'Вы уверены, что хотите удалить пользователя',
    emailChanged: 'Изменение электронной почты',
    verificationEmailSent:
      'Запрос на изменение адреса электронной почты отправлен. Для завершения процесса и обеспечения безопасности учетной записи пользователя новый адрес электронной почты должен быть подтвержден путем нажатия на ссылку подтверждения, отправленную на новый адрес. Адрес электронной почты не будет изменен до успешного подтверждения.',
  },
  clientsPageHeaders: {
    name: 'Имя',
    phoneNumber: 'Номер телефона',
    address: 'Адрес',
    email: 'Электронная почта',
    companyId: 'Идентификационный номер компании/Личный ID',
    clientType: 'Тип клиента',
    percentage: 'Процент',
    active: 'Активный',
    actions: 'Действия',
  },
  clientsPage: {
    newItem: 'Новая запись клиента',
    editItem: 'Редактировать клиента',
    id: 'ID',
    name: 'Имя',
    address: 'Адрес',
    phoneNumber: 'Номер телефона',
    email: 'Электронная почта',
    companyId: 'Идентификационный номер компании/Личный ID',
    clientType: 'Тип клиента',
    individual: 'Физическое лицо',
    business: 'Юридическое лицо',
    percentage: 'Процент',
    deleteClient: 'Удалить клиента',
    deleteMessage: 'Вы уверены, что хотите удалить клиента',
    tabs: {
      general: 'Общие',
      transfers: 'Переводы',
      users: 'Пользователи',
      invoices: 'Счета',
      cards: 'Карты',
    },
  },
  drivingsHeaders: {
    drivingNumber: 'Номер заказа на поездку',
    drivingType: 'Тип заказа на поездку',
    drivingStatus: 'Статус',
    locationFrom: 'Начальная точка',
    locationTo: 'Место назначения',
    vehicleClass: 'Класс автомобиля',
    vehicle: 'Автомобиль',
    driversName: 'Водитель',
    startTime: 'Время подачи',
    actions: 'Действия',
    exportExcel: 'Экспорт в Excel',
    price: 'Цена',
    totalPrice: 'Общая цена',
    confirmed: 'Подтверждено',
    paid: 'Оплачено',
    numberOfDrivings: 'Количество заказов на поездки',
  },
  vehiclesHeaders: {
    plateNumber: 'Регистрационный номер',
    brand: 'Марка автомобиля',
    vehicleClass: 'Класс автомобиля',
    passengersNumber: 'Количество пассажиров',
    fuelConsumption: 'Расход топлива',
    yearOfManufacture: 'Год выпуска',
    pricePerKm: 'Цена за км',
    pricePerMi: 'Цена за милю',
    pricePerHour: 'Цена в час',
    pricePerWaitingHour: 'Цена за ожидание в час',
    active: 'Активный',
    actions: 'Действия',
  },
  vehiclesPage: {
    id: 'ID',
    newItem: 'Новая запись автомобиля',
    editItem: 'Изменить автомобиль',
    vehiclePlates: 'Регистрационные знаки',
    vehicleBrand: 'Марка автомобиля',
    vehicleClass: 'Класс автомобиля',
    passengersNumber: 'Количество пассажиров',
    fuelConsumption: 'Расход топлива (100 км)',
    fuelConsumptionMi: 'Расход топлива автомобиля (100 миль)',
    manufactureYear: 'Год выпуска',
    price: 'Цена за км',
    priceMi: 'Цена за милю',
    hourPrice: 'Цена в час',
    image: 'Изображение',
    hourWaitingPrice: 'Цена за ожидание в час',
    deleteMessage: 'Вы уверены, что хотите удалить автомобиль?',
    tabs: {
      general: 'Общее',
      transfers: 'Перевозки',
      costs: 'Затраты',
      damages: 'Повреждения',
    },
    licence: 'Номер лицензии',
  },
  vehicleClassesHeaders: {
    vehicleClass: 'Класс автомобиля',
    active: 'Активный',
    actions: 'Действия',
  },
  vehicleClassesPage: {
    newItem: 'Новая запись класса автомобиля',
    editItem: 'Изменить класс автомобиля',
    id: 'ID',
    vehicleClass: 'Класс автомобиля',
    hourWaitingPrice: 'Цена за ожидание в час',
    deleteMessage: 'Вы уверены, что хотите удалить класс автомобиля?',
  },
  expensesHeaders: {
    name: 'Название',
    vehicle: 'Автомобиль',
    type: 'Тип',
    description: 'Описание',
    price: 'Цена',
    expenseDate: 'Дата',
    actions: 'Действия',
    driving: 'Вождение',
    exportExcel: 'Экспорт в Excel',
  },
  vehicleExpensesTypes: {
    minorService: 'Мелкое ТО',
    majorService: 'Капитальное ТО',
    fuel: 'Топливо',
    tires: 'Шины',
    parking: 'Парковка',
    other: 'Другое',
  },
  expensesPage: {
    newItem: 'Новый ввод затрат',
    editItem: 'Изменить затраты',
    id: 'ID',
    name: 'Название',
    vehicle: 'Автомобиль',
    costType: 'Тип затрат',
    price: 'Цена',
    currency: 'Валюта',
    costTime: 'Дата затрат',
    description: 'Описание',
    deleteMessage: 'Вы уверены, что хотите удалить эту запись о затратах?',
    costTypes: {
      'Mali servis': 'Мелкое ТО',
      'Veliki servis': 'Капитальное ТО',
      Gorivo: 'Топливо',
      Gume: 'Шины',
      Parking: 'Парковка',
      Ostalo: 'Другое',
    },
  },
  vehicleMaintenanceHeaders: {
    name: 'Автомобиль',
    details: 'Детали',
    dateFrom: 'Дата с',
    dateTo: 'Дата по',
    actions: 'Действия',
  },
  vehicleMaintenancePage: {
    newItem: 'Новая запись о поломке автомобиля',
    editItem: 'Изменить информацию о поломке автомобиля',
    id: 'ID',
    vehicle: 'Автомобиль',
    description: 'Описание',
    dateFrom: 'Дата начала',
    dateTo: 'Дата окончания',
    deleteMessage: 'Вы уверены, что хотите удалить эту запись о поломке автомобиля?',
  },
  rebatesHeaders: {
    min: 'Минимальное количество заказов',
    max: 'Максимальное количество заказов',
    percentage: 'Скидка (%)',
    actions: 'Действия',
  },
  rebatesPage: {
    newItem: 'Новая запись о скидке',
    editItem: 'Изменить скидку',
    rebateMin: 'Минимальное количество заказов',
    rebateMax: 'Максимальное количество заказов',
    rebate: 'Скидка (%)',
    deleteRebate: 'Удалить скидку',
    deleteMessage: 'Вы уверены, что хотите удалить эту запись о скидке?',
  },
  customerCardsHeaders: {
    cardholderName: 'Имя держателя карты',
    cardNumber: 'Номер карты',
    expiryDate: 'Срок действия',
    cvv: 'CVV',
    actions: 'Действия',
  },
  customerCardsPage: {
    newItem: 'Новая запись карты',
    editItem: 'Изменение карты',
    cardholderName: 'Имя держателя карты',
    cardNumber: 'Номер карты',
    expiryDate: 'Срок действия',
    cvv: 'CVV',
    deleteCard: 'Удалить карту',
    deleteMessage: 'Вы уверены, что хотите удалить эту карту?',
  },
  pricingRegionsHeaders: {
    name: 'Название',
    actions: 'Действия',
  },
  pricingRegionsPage: {
    newItem: 'Новая запись региона цен',
    editItem: 'Изменение региона цен',
    name: 'Название',
    chooseCurrency: 'Выберите валюту',
    team10Price: 'Цена для Команды 10',
    team10PaddlePriceId: 'Идентификатор цены Paddle для Команды 10',
    team25Price: 'Цена для Команды 25',
    team25PaddlePriceId: 'Идентификатор цены Paddle для Команды 25',
    team50Price: 'Цена для Команды 50',
    team50PaddlePriceId: 'Идентификатор цены Paddle для Команды 50',
    chooseCountries: 'Выберите страны',
    description: 'Описание',
    deletePricingRegion: 'Удалить регион цен',
    deleteMessage: 'Вы уверены, что хотите удалить этот регион цен?',
  },
  notificationsHeaders: {
    time: 'Время',
    notification: 'Уведомление',
  },
  organisationsHeaders: {
    name: 'Организация',
    phoneNumber: 'Номер телефона',
    email: 'Электронная почта',
    address: 'Адрес',
    companyId: 'Идентификационный номер компании',
    vat: 'НДС',
    active: 'Активная',
    createdAt: 'Зарегистрирована',
    actions: 'Действия',
    billingDate: 'Дата выставления счета',
  },
  organisationsPage: {
    newItem: 'Новая запись организации',
    editItem: 'Изменить организацию',
    id: 'ID',
    name: 'Организация',
    address: 'Адрес',
    phoneNumber: 'Номер телефона',
    email: 'Электронная почта',
    vat: 'НДС',
    companyId: 'Идентификационный номер компании',
    warrantNumber: 'Номер решения',
    image: 'Изображение',
    signature: 'Подпись',
    seal: 'Печать',
    deactivateMessage: 'Вы уверены, что хотите ',
    deactivate: 'ДЕАКТИВИРОВАТЬ',
    activate: 'АКТИВИРОВАТЬ',
    organisation: 'организацию',
    deleteOrganisation: 'Удалить организацию',
    deleteMessage: 'Вы уверены, что хотите удалить эту организацию?',
    cancelSubscrMessage:
      'Вы уверены, что хотите отменить подписку? Обратите внимание, что подтверждая это действие: 1. Аккаунт вашей организации и все связанные с ним данные будут безвозвратно удалены. Это действие нельзя отменить. 2. Вы будете автоматически выведены из системы сразу после отмены подписки. 3. У вас больше не будет доступа для входа в систему или получения каких-либо данных, связанных с вашей организацией в будущем. Мы настоятельно рекомендуем просмотреть необходимую информацию или экспортировать важные данные перед тем, как приступить к этому необратимому действию. Если у вас есть вопросы или вам нужна помощь, пожалуйста, не стесняйтесь обращаться в нашу службу поддержки. Хотите ли вы продолжить отмену?',
    allDrivings: 'Общее количество заказов',
    allDrivingsAccepted: 'Общее количество принятых заказов',
    allDrivingsCanceled: 'Общее количество отклоненных заказов',
    allVehicles: 'Общее количество автомобилей',
    allUsers: 'Общее количество пользователей',
    areYouSure: 'Вы уверены?',
    makeRoutesAvailable: 'Сделать маршруты доступными для интеграции',
    percentage: 'Процент',
    deleteImageMessage: 'Вы уверены, что хотите удалить это изображение?',
    deleteImageTitle: 'Удалить изображение',
    cancelSubscriptionTitle: 'Отменить подписку',
    subscriptionType: 'Тип подписки',
    billingDate: 'Дата выставления счета',
    transactions: 'Транзакции',
    articlesOfLaw: 'Статьи закона',
    country: 'Страна',
    referent: 'Референт',
    language: 'Язык',
    gnet: 'GNET',
  },
  transactionsHeaders: {
    invoiceNumber: 'Номер счета',
    status: 'Статус',
    total: 'Итого',
    currency: 'Валюта',
    billedAt: 'Дата выставления',
    actions: 'Действия',
  },
  transactionsPage: {
    newItem: 'Новая запись транзакции',
    editItem: 'Редактировать транзакцию',
    id: 'ID',
    invoiceNumber: 'Номер счета',
    status: 'Статус',
    sent: 'Отправлено',
    completed: 'Завершено',
    total: 'Итого',
    currency: 'Валюта',
    billedAt: 'Дата выставления',
    actions: 'Действия',
    deleteTransaction: 'Удалить транзакцию',
    deleteMessage: 'Вы уверены, что хотите удалить эту транзакцию?',
    currentSubscription: 'Текущий пакет',
    nextBillingDate: 'Дата следующего счета',
    numberOfSeatsTaken: 'Количество занятых мест в команде',
    numberOfSeatsAvailable: 'Количество доступных мест в команде',
  },
  currenciesHeaders: {
    name: 'Название',
    code: 'Код',
    symbol: 'Символ',
    active: 'Активная',
    default: 'Основная',
    actions: 'Действия',
  },
  currenciesPage: {
    newItem: 'Новая запись валюты',
    editItem: 'Изменить валюту',
    id: 'ID',
    currency: 'Валюта',
    code: 'Код',
    symbol: 'Символ',
    deleteMessage: 'Вы уверены, что хотите удалить эту валюту?',
  },
  paymentMethodsHeaders: {
    name: 'Название',
    active: 'Активный',
    default: 'Основной',
    priceVisible: 'Цена видима',
    actions: 'Действия',
  },
  paymentMethodsPage: {
    newItem: 'Новый способ оплаты',
    editItem: 'Изменить способ оплаты',
    id: 'ID',
    paymentMethod: 'Способ оплаты',
    deleteMessage: 'Вы уверены, что хотите удалить этот способ оплаты?',
  },
  feedbacksHeaders: {
    subject: 'Тема',
    organisation: 'Организация',
    user: 'Пользователь',
    actions: 'Действия',
  },
  passengersHeaders: {
    firstName: 'Имя',
    lastName: 'Фамилия',
    phoneNumber: 'Номер телефона',
    email: 'Электронная почта',
    passport: 'Паспорт',
    nationality: 'Национальность',
    actions: 'Действия',
  },
  checkpointsHeaders: {
    checkpointLocation: 'Местоположение контрольной точки',
    arrivalTime: 'Время прибытия',
    actions: 'Действия',
  },
  tables: {
    noDataText: 'Нет данных',
    search: 'Поиск',
    drivings: 'Заказы на поездки',
    drivingsByPage: 'Заказы на поездки на странице',
    vehicles: 'Транспортные средства',
    vehiclesByPage: 'Транспортные средства на странице',
    vehiclesClasses: 'Классы автомобилей',
    vehiclesClassesByPage: 'Классы автомобилей на странице',
    expenses: 'Расходы',
    expensesByPage: 'Затраты на страницу',
    vehiclesMaintenance: 'Техническое обслуживание транспортных средств',
    vehiclesMaintenanceByPage: 'Техническое обслуживание транспортных средств на странице',
    reportsByPage: 'Отчеты на странице',
    currencies: 'Валюты',
    currencieseByPage: 'Валюты на странице',
    payments: 'Способы оплаты',
    paymentsByPage: 'Способы оплаты на странице',
    saasAdministrators: 'Все администраторы',
    users: 'Все пользователи',
    usersByPage: 'Пользователи на странице',
    clients: 'Все клиенты',
    clientsByPage: 'Клиенты на странице',
    organisations: 'Все организации',
    organisationsByPage: 'Организации на странице',
    rebates: 'Скидки',
    rebatesByPage: 'Скидки на странице',
    cards: 'Карты',
    cardsByPage: 'Карты на странице',
    transactions: 'Транзакции',
    transactionsByPage: 'Транзакции на странице',
    pricingRegions: 'Ценовые регионы',
    pricingRegionsByPage: 'Ценовые регионы по странице',
    feedback: 'Отзывы',
    feedbacksByPage: 'Отзывы на странице',
    notifications: 'Уведомления',
    notificationsByPage: 'Уведомления на странице',
    all: 'Все',
    passengers: 'Пассажиры',
    passengersByPage: 'Пассажиры на странице',
    of: 'из',
    checkpoints: 'Путевые точки',
    checkpointsByPage: 'Путевые точки на странице',
    invoices: 'Счета',
    invoicesByPage: 'Счета по странице',
    drivingOffers: 'Предложения по вождению',
    drivingOffersByPage: 'Предложения по вождению на странице',
    smsMessages: 'SMS-сообщения',
    smsMessagesByPage: 'SMS-сообщения на странице',
    resourcesUsed: 'Использованные ресурсы',
    resourcesUsedByPage: 'Ресурсы по страницам',
    inNext3Days: 'В течение следующих 3 дней',
    notConfirmedOrPaid: 'Не подтверждено или не оплачено',
    inNext5Days: 'В течение следующих 5 дней',
    latestReviews: 'Последние отзывы',
    driversByPage: 'Водители на странице',
    reviewsByPage: 'Отзывы на странице',
  },
  statistics: {
    numberOfAcceptedDrivings: 'Количество принятых заказов на поездку',
    numberOfRejectedDrivings: 'Количество отклоненных заказов на поездку для водителя',
    vehicleDrivingsNumber: 'Количество заказов на поездку на транспортное средство',
    vehicleProfit: 'Заработок на транспортном средстве',
    monthDrivingsNumber: 'Количество заказов на поездку в месяц',
    driverProfit: 'Заработок водителя',
  },
  reportsHeaders: {
    vehicle: 'Транспортное средство',
    expenses: 'Расходы',
    profit: 'Прибыль',
    earnings: 'Заработок',
    daysWorking: 'Рабочие дни',
    usageIndex: 'Индекс использования',
    client: 'Клиент',
    numberOfDrivings: 'Количество Поездок',
    percentForClient: 'Цена для клиента',
    sumOfPrice: 'Количество',
  },
  packages: {
    active: 'Активный',
    starterTitle: 'Стартовый',
    monthlyPackagesMessage: 'Ежемесячная подписка на пользователя.',
    starterText:
      'Идеально подходит для физических лиц или небольших команд, которым нужна только базовая функциональность.',
    starterListItemOne: 'Мобильные приложения',
    starterListItemTwo: 'Уведомления push',
    starterListItemThree: 'Доступ к администрации',
    starterListItemFour: 'Интеграция с веб-сайтом',
    standardTitle: 'Стандартный',
    standardText: 'Отлично подходит для организаций с более чем 5 водителями.',
    standardListItemOne: 'Пакет STARTER включен',
    standardListItemTwo: 'Нет ограничения на количество водителей',
    standardListItemThree: 'Доступность 99,9%',
    standardListItemFour: 'Отчеты и управление транспортными средствами',
    premiumTitle: 'Премиум',
    premiumText: 'Идеально подходит для организаций лимузинского обслуживания, работающих с точными данными.',
    premiumListItemOne: 'Пакет STANDARD включен',
    premiumListItemTwo: 'Бизнес-интеллект и прогнозирование расходов',
    premiumListItemThree: 'Экспорт данных',
    premiumListItemFour: 'Еженедельные и ежемесячные отчеты',
    warningOne: '* Показанные цены - ежемесячная подписка на пользователя',
    warningTwo:
      '* После перехода на новый пакет нельзя переключиться на более маленький пакет в течение следующих 6 месяцев',
    warningThree:
      '* Пользователи демонстрационного плана имеют доступ к функциональности, предоставляемой премиальным пакетом',
    warningOneAnnualPackage: 'Нужно больше? У вас есть большая организация? У нас есть пакет ПРЕДПРИЯТИЕ для вас.',
    warningTwoAnnualPackage: 'и наша команда по продажам свяжется с вами в ближайшее время.',
    packageChange: 'Сменить пакет',
    changePackage: 'Вы уверены, что хотите перейти на',
    package: 'пакет',
    annualPricing: 'Годовая ценовая политика',
    annualPackageType10: 'Команда 10',
    annualPackageType25: 'Команда 25',
    annualPackageType50: 'Команда 50',
    'Team 10': 'Команда 10',
    'Team 25': 'Команда 25',
    'Team 50': 'Команда 50',
    annualPackagesMessage: 'Годовая подписка на организацию.',
    annualPackageType10MainMessage: 'До 10 учетных записей пользователя.',
    annualPackageType25MainMessage: 'До 25 учетных записей пользователя.',
    annualPackageType50MainMessage: 'До 50 учетных записей пользователя.',
    annualPackageListItemOne: 'Пакет Стандарт',
    annualPackageListItemTwo: 'Одноразовый платеж',
    annualPackageListItemThree: 'Преимущества цены',
    annualPackageType10Discount: '- до 65% скидка',
    annualPackageType25Discount: '- до 68% скидка',
    annualPackageType50Discount: '- до 65% скидка',
    allFeaturesIncluded: 'Все функции включены',
    trialPeriodIncluded: 'Пробный период включен',
    unlimitedVehicles: 'Неограниченное количество автомобилей',
    unlimitedBookings: 'Неограниченное количество бронирований',
    onetimePaymentAnnually: 'Однократная годовая оплата',
  },
  successPaymentPage: {
    message: 'Оплата прошла успешно. Спасибо.',
  },
  demoExpiring: {
    notification: 'Уведомление',
    expiredSubscriptionMessage:
      'Мы информируем вас о том, что ваша подписка на эту платформу истекла. Чтобы продолжить пользоваться нашими услугами, пожалуйста, обновите свою подписку.',
  },
  login: {
    username: 'Имя пользователя',
    password: 'Пароль',
    rememberMe: 'Запомнить меня',
    login: 'Войти',
    noAccount: 'Еще нет аккаунта? Вы можете зарегистрироваться',
    forgetPassword: 'Забыли пароль? Вы можете сбросить его',
    registerHere: 'Здесь',
    email: 'Email',
    enterEmail: 'Пожалуйста, введите ваш email',
    enterPassword: 'Пожалуйста, введите ваш новый пароль',
    signInWithGoogle: 'Войти с помощью Google',
  },
  registration: {
    register: 'Регистрация',
    name: 'Название организации',
    address: 'Адрес',
    phoneNumber: 'Номер телефона',
    email: 'Электронная почта',
    companyId: 'Идентификатор компании',
    vat: 'НДС',
    language: 'Язык',
    package: 'Пакет',
    accept: 'Я принимаю',
    terms: 'условия использования',
    successfullyRegistered:
      'Пользователь успешно зарегистрирован. Вы получите электронное письмо с ссылкой для подтверждения.',
    haveAccount: 'У вас уже есть аккаунт? Вы можете войти',
    loginHere: 'Здесь',
    trialPeriodInfo:
      'Включен бесплатный пробный период 14 дней. Оплата будет осуществляться после окончания испытательного периода.',
  },
  driving: {
    drivingType: 'Тип заказа',
    general: 'Общая информация',
    newDriving: 'Запланировать трансфер',
    newDailyRent: 'Запланировать аренду на день',
    areYouSure: 'Вы уверены?',
    vehicleIsUnavailable: 'Транспортное средство занято.',
    vehicleIsDamaged: 'Транспортное средство повреждено.',
    sureAboutThisVehicle: 'Вы уверены, что хотите выбрать это транспортное средство?',
    driverUnavaliable: 'Водитель занят.',
    sureAboutThisDriver: 'Вы уверены, что хотите выбрать этого водителя?',
    checkVehicleSeatAvailability: 'Это транспортное средство не может вместить такое количество пассажиров.',
    cancelDriving: 'Вы уверены, что хотите отменить заказ на поездку?',
    finishDriving: 'Вы уверены, что хотите завершить заказ на поездку?',
    noShowDriving: 'Вы уверены, что хотите отметить заказ на вождение как неявку?',
    deleteDriving: 'Вы уверены, что хотите удалить заказ на поездку?',
    newTransfer: 'Новый трансфер',
    driving: 'Заказ на поездку',
    comments: 'Комментарии',
    id: 'ID',
    locationFrom: 'Пункт отправления',
    locationTo: 'Пункт назначения',
    vehicleClass: 'Класс транспортного средства',
    vehicle: 'Транспортное средство',
    driver: 'Водитель',
    pickupTime: 'Время подачи',
    clientConfirmed: 'Клиент подтвердил',
    description: 'Описание',
    extraInfo: 'Дополнительная информация',
    drivingNotAccepted: 'Заказ на поездку не был принят:',
    currency: 'Валюта',
    paymentMethod: 'Способы оплаты',
    price: 'Цена',
    paid: 'Оплачено',
    distance: 'Расстояние (км)',
    distanceMi: 'Расстояние (миль)',
    expectedTime: 'Ожидаемая продолжительность',
    waitingTime: 'Время ожидания (ч)',
    EnterTheNumberOfHoursWaiting: 'Введите количество часов ожидания',
    dropOffTime: 'Время прибытия',
    comebackTime: 'Время возврата',
    acceptUntil: 'Принять до',
    drivingRequired: 'Обязателен заказ на поездку',
    comment: 'Комментарий',
    attachments: 'Вложения',
    addFiles: '+ Добавить файлы',
    deleteFiles: 'Вы уверены, что хотите удалить этот файл?',
    passengers: 'Пассажиры',
    addPassenger: '+ Добавить пассажира',
    deletePassenger: 'Вы уверены, что хотите удалить этого пассажира?',
    chooseClient: 'Выберите клиента',
    costs: 'Затраты',
    checkpoints: 'Путевые точки',
    addCheckpoint: 'Добавить путевую точку',
    deleteCheckpoint: 'Вы уверены, что хотите удалить эту путевую точку?',
    waitingBoardText: 'Текст ожидания на доске',
    flightNumber: 'Номер рейса',
    flightInfo: 'Информация о рейсе',
    createdBy: 'Заказ на вождение создан в <b>{time}</b> пользователем <b>{user}</b>',
    client: 'Клиент',
    note: 'Заметка',
    noteDriver: 'Заметка для водителя',
    noteDispatcher: 'Заметка для диспетчера',
    passenger: 'Пассажир',
    fullName: 'Полное имя',
    phoneNumber: 'Номер телефона',
    email: 'Электронная почта',
    suitcasesNumber: 'Количество чемоданов',
    passengersNumber: 'Количество пассажиров',
    babySeatsNumber: 'Количество детских сидений',
    stopsNumber: 'Количество остановок',
    steps: {
      mainInfo: 'Основная информация',
      additionalInfo: 'Дополнительная информация',
      review: 'Обзор',
    },
    commissionAmount: 'Комиссия',
  },
  drivingStatus: {
    pending: 'В ожидании',
    accepted: 'Принято',
    rejected: 'Отклонено',
    expired: 'Истекло',
    canceled: 'Отменено',
    draft: 'Черновик',
    done: 'Завершено',
    noShow: 'Неявка',
  },
  drivingOrderType: {
    dailyRent: 'Аренда на день',
    transfer: 'Трансфер',
  },
  flightInfo: {
    airlineName: 'Название авиакомпании',
    flightNumber: 'Номер рейса',
    departureAirport: 'Аэропорт отправления',
    departureCountry: 'Страна отправления',
    departureTime: 'Время отправления',
    arrivalAirport: 'Аэропорт прибытия',
    arrivalCountry: 'Страна прибытия',
    arrivalTime: 'Время прибытия',
    departureDelayed: 'Задержка вылета',
    flightStatus: 'Статус рейса',
    percentageOfFlight: 'Процент выполнения полета',
  },
  customValidations: {
    fileType: 'Тип файла .{extension} не поддерживается',
    fileSize: 'Файлы не могут быть больше {size}',
    fieldRequired: 'Поле {field} обязательно для заполнения',
    fieldType: 'Поле {field} должно быть типа {type}',
    fieldLengthMin: 'Поле {field} должно быть не менее {length}',
    fieldLengthMax: 'Поле {field} должно быть не более {length}',
    fieldUnique: 'Поле {field} должно быть уникальным',
    fieldValid: 'Поле {field} должно быть действительным',
    noActiveSubscription: 'Активная подписка не найдена',
    usersLimitExceeded: 'Превышен лимит пользователей',
    fieldBefore: '{field1} должно быть перед {field2}',
    flightNotExist: 'Рейс {field} не существует',
    gnetKeyNotValid: 'Указанный ключ Gnet недействителен.',
    gnetPassengersRequired: 'Заказ на поездку не может быть отправлен в GNET без пассажиров.',
    priceAndCurrencyRequired: 'Ссылка для оплаты не может быть создана без указания цены и валюты',
    fieldValueNotSupported: 'Значение поля {field} не поддерживается',
    noCountryCode: 'Вы не выбрали страну',
    noStripeForYourCountry: 'Stripe недоступен в вашей стране',
    stripeAlreadyEnabled: 'Stripe уже активирован',
    fieldValueMin: 'Поле {field} должно быть {min} или больше',
    fieldValueMax: 'Поле {field} должно быть {max} или меньше',
  },
  fieldTypes: {
    string: 'строка',
    integer: 'число',
    date: 'дата',
  },
  names: {
    username: 'имя пользователя',
    password: 'пароль',
    choose_client: 'выберите клиента',
    choose_month: 'выберите месяц',
    currency: 'валюта',
    payment_method: 'способ оплаты',
    vehicle_class: 'класс транспортного средства',
    first_name: 'имя',
    last_name: 'фамилия',
    passport: 'паспорт',
    nationality: 'национальность',
    plate_number: 'государственный номер',
    brand: 'марка автомобиля',
    number_of_passengers: 'количество пассажиров',
    fuel_consumption: 'расход топлива',
    year_of_manufacture: 'год выпуска',
    price_per_km: 'цена за км',
    price_per_mi: 'цена за милю',
    price_per_hour: 'цена в час',
    price_per_waiting_hour: 'цена за час ожидания',
    vehicle: 'транспортное средство',
    description: 'описание',
    time_from: 'время с',
    time_to: 'время до',
    name: 'имя',
    cost_type: 'тип расхода',
    cost_time: 'время расхода',
    price: 'цена',
    rebate_min: 'минимальное количество заказов',
    rebate_max: 'максимальное количество заказов',
    rebate: 'скидка',
    phone_number: 'номер телефона',
    organisation: 'организация',
    role: 'роль',
    image: 'изображение',
    location_from: 'место отправления',
    location_to: 'место назначения',
    pickup_time: 'время подачи',
    distance: 'расстояние',
    waiting_time: 'время ожидания',
    comment: 'комментарий',
    address: 'адрес',
    vat: 'НДС',
    company_id: 'идентификационный номер компании',
    company_personal_id: 'id компании/личный id',
    licence: 'лицензия',
    warrant_number: 'номер решения',
    seal: 'печать',
    signature: 'подпись',
    email: 'электронная почта',
    percentage: 'процент',
    organizationName: 'название организации',
    package: 'пакет',
    choose_currency: 'выберите валюту',
    team_10_price: 'цена за команду 10',
    team_10_paddle_price_id: 'ID цены Paddle для команды 10',
    team_25_price: 'цена за команду 25',
    team_25_paddle_price_id: 'ID цены Paddle для команды 25',
    team_50_price: 'цена за команду 50',
    team_50_paddle_price_id: 'ID цены Paddle для команды 50',
    countries: 'страны',
    invoice_number: 'номер счета',
    status: 'статус',
    total: 'итого',
    billed_at: 'дата выставления',
    articles_of_law: 'статьи закона',
    country: 'страна',
    referent: 'референт',
    language: 'язык',
    location: 'местоположение',
    arrival_time: 'время прибытия',
    waiting_board_text: 'текст ожидания на доске',
    flight_number: 'номер рейса',
    gnet_id: 'GNET ID',
    receiver_id: 'ID получателя',
    preferred_vehicle_type: 'предпочтительный тип транспортного средства',
    reservation_type: 'тип бронирования',
    run_type: 'тип пробега',
    trip_duration_minutes: 'продолжительность поездки',
    client: 'клиент',
    client_type: 'тип клиента',
    card_holder_name: 'имя держателя карты',
    card_number: 'номер карты',
    expiry_date: 'срок действия',
    cvv: 'cvv',
    code: 'код',
    symbol: 'символ',
    quantity: 'количество',
    discount: 'скидка',
    driving_type: 'тип вождения',
    full_name: 'полное имя',
    suitcases_number: 'количество чемоданов',
    passengers_number: 'количество пассажиров',
    baby_seats_number: 'количество детских сидений',
    stops_number: 'количество остановок',
    commission_amount: 'комиссия',
  },
  welcomeModal: {
    welcome: 'Добро пожаловать в LimoExpress',
  },
  weekDays: {
    monday: 'Понедельник',
    tuesday: 'Вторник',
    wednesday: 'Среда',
    thursday: 'Четверг',
    friday: 'Пятница',
    saturday: 'Суббота',
    sunday: 'Воскресенье',
  },
  select: {
    noDataAvailable: 'Нет доступных данных',
  },
  excelExport: {
    to_date_range: 'Отчет не может быть сгенерирован для будущих дат. Пожалуйста, введите корректные даты',
    overlap_range: 'Время окончания не может быть больше времени начала.',
  },
  setPassword: {
    success: 'Письмо отправлено',
    checkEmail: 'Проверьте вашу почту для получения дальнейших инструкций.',
    already_changed: 'Уже изменено',
    send_new_reset_request: 'Вы уже меняли пароль с этой ссылкой. Отправьте новый запрос на сброс пароля.',
  },
  statisticsPage: {
    reportType: 'Тип отчета',
    reportTime: 'Время отчета',
    vehicleUtilization: 'Использование транспортных средств',
    costEffectiveness: 'Эффективность затрат',
    generalReport: 'Общий отчет',
    clientsReport: 'Отчет по Клиентам',
  },
  profile: {
    yourProfile: 'Ваш профиль',
    name: 'Имя',
    role: 'Роль',
    username: 'Имя пользователя',
    password: 'Пароль',
    phoneNumber: 'Номер телефона',
    email: 'Электронная почта',
    percentage: 'Процент',
  },
  gpsMaps: {
    noTransfers: 'Нет текущих или предстоящих переводов.',
    map: 'Карта',
    transfers: 'Переводы',
    drivers: 'Водители',
    ongoing: 'Текущие',
    upcoming: 'Предстоящие',
    driving: 'Вождение',
    driver: 'Водитель',
    vehicle: 'Транспортное средство',
    requestDriverLocation: 'Запрос местоположения водителя',
    lastRecordedLocation: 'Последнее зафиксированное местоположение',
  },
  driverStatus: {
    available: 'Доступен',
    offline: 'Не в сети',
    inTransfer: 'В процессе передачи',
  },
  onboardingTutorialMain: {
    languageStep: 'Здесь вы можете установить предпочитаемый язык для приложения.',
    notificationsStep: 'Это ваши уведомления.',
    addTransferStep:
      'Нажмите эту кнопку, чтобы создать ваши трансферы и ежедневные аренды. Ежедневные аренды - это настраиваемые трансферы без указания места назначения.',
    sideMenuButtonStep: 'Нажмите здесь, чтобы показать или скрыть боковое меню.',
    sideMenuExpStep:
      'Отсюда вы можете перейти к основным разделам приложения: Трансферы, Парк автомобилей, Затраты, Администрирование, Статистика и Транзакции.',
    sideMenuButtonCloseStep: 'Нажмите снова на значок, чтобы скрыть боковое меню.',
    supportStep:
      'Если вам нужна помощь с какой-либо проблемой, не стесняйтесь обращаться в службу поддержки, нажав здесь.',
  },
  onboardingTutorialVehicles: {
    tableStep: 'Ваши автомобили будут перечислены здесь в таблице после их добавления.',
    searchStep: 'Вы можете легко найти автомобили, выполнив прямой поиск.',
    addStep:
      "Добавьте новые автомобили с помощью кнопки 'Добавить'. Обратите внимание, что перед использованием этой функции вы должны добавить 'Классы автомобилей'.",
  },
  onboardingTutorialStatistics: {
    typeStep:
      'В этом разделе вы можете найти все ваши статистические данные, отображаемые в графиках. Выберите тип статистики здесь.',
    intervalStep: 'Добавьте интервал, для которого вы хотите просмотреть статистику.',
    downloadStep: 'Вы также можете загрузить и распечатать эти таблицы.',
  },
  onboardingTutorialGps: {
    tabOneStep:
      'В модуле GPS вы можете отслеживать своих водителей и трансферы в одном месте. Выберите эту вкладку, чтобы просмотреть текущие и будущие трансферы.',
    tabTwoStep:
      'Выберите автомобили, чтобы увидеть их на карте. Вы можете добавить свободные автомобили прямо отсюда в трансферы.',
    mapStep: 'Выберите или отмените выбор автомобилей, щелкнув по их метке на карте.',
  },
  advancedSettings: {
    advancedSettings: 'Расширенные настройки',
    distanceUnit: 'Единица измерения расстояния',
    distanceUnitDescription: 'Выберите единицу измерения расстояния.',
    kilometers: 'Километры',
    miles: 'Мили',
    dateFormat: 'Формат даты',
    dateFormatDescription: 'Выберите предпочитаемый формат даты.',
    ddmmyyyy: 'DD-MM-YYYY',
    mmddyyyy: 'MM-DD-YYYY',
    mandatoryDrivings: 'Обязательные поездки',
    mandatoryDrivingsDescription: 'Выберите, если хотите сделать все поездки обязательными по умолчанию.',
    mandatoryDrivingsDefault: 'Обязательные Поездки (По умолчанию)',
    paidDrivings: 'Платные заказы на поездку',
    paidDrivingsDescription: 'Отметьте, чтобы сделать все заказы на поездку платными по умолчанию.',
    paidDrivingsDefault: 'Платные заказы на поездку (По умолчанию)',
    notificationsEmail: 'Электронная почта для уведомлений',
    notificationsEmailDescription:
      'Измените адрес электронной почты по умолчанию для получения уведомлений (если вы не хотите использовать адрес электронной почты вашей организации).',
    email: 'Электронная почта',
    articlesOfLaw: 'Статьи закона',
    integrations: 'Интеграции',
    gnetId: 'GNET ID',
    gnetDescription:
      'GNET — это комплексная платформа, предназначенная для оптимизации отслеживания в режиме реального времени, управления парком и диспетчерских операций в транспортной отрасли.',
    makeRoutesAvailable: 'Сделать маршруты доступными для интеграции',
    makeRoutesAvailableDescription:
      'Активируйте свой API-ключ, чтобы интегрировать LimoExpress с вашим веб-сайтом, ERP-системой и другими системами.',
    makePublicLinkAvailable: 'Сделать публичную ссылку доступной',
    makePublicLinkAvailableDescription:
      'Активируйте ссылку для доступа к публичной странице бронирования, чтобы ваши клиенты могли получить доступ к расширенной форме бронирования и легко создавать учетные записи. Вы можете использовать ссылку в рассылках, на веб-сайте, флаерах; это уникальная ссылка только для вашей компании.',
    emailsEnabled: 'Включенные электронные письма',
    emailsEnabledDefault: 'Включенные электронные письма (По умолчанию)',
    emailsEnabledDescription: 'Отметьте, чтобы получать уведомления по электронной почте.',
    passengerReviewsEnabled: 'Отзывы пассажиров включены',
    passengerReviewsEnabledDefault: 'Отзывы пассажиров включены',
    passengerReviewsEnabledDescription:
      'Активировав эту функцию, ваши пассажиры (если введен email) получат подтверждение по электронной почте после завершения поездки (отмечена как Завершена) и смогут оставить отзыв о ваших услугах.',
    vat: 'НДС',
    vatPercentage: 'Процент НДС',
    vatPercentageDescription: 'Введите процент НДС.',
    invoicePaymentInstructions: 'Инструкции по оплате счета',
    stripe: 'Stripe',
    stripeDescription:
      'Активируйте Stripe, чтобы иметь возможность взимать плату с клиентов за свои услуги. LimoExpress не берет комиссию.',
  },
  drivingExcelCheckboxes: {
    drivingNumber: 'Номер заказа',
    drivingType: 'Тип заказа',
    drivingStatus: 'Статус',
    locationFrom: 'Место отправления',
    locationTo: 'Пункт назначения',
    driver: 'Водитель',
    client: 'Клиент',
    plateNumber: 'Регистрационный номер',
    note: 'Описание',
    fuelConsumption: 'Расход топлива',
    pickupTime: 'Время отправления',
    numberOfPassengers: 'Количество пассажиров',
    passengers: 'Пассажиры',
    price: 'Цена',
    paid: 'Оплачено',
    paymentMethod: 'Метод оплаты',
    waitingTime: 'Время ожидания (ч)',
    distance: 'Расстояние (км)',
    distanceMi: 'Расстояние (миль)',
    selectAll: 'Выбрать все',
  },
  gnet: {
    gnetInfo: 'Информация GNET',
    requesterId: 'ID запросившего',
    receiverId: 'ID получателя',
    preferredVehicleType: 'Предпочтительный тип автомобиля',
    reservationType: 'Тип бронирования',
    runType: 'Тип поездки',
    price: 'Цена',
    currency: 'Валюта',
    noteDriver: 'Заметка для водителя',
    noteDispatcher: 'Заметка для диспетчера',
    acceptDriving: 'Вы уверены, что хотите принять заказ на вождение?',
    rejectDriving: 'Вы уверены, что хотите отклонить заказ на вождение?',
    tripDurationMinutes: 'Продолжительность поездки (минуты)',
  },
  invoicesHeaders: {
    invoiceNumber: 'Номер счета',
    client: 'Клиент',
    totalPrice: 'Общая стоимость',
    drivings: 'Поездки',
    paid: 'Оплачено',
    currency: 'Валюта',
    createdAt: 'Создано',
    actions: 'Действия',
  },
  invoicesPage: {
    newItem: 'Новая запись счета',
    chooseClient: 'Выберите клиента',
    chooseDrivings: 'Выберите поездки',
    deleteMessage: 'Вы уверены, что хотите удалить этот счет?',
  },
  drivingOffersHeaders: {
    number: 'Номер предложения по вождению',
    totalPrice: 'Общая стоимость',
    description: 'Описание',
    paid: 'Оплачено',
    currency: 'Валюта',
    createdAt: 'Создано',
    actions: 'Действия',
  },
  drivingOffersPage: {
    newItem: 'Новая запись предложения по вождению',
    item: 'Позиция',
    description: 'Описание',
    title: 'Заголовок',
    vehicleClass: 'Класс транспортного средства',
    quantity: 'Количество',
    price: 'Цена',
    discount: 'Скидка',
    vat: 'НДС',
    deleteMessage: 'Вы уверены, что хотите удалить это предложение по вождению?',
  },
  modal: {
    reservationSuccessTitle: 'Успех',
    reservationSuccessMessage: 'Запрос на бронирование успешно отправлен. Кто-то свяжется с вами в ближайшее время.',
  },
  dashboardPage: {
    drivingCountToday: 'Количество заказов на вождение (сегодня)',
    drivingCountThisMonth: 'Количество заказов на вождение (за последние 30 дней)',
    dailyRentsAndTransfers: 'Количество заказов на вождение (ежедневная аренда/трансферы)',
    drivingTotalAmount: 'Общая сумма',
    driversCount: 'Активные водители (±7 дней)',
    passengerCount: 'Количество пассажиров (за последние 30 дней)',
  },
  reviewsHeaders: {
    rating: 'Рейтинг',
    comment: 'Комментарий',
  },
  resourceOverviewHeaders: {
    actionType: 'Тип действия',
    user: 'Пользователь',
    action: 'Действие',
    createdAt: 'Создано',
  },
  resourceOverviewPage: {
    smsMessagesLeft: 'Количество оставшихся SMS-сообщений',
    flightsCheckLeft: 'Количество оставшихся проверок рейсов',
    sms: 'SMS',
    flightCheck: 'Проверка рейса',
  },
};
