import { getResourceData, getAuditData } from '@/api/resources';

export default {
  namespaced: true,
  mutations: {},
  actions: {
    async getResourceData(state, item) {
      return getResourceData(item);
    },
    async getAuditData(state, item) {
      return getAuditData(item);
    },
  },
  getters: {},
};
