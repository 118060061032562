export default {
  sidebar: {
    fleet: 'Flota',
    vehicles: 'Vehículos',
    vehiclesClasses: 'Clases de vehículos',
    administration: 'Administración',
    currencies: 'Monedas',
    currenciesAdministration: 'Administración de monedas',
    paymentTypes: 'Métodos de pago',
    paymentTypesAdministration: 'Administración de métodos de pago',
    users: 'Usuarios',
    usersAdministration: 'Administración de usuarios',
    clients: 'Clientes',
    clientsAdministration: 'Administración de clientes',
    organizations: 'Organizaciones',
    organizationsAdministration: 'Administración de organizaciones',
    saasAdmin: 'Admin Saas',
    administrators: 'Administradores',
    saasAdminAdministration: 'Administración de Admin Saas',
    statistics: 'Estadísticas',
    generalOverview: 'Resumen general',
    payment: 'Pago',
    rebates: 'Descuentos',
    pricingRegions: 'Regiones de precios',
    pricingRegionsAdministration: 'Administración de regiones de precios',
    help: 'Ayuda',
    drivings: 'Órdenes de manejo',
    calendar: 'Calendario',
    tabularView: 'Vista tabular',
    expenses: 'Costos',
    vehicleExpensesAdministration: 'Administración de gastos de vehículos',
    vehicleMaintenance: 'Mantenimiento de vehículos',
    vehicleMaintenanceAdministration: 'Administración de mantenimiento de vehículos',
    organization: 'Organización',
    organizationAdminstration: 'Administración de organización',
    packages: 'Paquetes',
    packagesChange: 'Cambiar paquetes',
    logout: 'Cerrar sesión del sistema',
    logoutText: '¿Estás seguro de que deseas cerrar sesión del sistema?',
    unaccessibleRoutes: 'No puedes acceder a esta página. Actualiza el paquete para tener acceso completo.',
    transactions: 'Transacciones',
    gpsMap: 'Mapa GPS',
    team: 'Equipo',
    teamAdministration: 'Administración del equipo',
    invoices: 'Facturas',
    invoicesAdministration: 'Administración de facturas',
    drivingOffers: 'Ofertas',
    drivingOffersAdministration: 'Administración de ofertas',
    dashboard: 'Tablero',
    dashboardAdministration: 'Administración del tablero',
    resourceOverview: 'Visión general de los recursos',
    resourceOverviewAdministration: 'Administración de la visión general de los recursos',
    rides: 'Viajes',
  },
  header: {
    organization: 'Organización',
    newDriving: 'Orden de manejo',
    dailyRent: 'Alquiler diario',
    transfer: 'Transferencia',
    notifications: 'Notificaciones',
    changeLanguage: 'Cambiar idioma',
  },
  footer: {
    poweredBy: 'Desarrollado por',
    subscriptionTo: 'Tu suscripción a',
    trialEndsAt: 'Su período de prueba para',
    packageExpiresIn: 'caduca en el paquete',
    clickToChangePackage: 'Haz clic para cambiar el paquete',
    clickToActivatePackage: 'Haz clic para activar el paquete',
    clickToPay: 'Haz clic para pagar',
    packageHasExpired: 'paquete ha caducado',
  },
  buttons: {
    ok: 'Aceptar',
    yes: 'Sí',
    no: 'No',
    save: 'Guardar',
    send: 'Enviar',
    sendMessage: 'Enviar mensaje',
    edit: 'Editar',
    add: 'Agregar',
    confirm: 'Confirmar',
    cancel: 'Cancelar',
    cancelDriving: 'Cancelar Orden de Manejo',
    markAsDone: 'Marcar como completado',
    markAsNoShow: 'Marcar como no presentado',
    deleteDriving: 'Eliminar Orden de Manejo',
    newOrganisation: 'Nueva organización',
    changePackage: 'Cambiar paquetes',
    downloadStats: 'Descargar estadísticas',
    downloadPDF: 'Descargar PDF',
    today: 'Hoy',
    choose: 'Elegir',
    login: 'Iniciar sesión',
    generateFile: 'Generar contrato',
    generateDrivingOrder: 'Imprimir orden de manejo',
    downloadFile: 'Descargar archivo',
    activate: 'Activar',
    deactivate: 'Desactivar',
    showImage: 'Mostrar imagen',
    showDetails: 'Mostrar detalles',
    actions: 'Acciones',
    delete: 'Eliminar',
    open: 'Abrir',
    show: 'Mostrar',
    options: 'Opciones',
    prev: 'Anterior',
    next: 'Siguiente',
    skip: 'Saltar',
    finish: 'Terminar',
    settings: 'Configuración',
    cancelSubscription: 'Cancelar suscripción',
    backToEditOrganization: 'Volver a editar organización',
    payNow: 'Pagar ahora',
    back: 'Volver',
    integrationRoutes: 'Rutas de integración',
    transactions: 'Transacciones',
    articlesOfLaw: 'Artículos de ley',
    export: 'Exportar',
    duplicate: 'Hacer una copia',
    makeReturnTrip: 'Hacer un viaje de regreso',
    selectOrganisation: 'Seleccionar organización',
    advancedSettings: 'Configuración avanzada',
    accept: 'Aceptar',
    reject: 'Rechazar',
    sendViaGnet: 'Enviar por GNET',
    printInvoice: 'Imprimir factura',
    enable: 'Habilitar',
    disable: 'Deshabilitar',
    generatePaymentLink: 'Generar enlace de pago',
    addItem: '+ Añadir ítem',
  },
  tooltips: {
    save: 'Guardar',
    edit: 'Editar',
    delete: 'Eliminar',
    showImage: 'Mostrar imagen',
    open: 'Abrir',
    show: 'Mostrar',
    showDetails: 'Mostrar detalles',
    downloadFile: 'Descargar archivo',
    generateFile: 'Generar contrato',
    generateDrivingOrder: 'Imprimir orden de manejo',
    activate: 'Activar',
    deactivate: 'Desactivar',
    cancel: 'Cancelar',
    cancelDriving: 'Cancelar Orden de Manejo',
    cannotMarkAsCanceled: 'El pedido no puede marcarse como cancelado porque ya ha sido aceptado',
    markAsDone: 'Marcar como completado',
    cannotMarkAsDone: 'El pedido no puede marcarse como completado, porque aún no ha ocurrido',
    markAsNoShow: 'Marcar como no presentado',
    cannotMarkAsNoShow: 'El pedido no puede ser marcado como no presentado, porque aún no ha ocurrido',
    deleteDriving: 'Eliminar Orden de Manejo',
    cannotDelete:
      'Lo sentimos, pero por razones de seguridad, los usuarios no pueden ser eliminados mientras estén actualmente conectados.',
    duplicate: 'Hacer una copia',
    makeReturnTrip: 'Hacer un viaje de regreso',
    refresh: 'Actualizar',
    selectOrganisation: 'Seleccionar organización',
    sendViaGnet: 'Enviar por GNET',
    receivedViaGnet: 'Recibido a través de GNET',
    sentViaGnet: 'Enviado a través de GNET',
    printInvoice: 'Imprimir factura',
    optionsDisabledGnet: 'Debe rechazar o aceptar la orden para acceder al menú de opciones',
    cannotMakeReturnTrip: 'No se puede hacer un viaje de regreso para el alquiler diario',
    cannotSendGnetOrderViaGnet: 'No se puede enviar la orden de gnet a través de gnet',
    cannotSendViaGnetWithoutGnetId: 'No se puede enviar la orden a través de gnet sin ID de gnet',
    cannotPrintInvoiceWithoutClient: 'No se puede imprimir la factura sin elegir cliente',
    cannotMarkAsDoneStatusRejected:
      'La orden no se puede marcar como completada porque ya se ha marcado como rechazada',
    cannotMarkAsDoneStatusCanceled:
      'La orden no se puede marcar como completada porque ya se ha marcado como cancelada',
    cannotMarkAsDoneStatusDone: 'La orden no se puede marcar como completada porque ya se ha marcado como completada',
    cannotMarkAsDoneStatusNoShow: 'La orden no se puede marcar como completada porque ya se ha marcado como no show',
    cannotMarkAsNoShowStatusRejected: 'La orden no se puede marcar como no show porque ya se ha marcado como rechazada',
    cannotMarkAsNoShowStatusCanceled: 'La orden no se puede marcar como no show porque ya se ha marcado como cancelada',
    cannotMarkAsNoShowStatusDone: 'La orden no se puede marcar como no show porque ya se ha marcado como completada',
    cannotMarkAsNoShowStatusNoShow: 'La orden no se puede marcar como no show porque ya se ha marcado como no show',
    cannotMarkAsCanceledGnet: 'La orden no se puede marcar como cancelada porque se recibió a través de gnet',
    cannotMarkAsCanceledStatusRejected:
      'La orden no se puede marcar como cancelada porque ya se ha marcado como rechazada',
    cannotMarkAsCanceledStatusExpired:
      'La orden no se puede marcar como cancelada porque ya se ha marcado como expirada',
    cannotMarkAsCanceledStatusCanceled:
      'La orden no se puede marcar como cancelada porque ya se ha marcado como cancelada',
    cannotMarkAsCanceledStatusDraft: 'La orden no se puede marcar como cancelada porque ya se ha marcado como borrador',
    cannotMarkAsCanceledStatusCompleted:
      'La orden no se puede marcar como cancelada porque ya se ha marcado como completada',
    cannotMarkAsCanceledStatusNoShow: 'La orden no se puede marcar como cancelada porque ya se ha marcado como no show',
    cannotDeleteDrivingSentViaGnet: 'La orden no se puede eliminar porque se envió a través de gnet',
    cannotDeleteDrivingReceivedViaGnet: 'La orden no se puede eliminar porque se recibió a través de gnet y se aceptó',
    generatePaymentLink: 'Generar enlace de pago',
    cannotGeneratePaymentLInk: 'El enlace de pago no puede ser generado porque no has activado Stripe',
  },
  snackbar: {
    close: 'Cerrar',
    savedSuccessfully: 'Guardado exitosamente',
    excelDownloadedSuccessfully: 'Descargado exitosamente',
    deletedSuccessfully: 'Eliminado exitosamente',
    sentSuccessfully: 'Enviado exitosamente',
    activeStatusChanged: 'Cambio de estado activo exitoso',
    defaultStatusChanged: 'Cambio de estado predeterminado exitoso',
    primaryStatusChanged: 'Cambio de estado principal exitoso',
    anErrorOccured: 'Ocurrió un error, informar al soporte',
    loadingCommentsFailed: 'Error al cargar los comentarios',
    drivingCanceled: 'Orden de manejo cancelada exitosamente',
    drivingFinished: 'Orden de manejo completada exitosamente',
    drivingNoShow: 'La orden de conducción se ha marcado correctamente como no presentado.',
    drivingDeleted: 'la orden de conducción se ha eliminado correctamente',
    drivingAccepted: 'La orden de conducción ha sido aceptada con éxito',
    drivingRejected: 'La orden de conducción ha sido rechazada con éxito',
    planChanged: 'Cambio de plan exitoso',
    planChangeFailed: 'No es posible cambiar el plan',
    saveChangesFirst: 'Primero guarda los cambios que hiciste anteriormente',
    makeRoutesPublic: 'Las rutas son accesibles públicamente',
    makeRoutesNotPublic: 'Las rutas ya no son accesibles públicamente',
    makePublicLinkActive: 'El enlace público ya está disponible',
    makePublicLinkInactive: 'El enlace público ya no está disponible',
    integrationKeyCopied: 'Clave de integración copiada',
    copyToken: 'Copia manualmente el token',
    publicLinkCopied: 'Enlace público copiado',
    copyPublicLink: 'Copie manualmente el enlace público',
    organisationChanged: 'Empresa cambiada exitosamente',
    changeFailed: 'El cambio falló, por favor intenta de nuevo',
    loginFailed: 'Nombre de usuario o contraseña incorrectos',
    organisationSuspended: 'Tu organización está suspendida',
    userEmailDoesNotExists: 'No existe un usuario con este correo electrónico',
    canceledSubscrSuccessfully: 'Se ha cancelado la suscripción correctamente',
    toManyRequests: 'Demasiadas solicitudes. Intente de nuevo en un minuto.',
    visibleStatusChanged: 'Estado visible cambiado con éxito',
    noInternetConnection: 'Sin internet. Por favor, comprueba tu conexión a internet.',
    backOnline: 'Has vuelto a estar en línea.',
    driverNotifiedToTurnGpsOn: 'Conductor notificado para encender el GPS',
    savedSuccessfullyWithGnetError: 'La sincronización con GNET falló, la orden de conducción se guardó correctamente.',
    logoutFromAnotherDevice: 'Has sido desconectado de otro dispositivo.',
    stripeEnabled: 'Stripe activado con éxito',
    stripeDisabled: 'Stripe desactivado con éxito',
    finishStripeConnect: 'Por favor, completa la configuración de Stripe',
  },
  filters: {
    filter: 'Filtrar',
    currentYear: 'Año actual',
    lastYear: 'Año pasado',
    lastQuarter: 'Último trimestre',
    lastThreeYears: 'Últimos tres años',
    filterByDriver: 'Filtrar por conductor',
    filterByVehicle: 'Filtrar por vehículo',
    filterByClient: 'Filtrar por clientes',
    day: 'Día',
    week: 'Semana',
    month: 'Mes',
    vehicle: 'Vehículo',
    driving: 'Conducción',
    user: 'Usuario',
    fromDate: 'Desde fecha',
    toDate: 'Hasta fecha',
    threeMonths: 'Tres meses',
    halfYear: 'Medio año',
    year: 'Año',
  },
  months: {
    jan: 'Enero',
    feb: 'Febrero',
    mar: 'Marzo',
    apr: 'Abril',
    may: 'Mayo',
    jun: 'Junio',
    jul: 'Julio',
    aug: 'Agosto',
    sep: 'Septiembre',
    oct: 'Octubre',
    nov: 'Noviembre',
    dec: 'Diciembre',
    January: 'Enero',
    February: 'Febrero',
    March: 'Marzo',
    April: 'Abril',
    May: 'Mayo',
    June: 'Junio',
    July: 'Julio',
    August: 'Agosto',
    September: 'Septiembre',
    October: 'Octubre',
    November: 'Noviembre',
    December: 'Diciembre',
  },
  usersPageHeaders: {
    firstName: 'Nombre',
    lastName: 'Apellido',
    username: 'Nombre de usuario',
    email: 'Correo electrónico',
    phoneNumber: 'Número de teléfono',
    role: 'Rol',
    active: 'Activo',
    actions: 'Acciones',
  },
  usersRoles: {
    masterAdmin: 'Administrador principal',
    manager: 'Gerente',
    driver: 'Conductor',
    operator: 'Operador',
    externalAssociate: 'Asociado externo',
    sales: 'Ventas',
    salesLead: 'Jefe de Ventas',
    gnet: 'GNET',
    customer: 'Usuario del portal',
  },
  masterUsersPageHeaders: {
    firstName: 'Nombre',
    lastName: 'Apellido',
    username: 'Nombre de usuario',
    email: 'Correo electrónico',
    phoneNumber: 'Número de teléfono',
    role: 'Rol',
    organisation: 'Organización',
    active: 'Activo',
    actions: 'Acciones',
  },
  usersPage: {
    newItem: 'Nueva entrada de usuario',
    editItem: 'Editar usuario',
    id: 'Id',
    firstName: 'Nombre',
    lastName: 'Apellido',
    username: 'Nombre de usuario',
    password: 'Contraseña',
    phoneNumber: 'Número de teléfono',
    role: 'Rol',
    client: 'Cliente',
    email: 'Correo electrónico',
    organisation: 'Organización',
    percentage: 'Porcentaje',
    externalAssociate: 'Asociado externo',
    image: 'Imagen',
    deleteUser: 'Eliminar usuario',
    deleteMessage: '¿Estás seguro de que deseas eliminar al usuario ',
    emailChanged: 'Cambio de correo electrónico',
    verificationEmailSent:
      'La solicitud para cambiar la dirección de correo electrónico se ha enviado. Para completar el proceso y garantizar la seguridad de la cuenta de usuario, la nueva dirección de correo electrónico debe verificarse haciendo clic en el enlace de verificación enviado al nuevo correo electrónico. La dirección de correo electrónico no se cambiará hasta que se verifique con éxito.',
  },
  clientsPageHeaders: {
    name: 'Nombre',
    phoneNumber: 'Número de teléfono',
    address: 'Dirección',
    email: 'Correo electrónico',
    companyId: 'ID de empresa/ID personal',
    clientType: 'Tipo de cliente',
    percentage: 'Porcentaje',
    active: 'Activo',
    actions: 'Acciones',
  },
  clientsPage: {
    newItem: 'Nueva entrada de cliente',
    editItem: 'Editar cliente',
    id: 'ID',
    name: 'Nombre',
    address: 'Dirección',
    phoneNumber: 'Número de teléfono',
    email: 'Correo electrónico',
    companyId: 'ID de empresa/ID personal',
    clientType: 'Tipo de cliente',
    individual: 'Persona física',
    business: 'Entidad empresarial',
    percentage: 'Porcentaje',
    deleteClient: 'Eliminar cliente',
    deleteMessage: '¿Estás seguro de que deseas eliminar al cliente ',
    tabs: {
      general: 'General',
      transfers: 'Transferencias',
      users: 'Usuarios',
      invoices: 'Facturas',
      cards: 'Tarjetas',
    },
  },
  drivingsHeaders: {
    drivingNumber: 'Número de orden de manejo',
    drivingType: 'Tipo de orden de manejo',
    drivingStatus: 'Estado',
    locationFrom: 'Punto de inicio',
    locationTo: 'Destino',
    vehicleClass: 'Clase de vehículo',
    vehicle: 'Vehículo',
    driversName: 'Conductor',
    startTime: 'Hora de recogida',
    actions: 'Acciones',
    exportExcel: 'Exportar a Excel',
    price: 'Precio',
    totalPrice: 'Precio total',
    confirmed: 'Confirmado',
    paid: 'Pagado',
    numberOfDrivings: 'Número de órdenes de conducción',
  },
  vehiclesHeaders: {
    plateNumber: 'Número de registro',
    brand: 'Marca del vehículo',
    vehicleClass: 'Clase de vehículo',
    passengersNumber: 'Número de pasajeros',
    fuelConsumption: 'Consumo de combustible (por 100 km)',
    yearOfManufacture: 'Año de fabricación',
    pricePerKm: 'Precio por km',
    pricePerMi: 'Precio por milla',
    pricePerHour: 'Precio por hora',
    pricePerWaitingHour: 'Precio por hora de espera',
    active: 'Activo',
    actions: 'Acciones',
  },
  vehiclesPage: {
    id: 'ID',
    newItem: 'Nueva entrada de vehículo',
    editItem: 'Cambiar vehículo',
    vehiclePlates: 'Matrículas de vehículos',
    vehicleBrand: 'Marca del vehículo',
    vehicleClass: 'Clase de vehículo',
    passengersNumber: 'Número de pasajeros',
    fuelConsumption: 'Consumo de combustible del coche (100 km)',
    fuelConsumptionMi: 'Consumo de combustible del coche (100 millas)',
    manufactureYear: 'Año de fabricación',
    price: 'Precio por km',
    priceMi: 'Precio por milla',
    hourPrice: 'Precio por hora',
    image: 'Imagen',
    hourWaitingPrice: 'Precio por hora de espera',
    deleteMessage: '¿Estás seguro de que deseas eliminar este vehículo?',
    tabs: {
      general: 'General',
      transfers: 'Transferencias',
      costs: 'Costos',
      damages: 'Daños',
    },
    licence: 'Número de licencia',
  },
  vehicleClassesHeaders: {
    vehicleClass: 'Clase de vehículo',
    active: 'Activo',
    actions: 'Acciones',
  },
  vehicleClassesPage: {
    newItem: 'Nueva entrada de clase de vehículo',
    editItem: 'Cambiar clase de vehículo',
    id: 'ID',
    vehicleClass: 'Clase de vehículo',
    hourWaitingPrice: 'Precio por hora de espera',
    deleteMessage: '¿Estás seguro de que deseas eliminar esta clase de vehículo?',
  },
  expensesHeaders: {
    name: 'Nombre',
    vehicle: 'Vehículo',
    type: 'Tipo',
    description: 'Descripción',
    price: 'Precio',
    expenseDate: 'Fecha',
    actions: 'Acción',
    driving: 'Conducción',
    exportExcel: 'Exportar a Excel',
  },
  vehicleExpensesTypes: {
    minorService: 'Servicio menor',
    majorService: 'Servicio mayor',
    fuel: 'Combustible',
    tires: 'Neumáticos',
    parking: 'Estacionamiento',
    other: 'Otro',
  },
  expensesPage: {
    newItem: 'Nueva entrada de costo',
    editItem: 'Cambio de costo',
    id: 'ID',
    name: 'Nombre',
    vehicle: 'Vehículo',
    costType: 'Tipo de costo',
    price: 'Precio',
    currency: 'Moneda',
    costTime: 'Hora del costo',
    description: 'Descripción',
    deleteMessage: '¿Estás seguro de que deseas eliminar este costo?',
    costTypes: {
      'Mali servis': 'Servicio menor',
      'Veliki servis': 'Servicio mayor',
      Gorivo: 'Combustible',
      Gume: 'Neumáticos',
      Parking: 'Estacionamiento',
      Ostalo: 'Otro',
    },
  },
  vehicleMaintenanceHeaders: {
    name: 'Vehículo',
    details: 'Detalles',
    dateFrom: 'Fecha desde',
    dateTo: 'Fecha hasta',
    actions: 'Acciones',
  },
  vehicleMaintenancePage: {
    newItem: 'Nueva entrada de avería de vehículo',
    editItem: 'Cambiar avería de vehículo',
    id: 'ID',
    vehicle: 'Vehículo',
    description: 'Descripción',
    dateFrom: 'Fecha desde',
    dateTo: 'Fecha hasta',
    deleteMessage: '¿Estás seguro de que deseas eliminar esta avería de vehículo?',
  },
  rebatesHeaders: {
    min: 'Número mínimo de órdenes de manejo',
    max: 'Número máximo de órdenes de manejo',
    percentage: 'Rebate (%)',
    actions: 'Acciones',
  },
  rebatesPage: {
    newItem: 'Nueva entrada de descuento',
    editItem: 'Cambiar descuento',
    rebateMin: 'Número mínimo de órdenes de manejo',
    rebateMax: 'Número máximo de órdenes de manejo',
    rebate: 'Rebate (%)',
    deleteRebate: 'Eliminar descuento',
    deleteMessage: '¿Estás seguro de que deseas eliminar este descuento?',
  },
  customerCardsHeaders: {
    cardholderName: 'Nombre del titular',
    cardNumber: 'Número de tarjeta',
    expiryDate: 'Fecha de caducidad',
    cvv: 'CVV',
    actions: 'Acciones',
  },
  customerCardsPage: {
    newItem: 'Nueva entrada de tarjeta',
    editItem: 'Cambio de tarjeta',
    cardholderName: 'Nombre del titular',
    cardNumber: 'Número de tarjeta',
    expiryDate: 'Fecha de caducidad',
    cvv: 'CVV',
    deleteCard: 'Eliminar tarjeta',
    deleteMessage: '¿Está seguro de que desea eliminar esta tarjeta?',
  },
  pricingRegionsHeaders: {
    name: 'Nombre',
    actions: 'Acciones',
  },
  pricingRegionsPage: {
    newItem: 'Nueva entrada de región de precios',
    editItem: 'Cambio de región de precios',
    name: 'Nombre',
    chooseCurrency: 'Seleccionar moneda',
    team10Price: 'Precio de Equipo 10',
    team10PaddlePriceId: 'ID de Precio de Paddle para Equipo 10',
    team25Price: 'Precio de Equipo 25',
    team25PaddlePriceId: 'ID de Precio de Paddle para Equipo 25',
    team50Price: 'Precio de Equipo 50',
    team50PaddlePriceId: 'ID de Precio de Paddle para Equipo 50',
    chooseCountries: 'Seleccionar países',
    description: 'Descripción',
    deletePricingRegion: 'Eliminar región de precios',
    deleteMessage: '¿Está seguro de que desea eliminar esta región de precios?',
  },
  notificationsHeaders: {
    time: 'Hora',
    notification: 'Notificación',
  },
  organisationsHeaders: {
    name: 'Organización',
    phoneNumber: 'Número de teléfono',
    email: 'Correo electrónico',
    address: 'Dirección',
    companyId: 'ID de la empresa',
    vat: 'IVA',
    active: 'Activo',
    createdAt: 'Registrado',
    actions: 'Acciones',
    billingDate: 'Fecha de facturación',
  },
  organisationsPage: {
    newItem: 'Nueva entrada de usuario',
    editItem: 'Editar usuario',
    id: 'Id',
    name: 'Organización',
    address: 'Dirección',
    phoneNumber: 'Número de teléfono',
    email: 'Correo electrónico',
    vat: 'IVA',
    companyId: 'ID de la empresa',
    warrantNumber: 'Número de decisión',
    image: 'Imagen',
    signature: 'Firma',
    seal: 'Sello',
    deactivateMessage: '¿Estás seguro de que deseas ',
    deactivate: 'DESACTIVAR',
    activate: 'ACTIVAR',
    organisation: 'organización',
    deleteOrganisation: 'Eliminar organización',
    deleteMessage: '¿Estás seguro de que deseas eliminar esta organización?',
    cancelSubscrMessage:
      '¿Está seguro de que desea cancelar su suscripción? Tenga en cuenta que al confirmar esta acción: 1. La cuenta de su organización y todos los datos asociados serán eliminados permanentemente. Esta acción no se puede deshacer. 2. Se le cerrará la sesión automáticamente del sistema inmediatamente después de la cancelación. 3. Ya no tendrá acceso para iniciar sesión ni recuperar ningún dato relacionado con su organización en el futuro. Le recomendamos encarecidamente que revise cualquier información necesaria o exporte datos críticos antes de proceder con esta acción irreversible. Si tiene alguna pregunta o necesita asistencia, no dude en contactar a nuestro equipo de soporte. ¿Desea proceder con la cancelación?',
    allDrivings: 'Número total de órdenes de manejo',
    allDrivingsAccepted: 'Número total de órdenes de manejo aceptadas',
    allDrivingsCanceled: 'Número total de órdenes de manejo canceladas',
    allVehicles: 'Número total de vehículos',
    allUsers: 'Número total de usuarios',
    areYouSure: '¿Estás seguro?',
    makeRoutesAvailable: 'Hacer rutas de integración disponibles',
    percentage: 'Porcentaje',
    deleteImageMessage: '¿Estás seguro de que quieres eliminar esta imagen?',
    deleteImageTitle: 'Eliminar Imagen',
    cancelSubscriptionTitle: 'Cancelar suscripción',
    subscriptionType: 'Tipo de suscripción',
    billingDate: 'Fecha de facturación',
    articlesOfLaw: 'Artículos de ley',
    country: 'País',
    referent: 'Referente',
    gnetId: 'ID de GNET',
  },
  transactionsHeaders: {
    invoiceNumber: 'Número de factura',
    status: 'Estado',
    total: 'Total',
    currency: 'Moneda',
    billedAt: 'Facturado el',
    actions: 'Acciones',
    language: 'Idioma',
  },
  transactionsPage: {
    newItem: 'Nueva entrada de transacción',
    editItem: 'Editar transacción',
    id: 'Id',
    invoiceNumber: 'Número de factura',
    status: 'Estado',
    sent: 'Enviado',
    completed: 'Completado',
    total: 'Total',
    currency: 'Moneda',
    billedAt: 'Facturado el',
    actions: 'Acciones',
    deleteTransaction: 'Eliminar transacción',
    deleteMessage: '¿Estás seguro de que quieres eliminar esta transacción?',
    currentSubscription: 'Paquete actual',
    nextBillingDate: 'Próxima fecha de facturación',
    numberOfSeatsTaken: 'Número de miembros del equipo ocupados',
    numberOfSeatsAvailable: 'Número de miembros del equipo disponibles',
  },
  currenciesHeaders: {
    name: 'Nombre',
    code: 'Código',
    symbol: 'Símbolo',
    active: 'Activo',
    default: 'Primario',
    actions: 'Acciones',
  },
  currenciesPage: {
    newItem: 'Nueva entrada de moneda',
    editItem: 'Cambiar moneda',
    id: 'Id',
    currency: 'Moneda',
    code: 'Código',
    symbol: 'Símbolo',
    deleteMessage: '¿Estás seguro de que deseas eliminar esta moneda?',
  },
  paymentMethodsHeaders: {
    name: 'Nombre',
    active: 'Activo',
    default: 'Primario',
    priceVisible: 'Precio visible',
    actions: 'Acciones',
  },
  paymentMethodsPage: {
    newItem: 'Nueva entrada de método de pago',
    editItem: 'Cambiar método de pago',
    id: 'Id',
    paymentMethod: 'Método de pago',
    deleteMessage: '¿Estás seguro de que deseas eliminar este método de pago?',
  },
  feedbacksHeaders: {
    subject: 'Nombre',
    organisation: 'Organización',
    user: 'Usuario',
    actions: 'Acciones',
  },
  passengersHeaders: {
    firstName: 'Nombre',
    lastName: 'Apellido',
    phoneNumber: 'Número de teléfono',
    email: 'Correo electrónico',
    passport: 'Pasaporte',
    nationality: 'Nacionalidad',
    actions: 'Acciones',
  },
  checkpointsHeaders: {
    checkpointLocation: 'Ubicación del punto de ruta',
    arrivalTime: 'Hora de llegada',
    actions: 'Acciones',
  },
  tables: {
    noDataText: 'Sin datos',
    search: 'Buscar',
    drivings: 'Órdenes de manejo',
    drivingsByPage: 'Órdenes de manejo por página',
    vehicles: 'Vehículos',
    vehiclesByPage: 'Vehículos por página',
    vehiclesClasses: 'Clases de vehículos',
    vehiclesClassesByPage: 'Clases de vehículos por página',
    expenses: 'Costos',
    expensesByPage: 'Costos por página',
    vehiclesMaintenance: 'Averías de vehículos',
    vehiclesMaintenanceByPage: 'Averías de vehículos por página',
    reportsByPage: 'Informes por página',
    currencies: 'Monedas',
    currencieseByPage: 'Monedas por página',
    payments: 'Métodos de pago',
    paymentsByPage: 'Métodos de pago por página',
    saasAdministrators: 'Todos los administradores',
    users: 'Todos los usuarios',
    usersByPage: 'Usuarios por página',
    clients: 'Todos los clientes',
    clientsByPage: 'Clientes por página',
    organisations: 'Todas las organizaciones',
    organisationsByPage: 'Organizaciones por página',
    rebates: 'Descuentos',
    rebatesByPage: 'Descuentos por página',
    cards: 'Tarjetas',
    cardsByPage: 'Tarjetas por página',
    transactions: 'Transacciones',
    transactionsByPage: 'Transacciones por página',
    pricingRegions: 'Tarifas de Regiones',
    pricingRegionsByPage: 'Tarifas de Regiones por página',
    feedback: 'Comentarios',
    feedbacksByPage: 'Comentarios por página',
    notifications: 'Notificaciones',
    notificationsByPage: 'Notificaciones por página',
    all: 'Todo',
    passengers: 'Pasajeros',
    passengersByPage: 'Pasajeros por página',
    of: 'de',
    checkpoints: 'Puntos de ruta',
    checkpointsByPage: 'Puntos de ruta por página',
    invoices: 'Facturas',
    invoicesByPage: 'Facturas por página',
    drivingOffers: 'Ofertas de conducción',
    drivingOffersByPage: 'Ofertas de conducción por página',
    smsMessages: 'Mensajes SMS',
    smsMessagesByPage: 'Mensajes SMS por página',
    resourcesUsed: 'Recursos utilizados',
    resourcesUsedByPage: 'Recursos por página',
    inNext3Days: 'En los próximos 3 días',
    notConfirmedOrPaid: 'No confirmado o pagado',
    inNext5Days: 'En los próximos 5 días',
    latestReviews: 'Últimas reseñas',
    driversByPage: 'Conductores por página',
    reviewsByPage: 'Reseñas por página',
  },
  statistics: {
    numberOfAcceptedDrivings: 'Número de órdenes de manejo aceptadas',
    numberOfRejectedDrivings: 'Número de órdenes de manejo rechazadas por conductor',
    vehicleDrivingsNumber: 'Número de órdenes de manejo por vehículo',
    vehicleProfit: 'Ganancias por vehículo',
    monthDrivingsNumber: 'Número de órdenes de manejo por mes',
    driverProfit: 'Ganancias por conductor',
  },
  reportsHeaders: {
    vehicle: 'Vehículo',
    expenses: 'Gastos',
    profit: 'Ganancias',
    earnings: 'Ingresos',
    daysWorking: 'Días de trabajo',
    usageIndex: 'Índice de uso',
    client: 'Cliente',
    numberOfDrivings: 'Número de Conducciones',
    percentForClient: 'Precio para el cliente',
    sumOfPrice: 'Cantidad',
  },
  packages: {
    active: 'Activo',
    starterTitle: 'Inicial',
    monthlyPackagesMessage: 'Suscripción mensual por usuario.',
    starterText: 'Ideal para individuos o equipos pequeños que solo necesitan funcionalidades básicas.',
    starterListItemOne: 'Aplicaciones móviles',
    starterListItemTwo: 'Notificaciones push',
    starterListItemThree: 'Acceso a la administración',
    starterListItemFour: 'Integración en el sitio web',
    standardTitle: 'Estándar',
    standardText: 'Perfecto para organizaciones con más de 5 conductores.',
    standardListItemOne: 'Paquete INICIAL incluido',
    standardListItemTwo: 'Sin límite en el número de conductores',
    standardListItemThree: 'Disponibilidad del 99,9%',
    standardListItemFour: 'Informes y Gestión de Vehículos',
    premiumTitle: 'Premium',
    premiumText: 'Perfecto para organizaciones de servicios de limusina que operan con datos precisos.',
    premiumListItemOne: 'Paquete ESTÁNDAR incluido',
    premiumListItemTwo: 'BI y Pronóstico de Costos',
    premiumListItemThree: 'Exportar datos',
    premiumListItemFour: 'Informes semanales y mensuales',
    warningOne: '* Los precios mostrados son suscripciones mensuales por usuario',
    warningTwo:
      '* Después de cambiar a un nuevo paquete, no es posible cambiar a un paquete más pequeño durante los siguientes 6 meses',
    warningThree:
      '* Los usuarios del plan de demostración tienen una visión de las funcionalidades proporcionadas por el paquete premium',
    warningOneAnnualPackage:
      '¿Necesita más? ¿Tiene una organización más grande? Tenemos un paquete EMPRESARIAL para usted.',
    warningTwoAnnualPackage: 'y nuestro equipo de ventas se pondrá en contacto con usted en breve.',
    packageChange: 'Cambiar paquete',
    changePackage: '¿Estás seguro de que deseas cambiar a',
    package: 'paquete',
    annualPricing: 'Precios anuales',
    annualPackageType10: 'Equipo 10',
    annualPackageType25: 'Equipo 25',
    annualPackageType50: 'Equipo 50',
    'Team 10': 'Equipo 10',
    'Team 25': 'Equipo 25',
    'Team 50': 'Equipo 50',
    annualPackagesMessage: 'Suscripción anual por organización.',
    annualPackageType10MainMessage: 'Hasta 10 cuentas de usuario.',
    annualPackageType25MainMessage: 'Hasta 25 cuentas de usuario.',
    annualPackageType50MainMessage: 'Hasta 50 cuentas de usuario.',
    annualPackageListItemOne: 'Paquete Estándar',
    annualPackageListItemTwo: 'Pago único',
    annualPackageListItemThree: 'Beneficios de precio',
    annualPackageType10Discount: '- hasta un 65% de descuento',
    annualPackageType25Discount: '- hasta un 68% de descuento',
    annualPackageType50Discount: '- hasta un 65% de descuento',
    allFeaturesIncluded: 'Todas las características incluidas',
    trialPeriodIncluded: 'Período de prueba incluido',
    unlimitedVehicles: 'Vehículos ilimitados',
    unlimitedBookings: 'Reservas ilimitadas',
    onetimePaymentAnnually: 'Pago único anual',
  },
  successPaymentPage: {
    message: 'Pago exitoso. Gracias.',
  },
  demoExpiring: {
    notification: 'Notificación',
    expiredSubscriptionMessage:
      'Le informamos que su suscripción a esta plataforma ha expirado. Para seguir utilizando nuestros servicios, por favor, renueve su suscripción.',
  },
  login: {
    username: 'Nombre de usuario',
    password: 'Contraseña',
    rememberMe: 'Recordarme',
    login: 'Iniciar sesión',
    noAccount: '¿Todavía no tienes una cuenta? Puedes registrarte',
    forgetPassword: '¿Olvidaste tu contraseña? Puedes restablecerla',
    registerHere: 'aquí',
    email: 'Correo electrónico',
    enterEmail: 'Por favor, introduce tu correo electrónico',
    enterPassword: 'Por favor, introduce tu nueva contraseña',
    signInWithGoogle: 'Iniciar sesión con Google',
  },
  registration: {
    register: 'Registro',
    name: 'Nombre de la organización',
    address: 'Dirección',
    phoneNumber: 'Número de teléfono',
    email: 'Correo electrónico',
    companyId: 'Identificación de la empresa',
    vat: 'IVA',
    language: 'Idioma',
    package: 'Paquete',
    accept: 'Acepto',
    terms: 'los términos de uso',
    successfullyRegistered:
      'El usuario se ha registrado con éxito. Recibirás un correo electrónico con un enlace de verificación.',
    haveAccount: '¿Ya tienes una cuenta? Puedes iniciar sesión',
    loginHere: 'Aquí',
    trialPeriodInfo:
      'Incluido es un período de prueba gratuito de 14 días. La facturación seguirá después del final del período de prueba.',
  },
  driving: {
    drivingType: 'Tipo de orden de conducción',
    general: 'Información general',
    newDriving: 'Programar un traslado',
    newDailyRent: 'Programar un alquiler diario',
    areYouSure: '¿Estás seguro?',
    vehicleIsUnavailable: 'El vehículo está ocupado.',
    vehicleIsDamaged: 'El vehículo está averiado.',
    sureAboutThisVehicle: '¿Estás seguro de que quieres elegir este vehículo?',
    driverUnavaliable: 'El conductor está ocupado.',
    sureAboutThisDriver: '¿Estás seguro de que quieres seleccionar a este conductor?',
    checkVehicleSeatAvailability: 'Este vehículo no puede llevar a tantos pasajeros.',
    cancelDriving: '¿Estás seguro de que quieres cancelar el pedido de traslado?',
    finishDriving: '¿Estás seguro de que quieres finalizar el pedido de traslado?',
    noShowDriving: '¿Está seguro de que desea marcar el pedido de conducción como no presentado?',
    deleteDriving: '¿Estás seguro de que quieres eliminar el pedido de traslado?',
    newTransfer: 'Nuevo traslado',
    driving: 'Pedido de traslado',
    comments: 'Comentarios',
    id: 'Id',
    locationFrom: 'Punto de inicio',
    locationTo: 'Destino',
    vehicleClass: 'Clase de vehículo',
    vehicle: 'Vehículo',
    driver: 'Conductor',
    pickupTime: 'Hora de recogida',
    clientConfirmed: 'Cliente confirmado',
    description: 'Descripción',
    extraInfo: 'Información adicional',
    drivingNotAccepted: 'No aceptaron el pedido de traslado:',
    currency: 'Moneda',
    paymentMethod: 'Métodos de pago',
    price: 'Precio',
    paid: 'Pagado',
    distance: 'Distancia (km)',
    distanceMi: 'Distancia (millas)',
    expectedTime: 'Duración esperada',
    waitingTime: 'Tiempo de espera (h)',
    EnterTheNumberOfHoursWaiting: 'Ingrese el número de horas de espera',
    dropOffTime: 'Hora de llegada',
    comebackTime: 'Hora de regreso',
    acceptUntil: 'Aceptar hasta',
    drivingRequired: '¿Es obligatorio el pedido de traslado?',
    comment: 'Comentario',
    attachments: 'Adjuntos',
    addFiles: '+ Agregar archivos',
    deleteFiles: '¿Estás seguro de que quieres eliminar este archivo?',
    passengers: 'Pasajeros',
    addPassenger: '+ Agregar pasajero',
    deletePassenger: '¿Estás seguro de que quieres eliminar este pasajero?',
    chooseClient: 'Elegir cliente',
    costs: 'Costos',
    checkpoints: 'Puntos de ruta',
    addCheckpoint: 'Agregar punto de ruta',
    deleteCheckpoint: '¿Estás seguro de que quieres eliminar este punto de ruta?',
    waitingBoardText: 'Texto de espera en el tablero',
    flightNumber: 'Número de vuelo',
    flightInfo: 'Información del vuelo',
    createdBy: 'Orden de conducción creado a las <b>{time}</b> por <b>{user}</b>',
    client: 'Cliente',
    gnet: 'GNET',
    note: 'Nota',
    noteDriver: 'Nota para el conductor',
    noteDispatcher: 'Nota para el despachador',
    passenger: 'Pasajero',
    fullName: 'Nombre completo',
    phoneNumber: 'Número de teléfono',
    email: 'Correo electrónico',
    suitcasesNumber: 'Número de maletas',
    passengersNumber: 'Número de pasajeros',
    babySeatsNumber: 'Número de asientos para bebés',
    stopsNumber: 'Número de paradas',
    steps: {
      mainInfo: 'Información principal',
      additionalInfo: 'Información adicional',
      review: 'Revisión',
    },
    commissionAmount: 'Comisión',
  },
  drivingStatus: {
    pending: 'Pendiente',
    accepted: 'Aceptado',
    rejected: 'Rechazado',
    expired: 'Caducado',
    canceled: 'Cancelado',
    draft: 'Borrador',
    done: 'Completado',
    noShow: 'No presentado',
  },
  drivingOrderType: {
    dailyRent: 'Alquiler diario',
    transfer: 'Traslado',
  },
  flightInfo: {
    airlineName: 'Nombre de la aerolínea',
    flightNumber: 'Número de vuelo',
    departureAirport: 'Aeropuerto de salida',
    departureCountry: 'País de salida',
    departureTime: 'Hora de salida',
    arrivalAirport: 'Aeropuerto de llegada',
    arrivalCountry: 'País de llegada',
    arrivalTime: 'Hora de llegada',
    departureDelayed: 'Retraso de salida',
    flightStatus: 'Estado del vuelo',
    percentageOfFlight: 'Porcentaje del vuelo',
  },
  customValidations: {
    fileType: `El tipo de archivo .{extension} no es compatible`,
    fileSize: 'Los archivos no pueden ser más grandes de {size}',
    fieldRequired: 'El campo {field} es obligatorio',
    fieldType: 'El campo {field} debe ser de tipo {type}',
    fieldLengthMin: 'El campo {field} debe tener {length} o más caracteres',
    fieldLengthMax: 'El campo {field} debe tener {length} o menos caracteres',
    fieldUnique: 'El campo {field} debe ser único',
    fieldValid: 'El campo {field} debe ser válido',
    noActiveSubscription: 'No se encontró una suscripción activa',
    usersLimitExceeded: 'Límite de usuarios alcanzado',
    fieldBefore: 'El {field1} debe estar antes que {field2}',
    flightNotExist: 'El vuelo {field} no existe',
    gnetKeyNotValid: 'La clave de Gnet proporcionada no es válida.',
    gnetPassengersRequired: 'La orden de conducción no puede ser enviada a GNET sin pasajeros.',
    priceAndCurrencyRequired: 'El enlace de pago no puede generarse sin precio y moneda',
    fieldValueNotSupported: 'El valor del campo {field} no está soportado',
    noCountryCode: 'No has seleccionado un país',
    noStripeForYourCountry: 'Stripe no está disponible en tu país',
    stripeAlreadyEnabled: 'Stripe ya está activado',
    fieldValueMin: 'El campo {field} debe ser {min} o más',
    fieldValueMax: 'El campo {field} debe ser {max} o menos',
  },
  fieldTypes: {
    string: 'cadena de texto',
    integer: 'número',
    date: 'fecha',
  },
  names: {
    username: 'nombre de usuario',
    password: 'contraseña',
    choose_client: 'elegir cliente',
    choose_month: 'elegir mes',
    currency: 'moneda',
    payment_method: 'método de pago',
    vehicle_class: 'clase de vehículo',
    first_name: 'primer nombre',
    last_name: 'apellido',
    passport: 'pasaporte',
    nationality: 'nacionalidad',
    plate_number: 'número de placa',
    brand: 'marca de auto',
    number_of_passengers: 'número de pasajeros',
    fuel_consumption: 'consumo de combustible',
    year_of_manufacture: 'año de fabricación',
    price_per_km: 'precio por km',
    price_per_mi: 'precio por milla',
    price_per_hour: 'precio por hora',
    price_per_waiting_hour: 'precio por hora de espera',
    vehicle: 'vehículo',
    description: 'descripción',
    time_from: 'hora de inicio',
    time_to: 'hora de finalización',
    name: 'nombre',
    cost_type: 'tipo de costo',
    cost_time: 'tiempo de costo',
    price: 'precio',
    rebate_min: 'número mínimo de pedidos de traslado',
    rebate_max: 'número máximo de pedidos de traslado',
    rebate: 'descuento',
    phone_number: 'número de teléfono',
    organisation: 'organización',
    role: 'rol',
    image: 'imagen',
    location_from: 'ubicación de inicio',
    location_to: 'ubicación de destino',
    pickup_time: 'hora de recogida',
    distance: 'distancia',
    waiting_time: 'tiempo de espera',
    comment: 'comentario',
    address: 'dirección',
    vat: 'IVA',
    company_id: 'identificación de la empresa',
    company_personal_id: 'id de empresa/id personal',
    licence: 'licencia',
    warrant_number: 'número de orden',
    seal: 'sello',
    signature: 'firma',
    email: 'correo electrónico',
    percentage: 'porcentaje',
    organizationName: 'nombre de la organización',
    package: 'paquete',
    choose_currency: 'elegir moneda',
    team_10_price: 'precio equipo 10',
    team_10_paddle_price_id: 'ID precio Paddle equipo 10',
    team_25_price: 'precio equipo 25',
    team_25_paddle_price_id: 'ID precio Paddle equipo 25',
    team_50_price: 'precio equipo 50',
    team_50_paddle_price_id: 'ID precio Paddle equipo 50',
    countries: 'países',
    invoice_number: 'número de factura',
    status: 'estado',
    total: 'total',
    billed_at: 'facturado el',
    articles_of_law: 'artículos de ley',
    country: 'país',
    referent: 'referent',
    language: 'idioma',
    location: 'ubicación',
    arrival_time: 'hora de llegada',
    waiting_board_text: 'texto de espera en el tablero',
    flight_number: 'número de vuelo',
    gnet_id: 'ID de GNET',
    receiver_id: 'ID del receptor',
    preferred_vehicle_type: 'tipo de vehículo preferido',
    reservation_type: 'tipo de reserva',
    run_type: 'tipo de carrera',
    trip_duration_minutes: 'duración del viaje',
    client: 'cliente',
    client_type: 'tipo de cliente',
    card_holder_name: 'nombre del titular',
    card_number: 'número de tarjeta',
    expiry_date: 'fecha de caducidad',
    cvv: 'cvv',
    code: 'código',
    symbol: 'símbolo',
    quantity: 'cantidad',
    discount: 'descuento',
    driving_type: 'tipo de conducción',
    full_name: 'nombre completo',
    suitcases_number: 'número de maletas',
    passengers_number: 'número de pasajeros',
    baby_seats_number: 'número de asientos para bebés',
    stops_number: 'número de paradas',
    commission_amount: 'comisión',
  },
  welcomeModal: {
    welcome: 'Bienvenido a LimoExpress',
  },
  weekDays: {
    monday: 'lunes',
    tuesday: 'martes',
    wednesday: 'miércoles',
    thursday: 'jueves',
    friday: 'viernes',
    saturday: 'sábado',
    sunday: 'domingo',
  },
  select: {
    noDataAvailable: 'No hay datos disponibles',
  },
  excelExport: {
    to_date_range: 'No se puede generar el informe para fechas futuras. Por favor, introduzca fechas válidas',
    overlap_range: 'La hora de finalización no puede ser mayor que la hora de inicio.',
  },
  setPassword: {
    success: 'Correo electrónico enviado',
    checkEmail: 'Revise su correo electrónico para obtener más instrucciones.',
    already_changed: 'Ya ha cambiado la contraseña',
    send_new_reset_request:
      'Ya ha cambiado la contraseña con este enlace. Envíe una nueva solicitud de restablecimiento de contraseña.',
  },
  statisticsPage: {
    reportType: 'Tipo de informe',
    reportTime: 'Tiempo del informe',
    vehicleUtilization: 'Utilización de vehículos',
    costEffectiveness: 'Rentabilidad',
    generalReport: 'Informe general',
    clientsReport: 'Informe por Clientes',
  },
  profile: {
    yourProfile: 'Tu perfil',
    name: 'Nombre',
    role: 'Rol',
    username: 'Nombre de usuario',
    password: 'Contraseña',
    phoneNumber: 'Número de teléfono',
    email: 'Correo electrónico',
    percentage: 'Porcentaje',
  },
  gpsMaps: {
    noTransfers: 'No hay transferencias en curso o próximas.',
    map: 'Mapa',
    transfers: 'Transferencias',
    drivers: 'Conductores',
    ongoing: 'En curso',
    upcoming: 'Próximos',
    driving: 'Conduccion',
    driver: 'Conductor',
    vehicle: 'Vehículo',
    requestDriverLocation: 'Solicitar ubicación del conductor',
    lastRecordedLocation: 'Última ubicación registrada',
  },
  driverStatus: {
    available: 'Disponible',
    offline: 'Fuera de línea',
    inTransfer: 'En transferencia',
  },
  onboardingTutorialMain: {
    languageStep: 'Aquí puedes establecer tu idioma preferido para la aplicación.',
    notificationsStep: 'Estas son tus notificaciones.',
    addTransferStep:
      'Haz clic en este botón para crear tus transferencias y alquileres diarios. Los alquileres diarios son transferencias personalizadas sin destino.',
    sideMenuButtonStep: 'Haz clic aquí para mostrar u ocultar el menú.',
    sideMenuExpStep:
      'Desde aquí, puedes navegar a las secciones principales de la aplicación: Transferencias, Flota, Costos, Administración, Estadísticas y Transacciones.',
    sideMenuButtonCloseStep: 'Haz clic de nuevo para ocultar el menú.',
    supportStep:
      'Si necesita ayuda con algún problema, no dude en ponerse en contacto con el Soporte haciendo clic aquí.',
  },
  onboardingTutorialVehicles: {
    tableStep: 'Tus vehículos se listarán aquí en una tabla, después de agregarlos.',
    searchStep: 'Puedes encontrar vehículos más fácilmente buscándolos directamente.',
    addStep:
      "Agrega nuevos vehículos con el botón 'Agregar'. Ten en cuenta que debes agregar 'Clases de vehículos' antes de usar esta función.",
  },
  onboardingTutorialStatistics: {
    typeStep:
      'En esta sección, puedes encontrar todos tus datos estadísticos mostrados en gráficos. Selecciona el tipo de estadística aquí.',
    intervalStep: 'Agrega un intervalo para ver estadísticas.',
    downloadStep: 'También puedes descargar e imprimir estas tablas.',
  },
  onboardingTutorialGps: {
    tabOneStep:
      'En el módulo GPS, puedes seguir a tus conductores y transferencias en el mismo lugar. Selecciona esta pestaña para ver transferencias actuales y futuras.',
    tabTwoStep:
      'Selecciona vehículos para verlos en el mapa. Puedes agregar vehículos libres directamente desde aquí a las transferencias.',
    mapStep: 'Selecciona o deselecciona vehículos haciendo clic en su pin en el mapa.',
  },
  advancedSettings: {
    advancedSettings: 'Configuración avanzada',
    distanceUnit: 'Unidad de distancia',
    distanceUnitDescription: 'Seleccione la unidad de medida para las distancias.',
    kilometers: 'Kilómetros',
    miles: 'Millas',
    dateFormat: 'Formato de fecha',
    dateFormatDescription: 'Seleccione el formato de fecha que prefiere usar.',
    ddmmyyyy: 'DD-MM-YYYY',
    mmddyyyy: 'MM-DD-YYYY',
    mandatoryDrivings: 'Órdenes de conducción obligatorias',
    mandatoryDrivingsDescription: 'Elija si desea que todas las órdenes de conducción sean obligatorias por defecto.',
    mandatoryDrivingsDefault: 'Órdenes de Conducción Obligatorias (Por defecto)',
    paidDrivings: 'Órdenes de conducción pagadas',
    paidDrivingsDescription: 'Marque para que todos los pedidos de conducción sean pagados por defecto.',
    paidDrivingsDefault: 'Órdenes de conducción pagadas (Por defecto)',
    notificationsEmail: 'Correo electrónico para notificaciones',
    notificationsEmailDescription:
      'Cambie el correo electrónico predeterminado para recibir notificaciones (si no desea usar la dirección de correo electrónico de su organización).',
    email: 'Correo electrónico',
    articlesOfLaw: 'Artículos de ley',
    integrations: 'Integraciones',
    gnetId: 'GNET ID',
    gnetDescription:
      'GNET es una plataforma integral diseñada para optimizar el seguimiento en tiempo real, la gestión de flotas y las operaciones de despacho para la industria del transporte.',
    makeRoutesAvailable: 'Hacer rutas de integración disponibles',
    makeRoutesAvailableDescription:
      'Active su clave API para integrar LimoExpress con su sitio web, sistema ERP y más.',
    makePublicLinkAvailable: 'Hacer enlace público disponible',
    makePublicLinkAvailableDescription:
      'Active el enlace para acceder a la página de reservas pública para que sus clientes puedan acceder al formulario de reserva avanzado y crear cuentas fácilmente. Puede usar el enlace en boletines, en el sitio web, folletos; es un enlace único solo para su empresa.',
    emailsEnabled: 'Correos electrónicos habilitados',
    emailsEnabledDefault: 'Correos electrónicos habilitados',
    emailsEnabledDescription: 'Marque para recibir notificaciones por correo electrónico.',
    passengerReviewsEnabled: 'Reseñas de pasajeros habilitadas',
    passengerReviewsEnabledDefault: 'Reseñas de pasajeros habilitadas',
    passengerReviewsEnabledDescription:
      'Al activar esta función, sus pasajeros (si se ingresó un correo electrónico) recibirán un correo electrónico de confirmación después de que se complete el viaje (marcado como Finalizado) y tendrán la oportunidad de proporcionar calificaciones (comentarios) sobre sus servicios.',
    vat: 'IVA',
    vatPercentage: 'Porcentaje de IVA',
    vatPercentageDescription: 'Ingrese el porcentaje de IVA.',
    invoicePaymentInstructions: 'Instrucciones de pago de factura',
    stripe: 'Stripe',
    stripeDescription:
      'Activa Stripe para poder cobrar a tus clientes por tus servicios. LimoExpress no cobra ninguna comisión.',
  },
  drivingExcelCheckboxes: {
    drivingNumber: 'Número de orden de conducción',
    drivingType: 'Tipo de orden de conducción',
    drivingStatus: 'Estado',
    locationFrom: 'Punto de partida',
    locationTo: 'Destino',
    driver: 'Conductor',
    client: 'Cliente',
    plateNumber: 'Número de matrícula',
    note: 'Descripción',
    fuelConsumption: 'Consumo de combustible',
    pickupTime: 'Hora de recogida',
    numberOfPassengers: 'Número de pasajeros',
    passengers: 'Pasajeros',
    price: 'Precio',
    paid: 'Pagado',
    paymentMethod: 'Método de pago',
    waitingTime: 'Tiempo de espera (h)',
    distance: 'Distancia (km)',
    distanceMi: 'Distancia (millas)',
    selectAll: 'Seleccionar todo',
  },
  gnet: {
    gnetInfo: 'Información GNET',
    requesterId: 'ID del solicitante',
    receiverId: 'ID del receptor',
    preferredVehicleType: 'Tipo de vehículo preferido',
    reservationType: 'Tipo de reserva',
    runType: 'Tipo de ejecución',
    price: 'Precio',
    currency: 'Moneda',
    noteDriver: 'Nota para el conductor',
    noteDispatcher: 'Nota para el despachador',
    acceptDriving: '¿Estás seguro de que quieres aceptar la orden de conducción?',
    rejectDriving: '¿Estás seguro de que quieres rechazar la orden de conducción?',
    tripDurationMinutes: 'Duración del viaje (minutos)',
  },
  invoicesHeaders: {
    invoiceNumber: 'Número de factura',
    client: 'Cliente',
    totalPrice: 'Precio total',
    drivings: 'Conducciones',
    paid: 'Pagado',
    currency: 'Moneda',
    createdAt: 'Creado en',
    actions: 'Acciones',
  },
  invoicesPage: {
    newItem: 'Nueva entrada de factura',
    chooseClient: 'Elige cliente',
    chooseDrivings: 'Elige trayectos',
    deleteMessage: '¿Estás seguro de que quieres eliminar esta factura?',
  },
  drivingOffersHeaders: {
    number: 'Número de oferta de conducción',
    totalPrice: 'Precio total',
    description: 'Descripción',
    paid: 'Pagado',
    currency: 'Moneda',
    createdAt: 'Creado en',
    actions: 'Acciones',
  },
  drivingOffersPage: {
    newItem: 'Nueva entrada de oferta de conducción',
    item: 'Ítem',
    description: 'Descripción',
    title: 'Título',
    vehicleClass: 'Clase de vehículo',
    quantity: 'Cantidad',
    price: 'Precio',
    discount: 'Descuento',
    vat: 'IVA',
    deleteMessage: '¿Está seguro de que desea eliminar esta oferta de conducción?',
  },
  modal: {
    reservationSuccessTitle: 'Éxito',
    reservationSuccessMessage:
      'Solicitud de reserva enviada con éxito. Alguien se pondrá en contacto contigo en breve.',
  },
  dashboardPage: {
    drivingCountToday: 'Número de órdenes de conducción (hoy)',
    drivingCountThisMonth: 'Número de órdenes de conducción (últimos 30 días)',
    dailyRentsAndTransfers: 'Número de órdenes de conducción (alquileres/transfers diarios)',
    drivingTotalAmount: 'Monto total',
    driversCount: 'Conductores activos (±7 días)',
    passengerCount: 'Número de pasajeros (últimos 30 días)',
  },
  reviewsHeaders: {
    rating: 'Calificación',
    comment: 'Comentario',
  },
  resourceOverviewHeaders: {
    actionType: 'Tipo de acción',
    user: 'Usuario',
    action: 'Acción',
    createdAt: 'Creado el',
  },
  resourceOverviewPage: {
    smsMessagesLeft: 'Número de mensajes SMS restantes',
    flightsCheckLeft: 'Número de verificaciones de vuelos restantes',
    sms: 'SMS',
    flightCheck: 'Verificación de vuelo',
  },
};
